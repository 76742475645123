import i18next from "i18next"
import { Provider } from 'react-redux'
import ReactDOM from "react-dom/client"
import { I18nextProvider } from "react-i18next"
import { BrowserRouter } from "react-router-dom"

import { store } from './store/configuring-store'
import App from "./App"

import "./index.css"

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <I18nextProvider i18n={i18next}>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </I18nextProvider>
);

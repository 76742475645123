import { api } from ".";

/*API calls for  categories */
export const getSectionsRoute = () => {
    return api.get('/categories');
}
export const getSectionsAllForOneLanguage = () => {

    return api.get(`/categories`);

}
export const createSection = (data: string) => {
    return api.post('categories', data);
}
export const deleteSection = (id: any) => {
    return api.delete(`categories/${id}`);
}
export const updateSection = (data: any) => {
    return api.put(`/categories/${data.id}`, { name: data.name });
}
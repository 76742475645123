import { Navigate, Route, Routes } from "react-router-dom";

import { LoginScreen } from "@pages/index";

const AuthRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<LoginScreen />} />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default AuthRoutes;
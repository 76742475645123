import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Form } from "antd"
import { LeftOutlined } from "@ant-design/icons"

import {
    getSingleArticle,
    updateArticle,
    createArticle,
    getEditArticle,
    getArticleLoading,
    resetArticleData,
    getAllLanguages,
    getDataRequest,
    isArticleCreated,
    getUpdateArticleSuccess,
    articleNotExistError
} from "@store/index";
import {
    EditArticleSkeleton,
    Section,
    TimeAndDate,
    TitleContent,
    Image,
    SuccessOrErrorMessage,
    Language, CustomButton
} from "@components/index";
import {
    DEFAULT_LANGAUGE,
    MAX_LENGTH_ARTICLE_CONTENT,
    MAX_LENGTH_ARTICLE_TITLE,
    MIN_LENGTH_ARTICLE,
    contentPattern
} from "@utils/index";
import {
    ICurrentLanguage,
    IArticleData,
    TitleOrContent,
    AntDataType,
    ILanguageData
} from "@models/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { NotFound } from '@pages/index'

import style from "./article.module.css";
import '../../App.css'

export const Article = (): JSX.Element => {

    const dispatch = useAppDispatch();

    const [form] = Form.useForm();
    const { id } = useParams<string>()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const loading = useAppSelector(getArticleLoading)
    const languages = useAppSelector(getAllLanguages)
    const editArticle = useAppSelector(getSingleArticle)
    const isPending = useAppSelector(getDataRequest)
    const articleCreated = useAppSelector(isArticleCreated)
    const articleUpdated = useAppSelector(getUpdateArticleSuccess)
    const articleNotExist = useAppSelector(articleNotExistError)

    const [currentLng, setCurrentLng] = useState<ICurrentLanguage>()

    const [startValidate, setStateValidate] = useState<boolean>(false)
    const [startEditing, setStartEditing] = useState<boolean>(id ? true : false)
    const [articleData, setArticleData] = useState<IArticleData>({
        date: '',
        time: '',
        image: undefined,
        categoryId: undefined,
        content: []
    })
    const { image, content, date, time, categoryId } = articleData

    useEffect(() => {
        return () => { if (id) { dispatch(resetArticleData()) } }
    }, [dispatch])

    useEffect(() => {
        if (!id && !content.length && languages) {
            languages.forEach(({ id }: ILanguageData) => {
                createFormState(id)
            })
        }

        setCurrentLng(languages.find(({ key }: ILanguageData) => key === DEFAULT_LANGAUGE))
    }, [languages])

    useEffect(() => {
        if (id && !isArticleCreatedForLng()) {
            dispatch(getEditArticle(id))
        }

        if (startValidate) {
            form.validateFields()
        }

        if (id) {
            form.validateFields([`title${currentLng?.id}`, `content${currentLng?.id}`])
        }
    }, [currentLng, dispatch])

    useEffect(() => {
        if (editArticle.id && !isArticleCreatedForLng()) {
            const { language, title, description } = editArticle.translations[0]
            createFormState(language.id, title, description)
        }
    }, [editArticle.id])

    function setAntData(typeAction: AntDataType): void {
        if (typeAction === AntDataType.DATE) {
            form.setFieldsValue({
                date: date && time ? `${date} ${time}` : editArticle?.date
            })
        } else if (typeAction === AntDataType.TITLE) {
            form.setFieldsValue({
                [`title${currentLng?.id}`]: currentTitleOrContent(TitleOrContent.TITLE),
            })
        } else if (typeAction === AntDataType.CONTENT) {
            form.setFieldsValue({
                [`content${currentLng?.id}`]: currentTitleOrContent(TitleOrContent.CONTENT)?.replace(contentPattern, "")
            })
        }
        else if (typeAction === AntDataType.IMAGE) {
            form.setFieldsValue({
                image: image ? image : editArticle.image?.path
            })
        } else if (typeAction === AntDataType.SECTION) {
            form.setFieldsValue({
                section: categoryId
            })
        }
    }

    function createFormState(languageId: number, title: string = '', description: string = ''): void {
        setArticleData(prev => ({
            ...prev,
            categoryId: id ? editArticle.category.id : prev.categoryId,
            content: [
                ...(prev.content),
                {
                    languageId,
                    title,
                    description
                }
            ]
        }))
    }

    function currentTitleOrContent(actionType: TitleOrContent): string | undefined {
        const titleOrContent = content?.find(({ languageId }) => languageId === currentLng?.id);
        return (actionType === TitleOrContent.TITLE) ? titleOrContent?.title : titleOrContent?.description;
    }

    function isArticleCreatedForLng(): boolean {
        return !!(content.find(data => data.languageId === currentLng?.id)) && true;
    }

    const onFinish = (): void => {
        if (!id) {
            dispatch(createArticle(articleData))
        } else {
            dispatch(updateArticle({
                id,
                articleData,
                receivedDate: editArticle?.date
            }))
        }
    }

    const onSubmit = (): void => {
        setStateValidate(true)

        if ((currentTitleOrContent(TitleOrContent.TITLE)?.length ?? 0) < MIN_LENGTH_ARTICLE || (currentTitleOrContent(TitleOrContent.TITLE)?.length ?? 0) >= MAX_LENGTH_ARTICLE_TITLE) {
            window.scrollTo(0, 0)
            return
        } else if ((currentTitleOrContent(TitleOrContent.CONTENT)?.length ?? 0) < MIN_LENGTH_ARTICLE || (currentTitleOrContent(TitleOrContent.CONTENT)?.length ?? 0) >= MAX_LENGTH_ARTICLE_CONTENT) {
            window.scrollTo(0, 0)
            return
        }

        content?.forEach(({ title, description, languageId }) => {
            const titleLength = title ? title.length : 0
            const contentLength = description ? description.replace(contentPattern, "").length : 0

            if (
                !title ||
                !description ||
                titleLength < MIN_LENGTH_ARTICLE ||
                contentLength < MIN_LENGTH_ARTICLE ||
                titleLength >= MAX_LENGTH_ARTICLE_TITLE ||
                contentLength >= MAX_LENGTH_ARTICLE_CONTENT
            ) {
                setCurrentLng(languages.find(({ id }: ILanguageData) => languageId === id))
                window.scrollTo(0, 0)
            }
        })
    }

    return (
        <>
            <SuccessOrErrorMessage
                articleData={articleData}
                articleCreated={articleCreated}
                articleUpdated={articleUpdated}
            />
            <Form
                name="basic"
                form={form}
                style={{ maxWidth: 1099, marginTop: "22px" }}
                disabled={startEditing}
                onFinish={onFinish}
            >
                <div className={style.btnsWrapper}>
                    <div className={style.languageMenuWrapper}>
                        <p className={style.languageLabel}>
                            {currentLng?.label}
                        </p>
                        <Language getCurrentLanguage={setCurrentLng} article />
                    </div>
                    <div className={style.navigateWrapper}>
                        <CustomButton
                            onClick={() => navigate(-1)}
                            name={"Back"}
                            icon={<LeftOutlined />}
                        />
                    </div>
                </div>
                {loading ? <EditArticleSkeleton /> :
                    articleNotExist ?
                        <NotFound />
                        :
                        <>
                            <TitleContent
                                content={content}
                                currentLng={currentLng}
                                setArticleData={setArticleData}
                                form={form}
                                currentTitleOrContent={currentTitleOrContent}
                                startEditing={startEditing}
                                setAntData={setAntData}
                            />
                            <TimeAndDate
                                editArticle={editArticle}
                                setArticleData={setArticleData}
                                form={form}
                                date={date}
                                time={time}
                                setAntData={setAntData}
                            />
                            <Image
                                image={image}
                                editArticle={editArticle}
                                setAntData={setAntData}
                                setArticleData={setArticleData}
                            />
                            <Section
                                setArticleData={setArticleData}
                                currentLng={currentLng}
                                setAntData={setAntData}
                                categoryId={categoryId}
                            />
                            {!!id &&
                                <div
                                    hidden={!startEditing}
                                    className={style.editWrapper}
                                >
                                    <Button
                                        disabled={false}
                                        onClick={() => setStartEditing(false)}
                                        className={style.edit}
                                    >
                                        {t('MAIN.EDIT')}
                                    </Button>
                                </div>}
                            <div
                                hidden={startEditing}
                                className={style.submitWrapper}
                            >
                                <Button
                                    onClick={onSubmit}
                                    disabled={isPending || articleCreated || articleUpdated}
                                    type="primary"
                                    htmlType="submit"
                                    className={style.submit}
                                >
                                    {t(`MAIN.${id ? 'UPDATE' : 'CREATE'}`)}
                                </Button>
                            </div>
                        </>}
            </Form>
        </>
    )
}
import { RcFile } from "antd/es/upload";

import { BASE_URL, getCookie } from "@utils/index";
import { LocationOfUse, ServerErrorType } from "@models/index"

const token = getCookie('accessToken');

export const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
            if (e.target?.result) {
                resolve(e.target.result as string);
            }
        }
        reader.onerror = error => reject(error);
    });

const NEWLINE_EXPRESSIONS = /\r\n|\n\r|\n|\r/g;

export const countLines = (text: any) => {
    NEWLINE_EXPRESSIONS.lastIndex = 0;
    for (let i = 1; true; ++i) {
        const match = NEWLINE_EXPRESSIONS.exec(text);
        if (match == null) {
            return i;
        }
    }
};

export function firstLetterBox(name: any) {
    if (name && name.length > 0) {
        const firstLetterText = name.charAt(0).toUpperCase();
        return firstLetterText;
    }
    return '';
}

export const filterErrors = (errorArray: any, errorfield: string) => {
    if (typeof errorArray === 'string') {
        return upperCase(errorArray);
    } else if (errorArray) {
        const newData = errorArray?.filter((item: any) => item.field === errorfield);
        if (newData[0]) {
            return upperCase(newData[0].message);
        }
    }
}

export function upperCase(text: string) {
    return text?.toUpperCase();
}

export function capitilizedText(str: string) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
}

export function deleteLastSegmentFromURL(url: any) {
    const segments = url.split('/').filter((segment: any) => segment !== '');
    segments.pop(); // Remove the last segment
    return segments;
}

export function removeSymbolAndCapitalize(text: string) {
    const withoutSymbols = text.replace(/[^a-zA-Z ]/g, ' '); // Remove non-alphabetic characters
    const words = withoutSymbols.split(' ');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
}

export function createImagePath(path: string) {
    return `${BASE_URL}${path}?authorization=${token}`;
}


export function objectToFormData(obj: Record<string, string | File>): FormData {
    const formData = new FormData();
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            formData.append(key, obj[key]);
        }
    }
    return formData;
}

export function shortenTitle(title: string, maxLength: number) {
    if (title.length <= maxLength) {
        return title;
    }
    return title.substring(0, maxLength) + '...';
}


export const getServerError = (location: LocationOfUse, action: ServerErrorType, errors: any, lngId?: number): string | undefined => {
    let errMessage: string = '';
    if (Array.isArray(errors)) {
        if (location === LocationOfUse.ARTICLE) {
            if (action === ServerErrorType.IMAGE) {
                errMessage = errors.find(err => err.field === 'image')?.message
            } else if (action === ServerErrorType.CATEGORY) {
                errMessage = errors.find(err => err.field === 'categoryId')?.message
            } else if (action === ServerErrorType.TITLE && lngId) {
                errMessage = errors.find(err => err.message.includes(`${lngId - 1}.title`))?.message.split(".")[1];
            } else if (action === ServerErrorType.CONTENT && lngId) {
                errMessage = errors.find(err => err.message.includes(`${lngId - 1}.description`))?.message.split(".")[1];
            }
        } else if (location === LocationOfUse.SECTION) {
            if (action === ServerErrorType.KEY) {
                errMessage = errors.find(err => err.field === 'key')?.message.slice(4)
            } else if (action === ServerErrorType.NAME && lngId) {
                errMessage = errors.find(err => err.message.includes(`${lngId - 1}.name`))?.message.split(".")[1]
            }
        }
    }
    return errMessage ? errMessage?.toUpperCase() : undefined
}
import {
    Button,
    Form,
    Upload,
    UploadFile,
    UploadProps,
    message
} from 'antd'
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RcFile } from 'antd/es/upload';

import { createImagePath, getBase64, getServerError, UPLOAD_FILE_TYPES } from '@utils/index'
import { IArticleData, LocationOfUse, ServerErrorType, AntDataType } from '@models/index';
import { articleErrors } from '@store/index';
import { useAppSelector } from '@hooks/index';

import style from './image.module.css'
import '../../../App.css'

interface IProps {
    image: UploadFile<any> | undefined;
    editArticle: any;
    setArticleData: Dispatch<SetStateAction<IArticleData>>;
    setAntData: Function
}

export const Image = ({
    image,
    editArticle,
    setArticleData,
    setAntData
}: IProps): JSX.Element => {

    const { t } = useTranslation()

    const serverErrors = useAppSelector(articleErrors)

    const [previewImage, setPreviewImage] = useState<string>('');

    useEffect(() => {
        setAntData(AntDataType.IMAGE)
    }, [image, editArticle.image?.path])

    const handlePreview = (file: UploadFile): void => {
        getBase64(file as RcFile).then((e: string) => setPreviewImage(e))
    };

    const chooseImage = (image: any): void => {
        setArticleData(prev => ({
            ...prev,
            image
        }))
    }

    const props: UploadProps = {
        name: 'file',
        headers: { authorization: 'authorization-text' },
        accept: "image/*",
        beforeUpload: ({ type, size }) => {
            const allowedFileTypes = UPLOAD_FILE_TYPES;
            const isLt15M = size / 1024 / 1024 < 15;

            if (!allowedFileTypes.includes(type) || !isLt15M) {
                message.error(!allowedFileTypes.includes(type)
                    ? t('MAIN.UPLOAD_FILE_TYPE_ERROR')
                    : t('MAIN.UPLOAD_FILE_SIZE_ERROR'));
                return Upload.LIST_IGNORE;
            }
            return false;
        },
        onChange({ fileList, file }) {
            fileList.length = 0
            fileList.push(file)
            handlePreview(file)
            chooseImage(fileList.length ? fileList[0] : undefined)
        },
        showUploadList: false
    };

    return (
        <>
            <div
                hidden={!image && !editArticle.image?.path}
                className='formItemWrapper'
            >
                <Form.Item
                    label="Existing Featured Photo"
                    name="image-show"
                    style={{ marginBottom: 20 }}
                    className='formItem'
                >
                    <img
                        className={style.image}
                        src={!image
                            ? createImagePath(editArticle.image?.path)
                            : previewImage}
                        alt="Article image"
                    />
                </Form.Item>
            </div>
            <div className='formItemWrapper'>
                <Form.Item
                    label="Change Featured Photo"
                    name="image"
                    style={{ marginBottom: 20 }}
                    rules={[{ required: true, message: `${t('MAIN.CHOOSEFILE')}` }]}
                    help={!image && getServerError(LocationOfUse.ARTICLE, ServerErrorType.IMAGE, serverErrors)
                        ? <p className="errMessage">
                            {t(`MAIN.${getServerError(LocationOfUse.ARTICLE, ServerErrorType.IMAGE, serverErrors)}`)}
                        </p>
                        : undefined}
                    className='formItem'
                >
                    <Upload {...props}>
                        <Button className={style.uploadImage}>
                            {t('MAIN.CHOOSEFILE')}
                        </Button>
                    </Upload>
                </Form.Item>
            </div>
        </>
    )
}
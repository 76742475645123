import { useEffect, useState } from "react";
import { RcFile } from "antd/es/upload";
import { useTranslation } from "react-i18next";
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Upload, UploadProps, message } from "antd";

import {
    createPartnerThunk,
    getOnePartnerInfo,
    getPartnersErrors,
    updatePartnerThunk,
    getPartnerCardLoading
} from "@store/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { createImagePath, getBase64 } from "@utils/helper";
import { IPartnerInfo, IFormValues } from "@models/index";
import { nofile } from "@assets/index";

import styles from "../partner.module.css";

interface IProps {
    id?: number,
    isCancelClicked: any
}

export const Partner = ({ id, isCancelClicked }: IProps): JSX.Element => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const cardInfo = useAppSelector<IPartnerInfo>(getOnePartnerInfo);
    const errors = useAppSelector<[]>(getPartnersErrors);
    const loading = useAppSelector(getPartnerCardLoading)

    const [base64, setBase64] = useState<string | null | undefined>(null);
    const [validateImageField, setValidateImage] = useState<boolean>()

    useEffect(() => {
        if (id) {
            form.setFieldsValue({
                name: cardInfo?.name,
                image: cardInfo?.image?.path
            })
        }
    }, [cardInfo, id]);

    const props: UploadProps = {
        name: 'file',
        headers: {
            authorization: 'authorization-text',
        },
        accept: "image/*",
        beforeUpload: () => false,
        async onChange({ fileList, file }) {
            const { status, name } = file

            if (fileList.length) {
                const img = await getBase64(file as RcFile);
                form.setFieldValue('image', file);
                setBase64(img);
            } else {
                setBase64(undefined)
            }

            if (status === 'done') {
                message.success(`${name} file uploaded successfully`);
            } else if (status === 'error') {
                message.error(`${name} file upload failed.`);
            }
        },
        fileList: form.getFieldValue('image') ? [form.getFieldValue('image')] : undefined,
        showUploadList: false
    };

    const onFinish = (values: IFormValues): void => {
        dispatch(id
            ? updatePartnerThunk({ values, id, currentImage: form.getFieldValue('image') })
            : createPartnerThunk({ values, currentImage: form.getFieldValue('image') }));
    };

    if (loading) {
        return <p>{t('MAIN.LOADING')}</p>
    }

    return (
        <Card className={styles.cardWrapper}>
            <Form
                name="basic"
                form={form}
                labelCol={{ span: 16 }}
                wrapperCol={{ span: 28 }}
                style={{ maxWidth: 600 }}
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
            >
                {errors?.map((item: any) => {
                    return (
                        <span className={styles.error}>
                            {t(`PARTNERS.${item.message.toUpperCase()}`)}
                        </span>
                    )
                })}
                <Form.Item
                    label="Partners Name"
                    name="name"
                    rules={[{ required: true, message: '' }]}
                    className="m0"
                >
                    <Input addonAfter={<CloseOutlined onClick={() => form.resetFields(["name"])} />} className={styles.socialInputs} />
                </Form.Item>
                <Form.Item
                    name="image"
                    className={`${styles.uploadLine} "m0"`}
                    rules={[{ required: true, message: '' }]}
                >
                    <div className={styles.imageLabel}>
                        <p>{t("MAIN.IMAGE")}</p>
                        <div
                            className={validateImageField && !base64
                                ? styles.imageValidate
                                : ''
                            }
                        >
                            {base64
                                ? <img
                                    src={base64}
                                    className={styles[id ? 'noFile' : '']}
                                    alt="Partner image"
                                />
                                : <img
                                    src={id && cardInfo.image?.path
                                        ? createImagePath(cardInfo.image?.path)
                                        : nofile}
                                    alt="Partner image"
                                />}
                        </div>
                    </div>
                    <div>
                        <Upload {...props}>
                            <Button
                                icon={<UploadOutlined />}
                                className={styles.uploadButton}
                            >
                                {t("MAIN.UPLOAD")}
                            </Button>
                        </Upload>
                    </div>
                </Form.Item>
                <br />
                <div className={styles.partnerButtonsRow}>
                    <Button
                        type="primary"
                        className={styles.partnerCancelButton}
                        onClick={() => {
                            isCancelClicked && isCancelClicked(true);
                        }}>
                        {t("MAIN.CANCEL")}
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => setValidateImage(true)}
                        className={styles.partnerSaveButton}
                    >
                        {t("MAIN.SAVE")}
                    </Button>
                </div>
            </Form>
        </Card >
    )
}
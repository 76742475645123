import { useTranslation } from "react-i18next"
import { Form, FormInstance, Input } from "antd"
import { Dispatch, SetStateAction, useEffect, useState } from "react"

import {
    ActionTypes,
    TitleOrContent,
    ICurrentLanguage,
    IArticleData,
    ITitle,
    AntDataType,
    ServerErrorType,
    LocationOfUse,
    ITitleAndContentError
} from "@models/index"
import {
    MAX_LENGTH_ARTICLE_CONTENT,
    MAX_LENGTH_ARTICLE_TITLE,
    MIN_LENGTH_ARTICLE,
    getServerError
} from "@utils/index"
import { articleErrors, resetError } from "@store/index"
import { useAppDispatch, useAppSelector } from "@hooks/index"
import { CustomEditor } from "@components/index"

import style from './title-content.module.css'
import '../../../App.css'

interface IProps {
    content: ITitle[];
    currentLng: ICurrentLanguage | undefined;
    setArticleData: Dispatch<SetStateAction<IArticleData>>;
    form: FormInstance<any>;
    currentTitleOrContent: Function;
    startEditing: boolean;
    setAntData: Function
}

export const TitleContent = ({
    content,
    currentLng,
    setArticleData,
    form,
    currentTitleOrContent,
    startEditing,
    setAntData
}: IProps): JSX.Element => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const serverErrors = useAppSelector(articleErrors)

    const [error, setError] = useState<ITitleAndContentError>({
        title: '',
        content: ''
    })

    useEffect(() => {
        if (currentLng?.id) {
            const titleError = getServerError(LocationOfUse.ARTICLE, ServerErrorType.TITLE, serverErrors, currentLng?.id)
            const contentError = getServerError(LocationOfUse.ARTICLE, ServerErrorType.CONTENT, serverErrors, currentLng?.id)

            setError({
                title: titleError,
                content: contentError
            })
        }
    }, [currentLng?.id, serverErrors])

    useEffect(() => {
        return () => { dispatch(resetError()) }
    }, [dispatch])

    useEffect(() => {
        setAntData(AntDataType.TITLE)
    }, [currentTitleOrContent(TitleOrContent.TITLE)])

    useEffect(() => {
        setAntData(AntDataType.CONTENT)
    }, [currentTitleOrContent(TitleOrContent.CONTENT)])

    function changeTitleOrContent(typeAction: ActionTypes, data: string): void {
        setArticleData(prev => {
            const updatedData = { ...prev };
            if (typeAction === ActionTypes.CONTENT || typeAction === ActionTypes.TITLE) {
                updatedData.content = prev.content.map(item => {
                    if (item.languageId === currentLng?.id) {
                        return {
                            ...item,
                            [typeAction]: data,
                        };
                    }
                    return item;
                });
            }
            return updatedData;
        });
    }

    return (
        <div className={style.mainWrapper}>
            {content.map(lng => {
                return (
                    <div
                        key={lng.languageId}
                        hidden={lng.languageId !== currentLng?.id}
                    >
                        <div className='formItemWrapper'>
                            <Form.Item
                                label="News Title"
                                name={`title${lng.languageId}`}
                                rules={[
                                    { required: true, message: `${t('MAIN.FILL_TITLE')}` },
                                    { max: MAX_LENGTH_ARTICLE_TITLE, message: 'Title is very long' },
                                    { min: MIN_LENGTH_ARTICLE, message: 'Title is very short' }]}
                                help={error.title
                                    ? <p className="errMessage">
                                        {t(`MAIN.${error.title}`)}
                                    </p>
                                    : undefined}
                                className='formItem'
                            >
                                <Input
                                    className={style.title}
                                    onChange={e => changeTitleOrContent(ActionTypes.TITLE, e.target.value)}
                                />
                            </Form.Item>
                        </div>
                        <div className='formItemWrapper'>
                            <Form.Item
                                label="News content"
                                name={`content${lng.languageId}`}
                                rules={[
                                    { required: true, message: `${t('MAIN.FILL_CONTENT')}` },
                                    { max: MAX_LENGTH_ARTICLE_CONTENT, message: 'Content is very long' },
                                    { min: MIN_LENGTH_ARTICLE, message: 'Content is very short' }]}
                                help={error.content
                                    ? <p className="errMessage">
                                        {t(`MAIN.${error.content}`)}
                                    </p>
                                    : undefined}
                                className='formItem'
                            >
                                <CustomEditor
                                    articleForm={form}
                                    currentLngId={currentLng?.id}
                                    content={currentTitleOrContent(TitleOrContent.CONTENT)}
                                    getEditorContent={(data: string) => changeTitleOrContent(ActionTypes.CONTENT, data)}
                                    article
                                    isDisabled={startEditing}
                                />
                            </Form.Item>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, message } from "antd";

import {
    getIsSocialCreated,
    getIsSocialDeleted,
    getIsSocialUpdated,
    getOneSocialsThunk,
    getSocialData,
    getSocialLoading,
    getSocialsThunk,
    getSettingsThunk,
    getSettingsErrorMessage,
    getSettingsErrors,
    isSettingsUpdated
} from "@store/index";
import {
    SocialCards,
    SocialMediaCardSkeleton,
    CreateOrUpdateSocialCard,
    NewSocialCard
} from "@components/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { Copyright } from "./copyright"
import { ISocialData } from "models"

import style from "./settings-general-content.module.css";

export const SettingsOfGeneralContent = (): JSX.Element => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const ismetaContentUpdated = useAppSelector<boolean>(isSettingsUpdated);
    const errorMessage = useAppSelector(getSettingsErrorMessage);
    const settingsErrors = useAppSelector(getSettingsErrors);
    const isSocialCreated = useAppSelector<boolean>(getIsSocialCreated);
    const isSocialUpdated = useAppSelector<boolean>(getIsSocialUpdated);
    const isSocialDeleted = useAppSelector<boolean>(getIsSocialDeleted);
    const loading = useAppSelector<boolean>(getSocialLoading);
    const socials = useAppSelector<ISocialData[]>(getSocialData);

    const [messageApi, contextHolder] = message.useMessage();
    const [isPlusClicked, setIsPlusClicked] = useState<boolean>(false);
    const [isEdit, setisEdit] = useState<boolean>(false);
    const [isEditedId, setIsEditedId] = useState<boolean>();
    const [newCard, setNewCard] = useState<any[]>([]);

    useEffect(() => {
        if (ismetaContentUpdated) {
            dispatch(getSettingsThunk());
            updated();
        }
    }, [ismetaContentUpdated, dispatch])

    useEffect(() => {
        dispatch(getSocialsThunk());
        if (isSocialCreated) {
            closeAddSocial()
        } else if (isSocialUpdated) {
            isEditClicked({ isEdit: false, editedId: null })
        }

        if (isEditedId) {
            dispatch(getOneSocialsThunk(isEditedId));
        }
    }, [isSocialCreated, isSocialUpdated, isSocialDeleted, isEditedId, dispatch])

    const isClicked = (e: boolean): void => {
        setIsPlusClicked(e)
        setNewCard([...newCard, {}]);
    }

    const closeAddSocial = (): void => {
        setIsPlusClicked(false);
        setNewCard([]);
    }

    const isCancelClicked = (): void => {
        newCard.pop();
        setNewCard([...newCard]);
    }

    const isEditClicked = (e: any): void => {
        setisEdit(e.isEdit);
        setIsEditedId(e.editedId);
    }

    const updated = (): void => {
        messageApi.open({
            type: 'success',
            content: 'General Page Updated',
            duration: 5,
        });
    };

    if (loading) {
        return <SocialMediaCardSkeleton />
    }

    return (
        <>
            {contextHolder}
            <div className={style.socialSec}>
                <Row gutter={[32, 95]}>
                    <Col span={2}>
                        <p className={style.socialMediaLabel}>
                            {t("MAIN.SOCIAL_MEDIA")}
                        </p>
                    </Col>
                    <Col span={20}>
                        <div className={style.socialCard}>
                            {socials &&
                                socials.map((item: any, key: number) => {
                                    return (
                                        <Col span={7} key={key}>
                                            {isEdit && isEditedId === item.id
                                                ? <CreateOrUpdateSocialCard
                                                    id={item.id}
                                                    isCancelClicked={() => isEditClicked({
                                                        isEdit: false, editedId: null
                                                    })}
                                                />
                                                : <SocialCards
                                                    link={item.link}
                                                    image={item.image}
                                                    title={item.name}
                                                    id={item.id}
                                                    isEditClicked={isEditClicked}
                                                />}
                                        </Col>
                                    )
                                })}
                            {isPlusClicked &&
                                newCard?.map((_, index) => {
                                    return (
                                        <Col span={7} key={index}>
                                            <CreateOrUpdateSocialCard
                                                isCancelClicked={isCancelClicked}
                                            />
                                        </Col>
                                    )
                                })}
                            <Col span={7}>
                                <NewSocialCard isClicked={isClicked} />
                            </Col>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={style.settingsGeneralContentTab}>
                {!!settingsErrors && settingsErrors.errors.map((item: any) => {
                    return (
                        <p key={item.id} className={style.error}>
                            {t(`ERRORS.${item.message.toUpperCase()}`)}
                        </p>
                    );
                })}
                {!!errorMessage && <p className={style.error}>
                    {t(`ERRORS.${errorMessage.toUpperCase()}`)}
                </p>}
                <Copyright />
            </div>
        </>
    )
}
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Skeleton, Upload } from "antd";

import {
    getFaviconInfo,
    getSettingsErrors,
    getSettingsLoading,
    updateSettingsThunk
} from "@store/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { createImagePath, objectToFormData } from "@utils/index";
import { IFavicon } from "@models/index";

import style from "./settings-favicon.module.css";

export const SettingsOfFavicon = (): JSX.Element => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const favicon = useAppSelector<IFavicon>(getFaviconInfo);
    const loading = useAppSelector<boolean>(getSettingsLoading);
    const error = useAppSelector(getSettingsErrors);

    const [file, setFile] = useState<any>();

    const props = {
        name: 'file',
        headers: {
            authorization: 'authorization-text',
        },
        accept: "image/*",
        beforeUpload: () => {
            return false;
        },
        onChange: (info: any) => {
            const file = info.fileList.length ? info.file : undefined;
            setFile(file)
        },
        fileList: file ? [file] : undefined
    };

    const onFinish = () => {
        const formData = objectToFormData({ "favicon": file });
        const data = {
            formData: formData,
            id: favicon.id
        }
        dispatch(updateSettingsThunk(data));
    };

    return (
        <div className={style.mainWrapper}>
            {error ? <p className={style.error}>
                {t(`ERRORS.${error.message.toUpperCase()}`)}
            </p> : ""}
            <div className={style.logoAndLabelWrapper}>
                <p className={style.logoLabel}>{t("MAIN.EXISTING_FAVICON")}</p>
                {loading
                    ? <Skeleton.Image active className={style.logo} />
                    : <img
                        src={createImagePath(favicon.favicon?.path)}
                        className={style.logo}
                        alt="Favicon"
                    />}
            </div>

            <Form
                name="basic"
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 700 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    label="New Favicon"
                    name="favicon"
                    className={style.label}
                    rules={[{ required: true, message: 'Please choose the image!' }]}
                >
                    <div style={{ marginLeft: 30 }}>
                        <Upload {...props}>
                            <Button>
                                <UploadOutlined type="upload" /> {t("MAIN.UPLOAD")}
                            </Button>
                        </Upload>
                    </div>
                </Form.Item>
                <br />
                <Form.Item wrapperCol={{ offset: 11, span: 16 }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className={style.updateFavicon}
                    >
                        {t("MAIN.UPDATE")}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
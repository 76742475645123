import { Card, Skeleton } from "antd";

import styles from "./social-media.module.css";

export const SocialMediaCardSkeleton = (): JSX.Element => {
    function createCards(count: number): JSX.Element[] {
        const cards = [];
        for (let i = 0; i < count; i++) {
            cards.push(
                <div className={styles.mainWrapper} >
                    <Card className={styles.card}>
                        <Skeleton.Image
                            active
                            className={styles.image}
                        />
                    </Card>
                    <Skeleton.Input
                        active
                        className={styles.bottomSide}
                    />
                </div >
            )
        }
        return cards
    }

    return (
        <div className={styles.skeletons}>
            <div className={styles.title}>
                <Skeleton active title={false} paragraph={{
                    rows: 1,
                    width: 90
                }} />
            </div>
            <div className={styles.skeletonMainPart}>
                {createCards(6)}
            </div>
        </div>

    )
}

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { deleteReports, downloadReportByUser, getDetailsReports, getReports } from "@services/reports.service";
import { IReportsResponseData } from "@models/index";

const initialState: IReportsResponseData = {
  message: "",
  statusCode: 0,
  statusName: "",
  success: false,
  loading: false,
  data: {},
  details: [],
  isDeleted: false,
  pdfPath: "",
  isPdf: false
};

export const getReportsThunk = createAsyncThunk(
  "reports/getreports",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getReports(data);
      return Promise.resolve(response.data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getReportsDetailsThunk = createAsyncThunk(
  "reports/getdetailsreports",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getDetailsReports(data);
      return Promise.resolve(response.data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteReportThunk = createAsyncThunk(
  "reports/deletereport",
  async (data: number, { rejectWithValue }) => {
    try {
      const response = await deleteReports(data);
      return Promise.resolve(response.data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const downloadReportByUserThunk = createAsyncThunk(
  "reports/download",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await downloadReportByUser(data);
      return Promise.resolve(response.data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const ReportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getReportsThunk.pending, (state: IReportsResponseData) => {
      state.loading = true;
      state.data = {}
    });
    builder.addCase(getReportsThunk.fulfilled, (state: IReportsResponseData, { payload }: PayloadAction<any>) => {
      state.success = true;
      state.data = payload;
      state.loading = false;
      state.isDeleted = false;
    });
    builder.addCase(getReportsThunk.rejected, (state: IReportsResponseData) => {
      state.success = false;
      state.loading = false;
    });


    builder.addCase(getReportsDetailsThunk.pending, (state: IReportsResponseData) => {
      // state.loading = true;
    });
    builder.addCase(getReportsDetailsThunk.fulfilled, (state: IReportsResponseData, { payload }: PayloadAction<any>) => {
      state.success = true;
      state.details = payload;
      // state.loading = false;
      state.isDeleted = false;
    });
    builder.addCase(getReportsDetailsThunk.rejected, (state: IReportsResponseData) => {
      state.success = false;
      // state.loading = false;
    });

    builder.addCase(downloadReportByUserThunk.pending, (state: IReportsResponseData) => {
      state.pdfPath = "";
      state.isPdf = false;
      // state.loading =true;
    });
    builder.addCase(downloadReportByUserThunk.fulfilled, (state: IReportsResponseData, { payload }: PayloadAction<any>) => {
      state.pdfPath = payload;
      state.isPdf = true;
      // state.loading =false;
    });
    builder.addCase(downloadReportByUserThunk.rejected, (state: IReportsResponseData) => {
      state.isPdf = false;
      // state.loading =false;
    });

    builder.addCase(deleteReportThunk.pending, (state: IReportsResponseData) => {
      // state.loading = true;
    });
    builder.addCase(deleteReportThunk.fulfilled, (state: IReportsResponseData, { payload }: PayloadAction<any>) => {
      state.success = true;
      state.details = payload;
      // state.loading = false;
      state.isDeleted = true;
    });
    builder.addCase(deleteReportThunk.rejected, (state: IReportsResponseData) => {
      state.success = false;
      // state.loading = false;
    });
  },
});

export const getAllReports = (state: any) => {
  return state.reports?.data;
}

export const getDetailsById = (state: any) => {
  return state.reports?.details;
}
export const getPdfPath = (state: any) => {
  return state.reports?.pdfPath;
}
export const isPDFPath = (state: any) => {
  return state.reports?.isPdf;
}
export const getReportDeleted = (state: any) => {
  return state.reports?.isDeleted;
}

export const getReportLoading = (state: any) => {
  return state.reports.loading
}
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from '@tinymce/tinymce-react';

import { changeSetDataState } from '@store/index';
import { useAppDispatch } from '@hooks/index';
import { LineCount } from '@models/index';

import style from './custom-editor.module.css';

interface IProps {
    content?: string;
    getEditorContent: Function;
    count?: any;
    currentLngId?: number;
    isDisabled?: boolean;
    article?: true;
    articleForm?: any;
}

export const CustomEditor = ({
    content,
    getEditorContent,
    count,
    currentLngId,
    isDisabled,
    article,
    articleForm
}: IProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            if (article) {
                dispatch(changeSetDataState(false))
            }
        }
    }, [dispatch])

    const countAction = (): ReactNode => {
        if (count > (article ? LineCount.ARTICLE : LineCount.BANNER)) {
            if (article) {
                dispatch(changeSetDataState(true))
            }
            return (
                <p className="errorMessage">
                    {t("MAIN.EDITOR_LINE_COUNT")} {article ? LineCount.ARTICLE : LineCount.BANNER}
                </p>)
        } else {
            if (article) {
                dispatch(changeSetDataState(false))
            }
        }
    }

    return (
        <div className={style.editorWrapper}>
            <div className={isDisabled ? style.disabledDiv : undefined}></div>
            <Editor
                apiKey="q6p5xu2txzpzyk8v48v2lbxbsbpy18vke9sih4fa18k9arek"
                disabled={isDisabled}
                onKeyUp={() => {
                    if (article) {
                        articleForm.validateFields([`content${currentLngId}`])
                    }
                }}
                onEditorChange={content => getEditorContent(content)}
                value={content}
                init={{
                    height: 480,
                    resize: false,
                    max_width: 850,
                    width: "100%",
                    menubar: false,
                    max_chars: 100,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: `body { font-family:Helvetica,Arial,sans-serif; font-size:14px;}`
                }}
            />
            {countAction()}
        </div>
    );
}

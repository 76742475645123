import { useState } from "react";
import { Form, Input } from "antd";

import { CloseIcon, DoneIcon } from "@components/index";
import { updateSectionThunk } from "@store/index";
import { useAppDispatch } from "@hooks/index";

import style from "./section.module.css";

interface IProps {
  title: any;
  isClose: any;
  id: any;
  sectionKey: any
}

export const EditSection = ({ title, isClose, id, sectionKey }: IProps): JSX.Element => {

  const dispatch = useAppDispatch();

  const [sectionName, setSectionName] = useState(title);

  const isClicked = (val: boolean) => {
    const data = {
      name: sectionName,
      key: sectionKey,
      id
    }
    if (val) {
      dispatch(updateSectionThunk(data))
    }
  }

  return (
    <Form
      name="basic"
      style={{ paddingTop: 15 }}
      autoComplete="off"
    >
      <div style={{ display: "flex" }} className={style.editSection}>
        <Input
          value={sectionName}
          onChange={e => setSectionName(e.target.value)}
        />
        <DoneIcon isClicked={() => isClicked(true)} />
        <CloseIcon isClose={() => isClose(false)} />
      </div>
    </Form>
  );
};


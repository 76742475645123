import { Card } from "antd";
import { useTranslation } from "react-i18next";

import { plus } from "@assets/index"

import styles from "./social.module.css";

interface IProps {
  isClicked: Function
}

export const NewSocialCard = ({ isClicked }: IProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div>
      <Card className={styles.socialCard}>
        <img
          alt="Plus icon"
          src={plus}
          className={styles.plusicon}
          onClick={() => isClicked(true)}
        />
      </Card>
      <div className={styles.actionsText}>
        {t("SOCIALS.ADD_SOCIAL_MEDIA")}
      </div>
    </div>
  );
}
import { Card, Carousel, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';

import { deleteSectionThunk, getIsSectionUpdated } from "@store/index";
import { EditSection } from "@components/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { createImagePath } from "@utils/index";
import { IArticles, ICardSection } from "models";
import { close } from "@assets/index";

import styles from "./card.module.css";

let timer: any = 0;
const delay: number = 200;
let prevent: boolean = false;

export const CardSection = ({ title, id, sectionKey, articles }: ICardSection): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isSectionUpdated = useAppSelector<boolean>(getIsSectionUpdated);
  const [isDoubleClicked, setisDoubleClicked] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);

  useEffect(() => {
    if (isSectionUpdated) {
      setisDoubleClicked(false)
    }
  }, [isSectionUpdated]);

  const isClose = (value: boolean): void => setisDoubleClicked(value)

  function doClickAction() {
    setIsClicked(true);
  }

  function doDoubleClickAction(): void {
    setisDoubleClicked(true)
  }

  function handleClick(): void {

    timer = setTimeout(function () {

      if (!prevent) {
        doClickAction();
      }

      prevent = false;
    }, delay);
  }

  function handleDoubleClick(): void {
    clearTimeout(timer);
    prevent = true;
    doDoubleClickAction();
  }

  return (
    <>
      {!isClicked ?
        <>
          <span
            className={styles.delete}
            onClick={() => dispatch(deleteSectionThunk(id))}
          >
            <img src={close} alt="Close icon" />
          </span>
          <Card className={styles.secCard}>
            <div className={styles.cardContentWrapper}>
              <div>
                <Carousel autoplay>
                  {articles?.map((item: IArticles) => {
                    return (
                      <div key={item.id}>
                        <img
                          src={createImagePath(item.image?.path)}
                          className={styles.carouselImage}
                          alt={item.category.key}
                        />
                      </div>
                    )
                  })}
                </Carousel>
              </div>

              <div className={styles.editSection}>
                {isDoubleClicked ?
                  <EditSection
                    title={title}
                    isClose={isClose}
                    id={id}
                    sectionKey={sectionKey}
                  /> :
                  <>
                    <span className={styles.titleWrapper}>
                      <Tooltip
                        title={t('MAIN.TOOLTIP')}
                      >
                        <div
                          className={styles.title}
                          onClick={handleClick}
                          onDoubleClick={handleDoubleClick}
                        >
                          {title}
                        </div>
                      </Tooltip>
                    </span>
                  </>
                }
                {articles?.map((item: any) => {
                  return (
                    item?.translations?.map((i: any) => {
                      return (
                        <h2
                          className={styles.articleTitle}
                          onClick={() => navigate(`/content-management/article/${item.id}`)}
                          key={item.id}
                        >
                          <Link
                            to={`/content-management/article/${item.id}`}
                          >
                            {i.title}
                          </Link>
                        </h2>
                      )
                    })
                  )
                }
                )}
              </div>
            </div>
          </Card>
        </>
        : navigate(`/content-management/${id}`)
      }
    </>
  )
}
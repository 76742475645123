import axios from "axios";

import { sendRefreshTokenThunk } from "@store/index";
import { deleteCookie, getCookie } from "@utils/index";

let store: any;
let lngForEditArticle: string = 'en';

export function changeAcceptLanguage(lng: string = "en") {
    lngForEditArticle = lng
}
export const injectStore = (_store: any) => {
    store = _store
}

export const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

api.interceptors.request.use(async (config: any) => {
    const token = getCookie('accessToken');
    if (token && (!config.headers.non_auth || config.headers.non_auth === "false")) {
        config.headers.Authorization = `Bearer ${token}`;
        config.headers['Accept-Language'] = lngForEditArticle
    }
    delete config.headers.non_auth;
    return config;
});

let isRefreshing = false;
let failedQueue = [] as any;

const processQueue = (error: any, token = null) => {
    failedQueue.forEach((prom: any) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

api.interceptors.response.use(
    response => {
        return response.data;
    },
    async err => {
        const originalRequest = err.config;
        if (err.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return api(originalRequest);
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;
            return store.dispatch(sendRefreshTokenThunk(getCookie('refreshToken')))
                .then((res: any) => {
                    const { data } = res?.payload;
                    originalRequest.headers['Authorization'] = 'Bearer ' + data.accessToken;
                    processQueue(null, data.accessToken);
                    isRefreshing = false;
                    return api(originalRequest);
                })
                .catch((err: any) => {
                    processQueue(err, null);
                    deleteCookie('accessToken', '');
                    window.location.href = '/';
                    isRefreshing = false;
                });
        }

        return Promise.reject(err);
    }
);

export const getLangaugeByKey = (langkey: string) => {
    return api.get(`languages/${langkey}`);
}
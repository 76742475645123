import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getStatistics } from "@services/statistics.service";
import { IStatisticsResponseData } from "@models/index";

const initialState: IStatisticsResponseData = {
  message: "",
  statusCode: 0,
  statusName: "",
  success: false,
  loading: false,
  data: {},
  isArticleCreated: false
};

export const getStatisticsThunk = createAsyncThunk(
  "statistics/getstatistics",
  async (data:any, { rejectWithValue }) => {
    try {
      const response = await getStatistics(data);
      return Promise.resolve(response.data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const StatisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getStatisticsThunk.pending, (state: IStatisticsResponseData) => {
      state.loading = true;
    });
    builder.addCase(getStatisticsThunk.fulfilled, (state: IStatisticsResponseData, { payload }: PayloadAction<any>) => {
      state.success = true;
      state.data = payload;
      state.loading = false;
    });
    builder.addCase(getStatisticsThunk.rejected, (state: IStatisticsResponseData) => {
      state.success = false;
      state.loading = false;
    });
  },
});

export const getAllStatistics = (state: any) => {
  return state.statistiscs?.data;
}

export const getStatisticsLoading = (state: any) => {
  return state.statistiscs?.loading;
}
import { api } from ".";

/*API calls for  professions */
export const getProfessions = () => {
    return api.get('professions');
}
export const getProfessionbyId = (id: any) => {
    return api.get(`professions/${id}`);
}

export const createProfession = (data: string) => {
    return api.post('professions', data);
}
export const deleteProfession = (data: any) => {
    return api.delete(`professions/${data.id}`);
}
export const updateProfession = (data: any) => {
    return api.put(`/professions/${data.id}`, data.data);
}
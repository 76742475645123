import { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, Skeleton, message } from "antd";

import {
    getErrorsIC,
    getInfoContent,
    getInformationContentThunk,
    isICUpdated,
    updateInformationContentThunk,
    getSettingsLoading,
    createInformationContentThunk
} from "@store/index";
import { Language, languages, editArticleLanguages } from "@components/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { IinfoContent } from "@models/index";
import { changeAcceptLanguage } from "@services/index";

import style from "./information-content.module.css";

interface IProps {
    lang?: any
}

export const InformationContent = ({ lang }: IProps): JSX.Element => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const loading = useAppSelector<boolean>(getSettingsLoading)
    const infoContent = useAppSelector<IinfoContent>(getInfoContent);
    const isInfoContentUpdated = useAppSelector<boolean>(isICUpdated);
    const errors = useAppSelector(getErrorsIC);

    const [currentLangLabel, setCurrentLangLabel] = useState<string>("English");
    const [infoContentlng, setInfoContentLng] = useState<string>("en");
    const [infoContentlngID, setInfoContentLngID] = useState<number>(1);
    const [contentbyLanguage, setContentbyLanguage] = useState<any>();

    const updated = () => {
        messageApi.open({
            type: 'success',
            content: 'Information Content Updated',
            duration: 5,
        });
    };

    useEffect(() => {
        changeAcceptLanguage(infoContentlng);
        dispatch(getInformationContentThunk(infoContentlngID))
        const lngobj: any = editArticleLanguages?.find((o: any) => o.key === infoContentlng);
        setCurrentLangLabel(lngobj?.label);

        const lngId: any = languages?.find((o: any) => o.label === currentLangLabel);
        setInfoContentLngID(lngId.key);
    }, [infoContentlngID])


    useEffect(() => {
        if (infoContent || infoContentlngID || isInfoContentUpdated) {
            if (Array.isArray(infoContent)) {
                const contentByLang = infoContent.find((item: any) => item.language.id === infoContentlngID);
                setContentbyLanguage(contentByLang);
            }
        }
    }, [infoContent, infoContentlngID, isInfoContentUpdated, contentbyLanguage])

    useEffect(() => {
        changeAcceptLanguage(infoContentlng);
        const lngobj: any = editArticleLanguages?.find((o: any) => o.key === infoContentlng);
        setCurrentLangLabel(lngobj?.label);
    }, [infoContentlng, isInfoContentUpdated])

    useEffect(() => {
        const lngId: any = languages?.find((o: any) => o.label === currentLangLabel);
        setInfoContentLngID(lngId.key);
    }, [currentLangLabel, isInfoContentUpdated])

    useEffect(() => {
        if (infoContent)
            form.setFieldsValue({
                about: infoContent?.about,
                banner: infoContent?.banner,
                aboutTitle: infoContent?.aboutTitle,
                bannerTitle: infoContent?.bannerTitle
            });
    }, [infoContent, infoContentlngID, contentbyLanguage]);

    useEffect(() => {
        if (isInfoContentUpdated) {
            dispatch(getInformationContentThunk(infoContentlngID));
            updated();
        }
    }, [isInfoContentUpdated, infoContentlngID]);

    const onFinish = ({ about, banner, aboutTitle, bannerTitle }: any) => {
        const data = {
            about,
            banner,
            aboutTitle,
            bannerTitle,
            languageId: +infoContentlngID,
            id: infoContent.id ? infoContent.id : void 0
        }
        infoContent.id ? dispatch(updateInformationContentThunk(data)) : dispatch(createInformationContentThunk(data))

    };
    const getLanguage = (lng: any): void => {
        changeAcceptLanguage(lng)
        setInfoContentLng(lng)
    }

    return (
        <>
            {contextHolder}
            {errors?.map((item: any) => {
                return (
                    <span className={style.error}>
                        {t(`INFOCONTENT.${item.message.toUpperCase()}`)}
                    </span>
                )
            })}
            <div className={style.langContainer}>
                <span className={style.currentLngLabel}>
                    {currentLangLabel ? currentLangLabel : lang}
                </span>
                <Language getCurrentLanguage={getLanguage} banner />
            </div>
            <Form
                name="basic"
                form={form}
                style={{ maxWidth: 1000 }}
                onFinish={onFinish}
            >
                <div className={style.settingsHomeTab}>
                    <Form.Item
                        label="About Title"
                        name="aboutTitle"
                        rules={[{ required: true, message: 'Please input about title!' }]}
                        className={style.label}
                    >
                        {loading
                            ? <Skeleton.Input
                                active
                                className={style.descriptionSkeleton}
                            />
                            : <Input placeholder="About Title" />}
                    </Form.Item>
                    <Form.Item
                        label="About Text"
                        name="about"
                        rules={[{ required: true, message: 'Please input about text!' }]}
                        className={style.label}
                    >
                        {loading
                            ? <Skeleton.Input active className={style.descriptionSkeleton} />
                            : <TextArea rows={4} placeholder="About Text" className={style.metadesc} />}
                    </Form.Item>
                </div>
                <div className={style.settingsHomeTab}>
                    <Form.Item
                        label="Banner Title"
                        name="bannerTitle"
                        rules={[{ required: true, message: 'Please input banner title!' }]}
                        className={style.label}
                    >
                        {loading ? <Skeleton.Input active className={style.descriptionSkeleton} /> : <Input placeholder="Banner Title" />}
                    </Form.Item>
                    <Form.Item
                        label="Banner Text"
                        name="banner"
                        rules={[{ required: true, message: 'Please input banner text!' }]}
                        className={style.label}>
                        {loading
                            ? <Skeleton.Input
                                active
                                className={style.descriptionSkeleton}
                            />
                            : <TextArea
                                rows={4}
                                placeholder="Banner Text"
                                className={style.metadesc}
                            />}
                    </Form.Item>
                </div>
                <div className={style.updateMetaButtonRow}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className={style.updateMeta}
                    >
                        {t("MAIN.UPDATE")}
                    </Button>
                </div>
            </Form>
        </>
    )
}
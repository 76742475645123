import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { createSocial, deleteSocial, getOneSocial, getSocials, updateSocial } from "@services/social.service";
import { ISocialResponseData } from "@models/index";

const initialState: ISocialResponseData = {
    message: "",
    statusCode: 0,
    statusName: "",
    success: false,
    isSocialCreated: false,
    isSocialUpdated: false,
    isSocialDeleted: false,
    loading: false,
    about: "",
    address: "",
    copyright: "",
    description: "",
    favicon: {},
    keyword: "",
    logo: {},
    title: "",
    data: [],
    socialData: [],
    errors: [],
    singleCardLoading: false
};

export const createSocialThunk = createAsyncThunk(
    "social/createSocial",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await createSocial(data);
            return Promise.resolve(response);
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const getSocialsThunk = createAsyncThunk(
    "socal/getSocials",
    async (_, { rejectWithValue }) => {
        try {
            const response = await getSocials();
            return Promise.resolve(response.data);
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const deleteSocialsThunk = createAsyncThunk(
    "socal/deleteSocials",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await deleteSocial(data);
            return Promise.resolve(response);
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const getOneSocialsThunk = createAsyncThunk(
    "socal/getOneSocials",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await getOneSocial(data);
            return Promise.resolve(response.data);
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateSocialThunk = createAsyncThunk(
    "update/updateSsocial",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await updateSocial(data);
            return Promise.resolve(response);
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const SocialSlice = createSlice({
    name: "social",
    initialState,
    reducers: {
        resetSocialErrors: (state) => {
            state.errors = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createSocialThunk.fulfilled, (state: ISocialResponseData, { payload }: PayloadAction<any>) => {
            state.isSocialCreated = true;
            state.statusCode = payload.statusCode;
            state.statusName = payload.statusName;
            state.errors = [];
            state.message = "";
        });
        builder.addCase(createSocialThunk.rejected, (state: ISocialResponseData, { payload }: PayloadAction<any>) => {
            if ("errors" in payload) {
                state.errors = payload.errors
            } else {
                state.errors = payload.message?.toUpperCase()
            }
            state.message = payload.message;
            state.isSocialCreated = false;
        });

        builder.addCase(getSocialsThunk.pending, (state: ISocialResponseData) => {
            state.loading = true;
            state.isSocialCreated = false
            state.isSocialUpdated = false
            state.isSocialDeleted = false
        });

        builder.addCase(getSocialsThunk.fulfilled, (state: ISocialResponseData, { payload }: PayloadAction<any>) => {
            state.errors = [];
            state.success = true;
            state.socialData = payload;
            state.loading = false;
            state.isSocialCreated = false;
            state.errors = [];
            state.message = "";
        });
        builder.addCase(getSocialsThunk.rejected, (state: ISocialResponseData, { payload }: PayloadAction<any>) => {
            state.success = false;
            state.loading = false;
            state.isSocialCreated = false;
            state.errors = payload.errors;
        });

        builder.addCase(deleteSocialsThunk.fulfilled, (state: ISocialResponseData, { payload }: PayloadAction<any>) => {
            state.isSocialCreated = false;
            state.isSocialDeleted = true;
            state.statusCode = payload.statusCode;
            state.statusName = payload.statusName;
            state.errors = [];
            state.message = "";
        });

        builder.addCase(deleteSocialsThunk.rejected, (state: ISocialResponseData, { payload }: PayloadAction<any>) => {
            state.isSocialDeleted = false;
            state.isSocialCreated = false;
            state.errors = payload.errors;
        });

        builder.addCase(getOneSocialsThunk.fulfilled, (state: ISocialResponseData, { payload }: PayloadAction<any>) => {
            state.isSocialCreated = false;
            state.isSocialUpdated = false;
            state.isSocialDeleted = false;
            state.data = payload;
            state.statusCode = payload.statusCode;
            state.statusName = payload.statusName;
            state.errors = [];
            state.message = "";
            state.singleCardLoading = false
        });

        builder.addCase(getOneSocialsThunk.pending, (state: ISocialResponseData) => {
            state.singleCardLoading = true
        });


        builder.addCase(getOneSocialsThunk.rejected, (state: ISocialResponseData, { payload }: PayloadAction<any>) => {
            state.errors = payload.errors;
            state.isSocialDeleted = false;
            state.singleCardLoading = false
        });


        builder.addCase(updateSocialThunk.pending, (state: ISocialResponseData) => {
            state.isSocialUpdated = false;
        });

        builder.addCase(updateSocialThunk.fulfilled, (state: ISocialResponseData, { payload }: PayloadAction<any>) => {
            state.isSocialCreated = false;
            state.isSocialUpdated = true;
            state.isSocialDeleted = false;
            state.data = payload;
            state.statusCode = payload.statusCode;
            state.statusName = payload.statusName;
            state.errors = [];
            state.message = "";
        });
        builder.addCase(updateSocialThunk.rejected, (state: ISocialResponseData, { payload }: PayloadAction<any>) => {
            if ("errors" in payload) {
                state.errors = payload.errors
            } else {
                state.errors = payload.message?.toUpperCase()
            }
            state.isSocialUpdated = false;
            state.isSocialDeleted = false;
        });
    },
});

export const { resetSocialErrors } = SocialSlice.actions

export const getOneSocialInfo = (state: any) => {
    return state.social.data;
}
export const getIsSocialCreated = (state: any) => {
    return state.social.isSocialCreated;
}
export const getIsSocialUpdated = (state: any) => {
    return state.social.isSocialUpdated;
}
export const getIsSocialDeleted = (state: any) => {
    return state.social.isSocialDeleted
}
export const getSocialData = (state: any) => {
    return state.social.socialData;
}
export const getSocialErrors = (state: any) => {
    return state.social.errors;
}

export const getSocialErrorMessage = (state: any) => {
    return state.social.message;
}

export const getSocialLoading = (state: any) => {
    return state.social.loading;
}

export const getSingleSectionLoading = (state: any) => {
    return state.social.singleCardLoading
}
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LeftOutlined } from '@ant-design/icons';
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Select
} from "antd";

import {
  createUserFromAdminThunk,
  getUserByIdThunk,
  getUserErrors,
  getUserInfo,
  isAddUserLoading,
  isUserCreatedByAdmin,
  isUserUpdatedByAdmin,
  updateUserByAdminThunk,
  getProfessionList,
  getProfessionsThunk
} from "@store/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { IProfesionList, IUserInfOByID } from "@models/index";
import { BASE_URL, VALIDATION_PATTERNS } from "@utils/index";
import { CustomButton } from "@components/index";

import style from "../user.module.css";

const User = (): JSX.Element => {

  const { Option } = Select;
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation()

  const [_, setSearchParams] = useSearchParams();

  const userById = useAppSelector<IUserInfOByID>(getUserInfo)
  const loadingState = useAppSelector<boolean>(isAddUserLoading);
  const roles = useAppSelector<IProfesionList[]>(getProfessionList);
  const isCreated = useAppSelector<boolean>(isUserCreatedByAdmin);
  const userErrors = useAppSelector(getUserErrors);
  const isUpdate = useAppSelector(isUserUpdatedByAdmin);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<any>(location?.state?.page);
  const [statusValue, setStatusValue] = useState(userById?.status);

  useEffect(() => {
    setCurrentPage(location?.state?.page)
    id && dispatch(getUserByIdThunk(id));
    dispatch(getProfessionsThunk());
  }, [id, dispatch])

  useEffect(() => {
    if (userById.id && id) {
      const { firstName, lastName } = userById
      setSearchParams({ name: `${firstName} ${lastName}` });
    }
  }, [id, userById.id]);

  useEffect(() => {
    if (roles.length) {
      roles?.map((item: any) => {
        return {
          value: item.name,
          label: item.key
        }
      })
    }
  }, [roles])

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        userName: userById?.username,
        firstName: userById?.firstName,
        lastName: userById?.lastName,
        email: userById?.email,
        image: userById?.image,
        phone: userById?.phone,
        role: userById?.profession?.id,
        status: userById?.status,
        password: userById?.password
      })
    }
    setStatusValue(userById?.status)
  }, [userById.id, isEdit])

  useEffect(() => {
    if (isCreated || isUpdate) {
      navigate("/user-management")
    }
  }, [isCreated, isUpdate])

  const onChange = (e: RadioChangeEvent): void => {
    setStatusValue(e.target.value);
  }

  const onFinish = (values: any): void => {

    const datas: any = {
      data: {
        "username": values.userName,
        "firstName": values.firstName,
        "lastName": values.lastName,
        "email": values.email,
        "professionId": values.role,
        "phone": values.phone,
        "status": statusValue ? statusValue : values.status,
        "password": values.password && values.password
      },
      "id": id && id,
    }

    dispatch(id ? updateUserByAdminThunk(datas) : createUserFromAdminThunk(datas.data))
  }

  const receivedErrors = (): JSX.Element | JSX.Element[] => {
    if (Array.isArray(userErrors)) {
      return userErrors?.map((item: any) => {
        return (
          <p className={style.error}>
            {t(`VALIDATIONS.${item.message.toUpperCase()}`)}
          </p>
        )
      })
    } else if (userErrors) {
      return (
        <p className={style.error}>
          {t(`ERRORS.${userErrors}`)}
        </p>
      )
    }
    return <></>
  }

  return (
    <div className="bgColor">
      <CustomButton
        onClick={() => navigate("/user-management", { state: { page: currentPage } })}
        name={"Back"}
        position={"right"}
        icon={<LeftOutlined />}
      />

      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600, clear: "both" }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        {receivedErrors()}
        
        <Form.Item
          label="User Name"
          name="userName"
          className={style.label}
          rules={[
            { required: true, message: t('VALIDATIONS.REQUIERD_USERNAME') as string },
            { min: 1, message: t('VALIDATIONS.MIN_USERNAME') as string },
            { max: 50, message: t('VALIDATIONS.MAX_USERNAME') as string },
            { pattern: new RegExp(VALIDATION_PATTERNS.USERNAME, 'i'), message: t('VALIDATIONS.USERNAME_PATTERN') as string }
          ]}
        >
          <Input autoComplete="new-user" disabled={id && !isEdit ? true : false} />
        </Form.Item>
        <Form.Item
          label="First Name"
          name="firstName"
          className={style.label}
          rules={[
            { required: true, message: t('VALIDATIONS.REQUIERD_FIRSTNAME') as string },
            { pattern: new RegExp(VALIDATION_PATTERNS.NAME, 'i'), message: t('VALIDATIONS.NAME_PATTERN') as string }
          ]}
        >
          <Input autoComplete="new-user" disabled={id && !isEdit ? true : false} />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="lastName"
          className={style.label}
          rules={[
            { required: true, message: t('VALIDATIONS.REQUIERD_LASTNAME') as string },
            { pattern: new RegExp(VALIDATION_PATTERNS.NAME, 'i'), message: t('VALIDATIONS.NAME_PATTERN') as string }
          ]}
        >
          <Input autoComplete="new-user" disabled={id && !isEdit ? true : false} />
        </Form.Item>
        <Form.Item
          label="Email Address"
          name="email"
          className={style.label}
          rules={[
            {
              required: true,
              message: t('VALIDATIONS.REQUIERD_EMAIL') as string
            },
            {
              pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
              message: t('ERRORS.EMAIL_VALIDATION_ERROR') as string,
            }
          ]}
        >
          <Input autoComplete="new-user" disabled={id && !isEdit ? true : false} />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          className={style.label}
          rules={[{ required: true, message: t('VALIDATIONS.REQUIERD_PHONE') as string },
          { pattern: new RegExp(VALIDATION_PATTERNS.PHONE, "i"), message: t('VALIDATIONS.ERR_PHONE_IS_PHONE_NUMBER') as string }]}
        >
          <Input autoComplete="new-user" disabled={id && !isEdit ? true : false} />
        </Form.Item>
        {!id ? <>
          <Form.Item
            label="Password"
            name="password"
            className={style.label}
            rules={[
              { required: true, message: t('VALIDATIONS.REQUIERD_PASSSWORD') as string },
              { pattern: new RegExp(VALIDATION_PATTERNS.PASSWORD, 'i'), message: t('VALIDATIONS.PASSWORD_PATTERN') as string }
            ]}
            hasFeedback
          >
            <Input.Password autoComplete="new-password" disabled={id && !isEdit ? true : false}
            />
          </Form.Item>
          <Form.Item
            name="confirm"
            className={style.label}
            label="Retype Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: t("ERRORS.PASSWORD_MATCHED_CONFIRM_ERROR") as string,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t("ERRORS.PASSWORD_MATCHED_CONFIRM_ERROR") as string)
                  );
                },
              }),
            ]}
          >
            <Input.Password
              onPaste={e => {
                e.preventDefault()
                return false;
              }}
              disabled={id && !isEdit ? true : false}
              autoComplete="new-password"
            />
          </Form.Item>
        </>
          : null}
        <Form.Item
          label="Role"
          name="role"
          className={style.label}
          rules={[{ required: true, message: t('VALIDATIONS.REQUIERD_ROLE') as string }]}
        >
          <Select
            className={style.label}
            value={id ? userById?.profession?.id : "Select Role"}
            disabled={id && !isEdit ? true : false}
          >
            {roles?.map((item: any) => (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            ))}

          </Select>
        </Form.Item>
        {!!userById.cv &&
          <Form.Item
            label="View CV"
            name="cv"
            className={style.label}>

            <a href={`${BASE_URL}${userById.cv.path}`} target="_blank">
              <CustomButton name={"View CV"} />
            </a>
          </Form.Item>}
        {!!id && <Form.Item
          label="Status"
          name="status"
          className={style.label}
          valuePropName="checked"
        >
          <Radio.Group
            disabled={id && !isEdit ? true : false}
            onChange={onChange}
            value={statusValue ? statusValue : userById.status}
          >
            <Radio value={"active"}>Active</Radio>
            <Radio value={"blocked"}>Blocked</Radio>
            <Radio value={"deactivated"}>Deactivate</Radio>
          </Radio.Group>
        </Form.Item>}
        <Form.Item wrapperCol={{ offset: 16, span: 16 }}>
          {id ?
            !isEdit ?
              <div className={style.addUserButton}
                onClick={() => setIsEdit(true)} >
                {t("MAIN.EDIT")}
              </div> :
              <div style={{ display: "flex" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    setStatusValue(userById?.status)
                    setIsEdit(false);
                  }}
                  style={{ marginRight: "10px" }}
                  className={style.addUserButton}
                >
                  {t("MAIN.CANCEL")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={style.addUserButton}
                  loading={loadingState}
                >
                  {t("MAIN.UPDATE")}
                </Button>
              </div>

            :
            <Button
              type="primary"
              htmlType="submit"
              className={style.addUserButton}
              loading={loadingState}
            >
              {t('MAIN.ADD')}
            </Button>
          }
        </Form.Item>
      </Form>
    </div>
  )
}

export default User

// const userErrorMessage = useAppSelector<string>(getUserErrorMessage);
{/* {(userErrorMessage) ? <p className={style.error}> {t(`ERRORS.${userErrorMessage}`)}</p> : " "} */ }
import { api } from ".";

/*API calls for Banner */
export const updateBanner = (data: any) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("content", data.content);
    formData.append("languageId", data.language);
    data.images.map((item: any) => {
        formData.append("images", item.originFileObj)
    })
    return api.put(`/banners/${data.id}`, formData);
}
export const createBanner = (data: any) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("content", data.content);
    formData.append("languageId", data.language);
    data.images.map((item: any) => {
        formData.append("images", item.originFileObj)
    })
    return api.post(`/banners`, formData);
}
export const getOneBanner = (lng: any) => {
    return api.get(`/banners`, {
        // headers: {
        //     'Accept-Language': lng,
        // }
    }
    );
}
export const getAllBanner = () => {
    return api.get(`banners`);
}
export const deleteBannerImage = (data: any) => {
    return api.delete(`/banners/${data.bID}/image/${data.id}`);
}
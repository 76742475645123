import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { createInformationContent, getInformationContent, updateInformationContent } from "@services/information_content.service";
import { IInformationContent } from "@models/index";

const initialState: IInformationContent = {
    message: "",
    statusCode: 0,
    isInfoContentUpdated: false,
    isInfoContentCreated: false,
    statusName: "",
    success: false,
    loading: false,
    data: [],
    errors: []
};

export const getInformationContentThunk = createAsyncThunk(
    "informationcontenet/getinformationcontent",
    async (lng:any, { rejectWithValue }) => {
        try {
            const response = await getInformationContent(lng);
            return Promise.resolve(response.data);
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateInformationContentThunk = createAsyncThunk(
    "informationcontenet/updateinformationcontenet",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await updateInformationContent(data);           
            return Promise.resolve(response);
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const createInformationContentThunk = createAsyncThunk(
    "informationcontent/createinformationcontenet",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await createInformationContent(data);           
            return Promise.resolve(response);
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const InformationContentSlice = createSlice({
    name: "informationContent",
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder.addCase(getInformationContentThunk.pending, (state: IInformationContent) => {
            state.loading = true;
            state.data=[];
        });

        builder.addCase(getInformationContentThunk.fulfilled, (state: IInformationContent, { payload }: PayloadAction<any>) => {
            state.statusCode = payload.statusCode;
            state.statusName = payload.statusName;
            state.data = payload;
            state.isInfoContentUpdated = false;
        });
        builder.addCase(getInformationContentThunk.rejected, (state: IInformationContent, { payload }: PayloadAction<any>) => {
            state.errors = payload.errors;
            state.message = payload.message
        });
        builder.addCase(updateInformationContentThunk.pending, (state: IInformationContent) => {
            state.loading = true;
        });
        builder.addCase(updateInformationContentThunk.fulfilled, (state: IInformationContent, { payload }: PayloadAction<any>) => {
            state.statusCode = payload.statusCode;
            state.statusName = payload.statusName;
            state.isInfoContentUpdated = true;
            state.data=[];
            state.errors = [];
            state.message = "";
        });
        builder.addCase(updateInformationContentThunk.rejected, (state: IInformationContent, { payload }: PayloadAction<any>) => {
            state.errors = payload.errors;
            state.message = payload.message
        });
        builder.addCase(createInformationContentThunk.pending, (state: IInformationContent) => {
            state.loading = true;
        });
        builder.addCase(createInformationContentThunk.fulfilled, (state: IInformationContent, { payload }: PayloadAction<any>) => {
            state.statusCode = payload.statusCode;
            state.statusName = payload.statusName;
            state.isInfoContentUpdated = true;
            state.data=[];
            state.errors = [];
            state.message = "";
        });
        builder.addCase(createInformationContentThunk.rejected, (state: IInformationContent, { payload }: PayloadAction<any>) => {
            state.errors = payload.errors;
            state.message = payload.message
        });
    },
});

export const getInfoContent = (state: any) => {
    return state.informationContent.data;
}
export const isICUpdated = (state: any) => {
    return state.informationContent.isInfoContentUpdated;
}
export const getErrorsIC = (state: any) => {
    return state.informationContent.errors;
}
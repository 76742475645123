
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { createProfession, deleteProfession, getProfessionbyId, getProfessions, updateProfession } from "@services/profession.service";
import { IProfessionResponseData } from "@models/index";

const initialState: IProfessionResponseData = {
  message: "",
  statusCode: 0,
  statusName: "",
  success: false,
  loading: false,
  data: [],
  isCreated: false,
  isRoleUpdated: false,
  isRoleDeleted: false,
  isListRole: false,
  showEditModal: false,
  edited_id: 0,
  roleById: {},
  errors: []
}

export const getProfessionsThunk = createAsyncThunk(
  "profession/getprofessions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getProfessions();
      return Promise.resolve(response.data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getProfessionByIdThunk = createAsyncThunk(
  "profession/getprofessionByID",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getProfessionbyId(data);
      return Promise.resolve(response.data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createProfessionThunk: any = createAsyncThunk(
  "profession/createprofession",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await createProfession(data);
      return Promise.resolve(response);
    } catch (error: any) {

      return rejectWithValue(error.response.data);
    }
  }
);
export const updateProfessionThunk = createAsyncThunk(
  "update/updateProfession",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await updateProfession(data);
      return Promise.resolve(response);
    } catch (error: any) {

      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteProfessionThunk = createAsyncThunk(
  "delete/deleteprofession",
  async (data: any, { rejectWithValue }) => {
    try {

      const response = await deleteProfession(data);

      return Promise.resolve(response);
    } catch (error: any) {

      return rejectWithValue(error.response.data);
    }
  }
);

export const ProfessionsSlice = createSlice({
  name: "profession",
  initialState,
  reducers: {
    openCloseRoleModal: (state, { payload }: PayloadAction<any>) => {
      state.isListRole = payload
      state.showEditModal = false
    },
    isshowEditModal: (state, { payload }: PayloadAction<any>) => {
      state.showEditModal = payload.show
      state.isListRole = false
      state.edited_id = payload.id
      state.message = ""
    },
    resetRoleById: (state) => {
      state.roleById = {};
    },
    resetEditedID: (state) => {
      state.edited_id = 0
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getProfessionsThunk.pending, (state: IProfessionResponseData) => {
      state.loading = true;

    });
    builder.addCase(getProfessionsThunk.fulfilled, (state: IProfessionResponseData, { payload }: PayloadAction<any>) => {
      state.success = true;
      state.data = payload;
      state.loading = false;
      state.isRoleDeleted = false;
      state.isCreated = false;
      state.message = "";
      state.errors = [];
    });
    builder.addCase(getProfessionsThunk.rejected, (state: IProfessionResponseData) => {
      state.success = false;
      state.loading = false;
    });
    builder.addCase(getProfessionByIdThunk.pending, (state: IProfessionResponseData) => {
      state.loading = true;
    });
    builder.addCase(getProfessionByIdThunk.fulfilled, (state: IProfessionResponseData, { payload }: PayloadAction<any>) => {
      state.success = true;
      state.roleById = payload;
      state.isRoleDeleted = false;
      state.isCreated = false;
      state.loading = false;
      state.message = "";
      state.errors = [];
    });
    builder.addCase(getProfessionByIdThunk.rejected, (state: IProfessionResponseData) => {
      state.success = false;
      state.loading = false;
    });
    builder.addCase(updateProfessionThunk.fulfilled, (state: IProfessionResponseData, { payload }: PayloadAction<any>) => {
      state.isRoleUpdated = true;
      state.isRoleDeleted = false;
      state.isCreated = false;
      state.statusCode = payload.statusCode;
      state.statusName = payload.statusName;
      state.message = "";
      state.errors = [];
    });
    builder.addCase(updateProfessionThunk.rejected, (state: IProfessionResponseData, { payload }: any) => {
      state.isRoleUpdated = false;
      state.message = payload?.message
      state.errors = payload.errors;
    });
    builder.addCase(updateProfessionThunk.pending, (state: IProfessionResponseData, { payload }: any) => {
      state.isRoleUpdated = payload;
    });
    builder.addCase(createProfessionThunk.pending, (state: IProfessionResponseData, { payload }: any) => {
      state.success = payload;
    });
    builder.addCase(createProfessionThunk.fulfilled, (state: IProfessionResponseData, { payload }: PayloadAction<any>) => {
      state.success = payload;
      state.isCreated = true;
      state.isRoleDeleted = false;
      state.message = "";
      state.errors = [];
    });
    builder.addCase(createProfessionThunk.rejected, (state: IProfessionResponseData, { payload }: any) => {
      state.success = false;
      state.isRoleDeleted = false;
      state.message = payload?.message;
      state.errors = payload?.errors;
    });

    builder.addCase(deleteProfessionThunk.fulfilled, (state: IProfessionResponseData, { payload }: PayloadAction<any>) => {
      state.success = payload;
      state.isRoleDeleted = true;
      state.isCreated = false;
      state.message = "";
      state.errors = [];

    });
    builder.addCase(deleteProfessionThunk.rejected, (state: IProfessionResponseData) => {
      state.isRoleDeleted = false;
      state.success = false;
    });
  },
});

export const { openCloseRoleModal, isshowEditModal, resetRoleById, resetEditedID } = ProfessionsSlice.actions;

export const getProfessionList = (state: any) => {
  return state.profession.data;
}
export const getIsProfessionCreated = (state: any) => {
  return state.profession.isCreated;
}
export const getIsRoleDeleted = (state: any) => {
  return state.profession.isRoleDeleted;
}
export const getOpenCloseRoleModal = (state: any) => {
  return state.profession.isListRole;
}
export const getshowEditModal = (state: any) => {
  return state.profession.showEditModal;
}
export const getEditedId = (state: any) => {
  return state.profession.edited_id;
}
export const getRoleById = (state: any) => {
  return state.profession.roleById;
}
export const getIsUpdated = ((state: any) => {
  return state.profession.isRoleUpdated
})
export const getErrorMessage = ((state: any) => {
  return state.profession.message
})
export const getErrorsField = ((state: any) => {
  return state.profession.errors
})
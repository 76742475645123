import { api } from ".";

/*API calls for user management */
export const getUsers = (data: any) => {
    const { take, order, orderBy, professionId, page } = data
    const searchParams = data?.searchData?.toString();
    return api.get(`users`, {
        params: {
            search: searchParams,
            take,
            order,
            orderBy,
            professionId,
            page
        }
    });
}
export const getUserByID = (id: any) => {
    return api.get(`/users/${id}`);
};
export const updateUserByAdmin = (data: any) => {
    return api.put(`/users/${data.id}`, data.data);

};
export const changePasswordByAdmin = (data: any) => {
    return api.post(`/users/change-password/${data.id}`, data);
};
export const deleteUser = (data: any) => {
    return api.delete(`/users/${data}`);
}
export const createUserFromAdmin = (data: any) => {
    return api.post(`/users`, data);
};
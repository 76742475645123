import { Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
    getAllPartnersThunk,
    getIsPartnersCreated,
    getIsPartnersDeleted,
    getIsPartnersUpdated,
    getOnePartnerThunk,
    getPartnersData,
    getPartnersLoading,
    resetPartnerErrors
} from "@store/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { Partner, ShowPartners } from "./components/index";
import { PartnersSkeleton } from "@components/index";
import { IPartnerInfo } from "@models/index";

import { plus } from "@assets/index";

import styles from "./partner.module.css";

export const Partners = (): JSX.Element => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const partners = useAppSelector<IPartnerInfo[]>(getPartnersData);
    const loading = useAppSelector<boolean>(getPartnersLoading);
    const isPartnerCreated = useAppSelector<boolean>(getIsPartnersCreated);
    const isPartnerUpdated = useAppSelector<boolean>(getIsPartnersUpdated);
    const isPartnerDeleted = useAppSelector<boolean>(getIsPartnersDeleted);

    const [isPlusClicked, setIsPlusClicked] = useState<boolean>(false);
    const [newPartnerCard, setNewPartnerCard] = useState<any[]>([]);
    const [isEdit, setisEdit] = useState<boolean>(false);
    const [isEditedId, setIsEditedId] = useState<boolean>();

    useEffect(() => {
        if (isEditedId) {
            dispatch(getOnePartnerThunk(isEditedId));
        }
    }, [isEditedId])

    useEffect(() => {
        dispatch(getAllPartnersThunk());
        if (isPartnerCreated) {
            closeAddPartner()
        } else if (isPartnerUpdated) {
            isPartnerEditClicked({ isEdit: false, editedId: null })
        }
    }, [isPartnerCreated, isPartnerUpdated, isPartnerDeleted])

    const closeAddPartner = (): void => {
        setIsPlusClicked(false);
        setNewPartnerCard([]);
    }

    const isClicked = (e: any): void => {
        setIsPlusClicked(e)
        setNewPartnerCard([...newPartnerCard, {}]);
    }

    const isCancelClicked = (): void => {
        dispatch(resetPartnerErrors())
        newPartnerCard.pop();
        setNewPartnerCard([...newPartnerCard]);
    }

    const isPartnerEditClicked = (e: any): void => {
        setisEdit(e.isEdit);
        setIsEditedId(e.editedId);
    }

    if (loading) {
        return <PartnersSkeleton />
    }

    return (
        <Row gutter={[32, 95]}>
            <Col span={2}>
                <p className={styles.partnersLabel}>
                    {t("MAIN.PARTNERS")}
                </p>
            </Col>
            <Col span={20} >
                <div className="wrap">
                    {partners &&
                        partners.map((item: any, key: number) => {
                            return (<Col span={8} key={key} className="m30" >
                                {isEdit && isEditedId == item.id ?
                                    <Partner id={item.id} isCancelClicked={() => isPartnerEditClicked({
                                        isEdit: false, editedId: null
                                    })} /> :
                                    <ShowPartners
                                        link={item.link}
                                        image={item.image}
                                        title={item.name} id={item.id}
                                        isPartnerEditClicked={isPartnerEditClicked}
                                    />}
                            </Col>)
                        })}
                    {isPlusClicked &&
                        newPartnerCard?.map((_, index) => {
                            return (
                                <Col
                                    span={8}
                                    key={index}
                                    className="m30">
                                    <Partner isCancelClicked={isCancelClicked} />
                                </Col>
                            )
                        })}
                    <Col span={8} className="m30">
                        <div className="noRadius">
                            <Card className={styles.newCard}>
                                <img
                                    src={plus}
                                    className={styles.plusicon}
                                    onClick={() => isClicked(true)}
                                    alt="Plus icon"
                                />
                            </Card>
                            <div className={styles.actionsText}>
                                {t("MAIN.ADD")} {t("MAIN.PARTNERS")}
                            </div>
                        </div>
                    </Col>
                </div>
            </Col>
        </Row>
    );
};
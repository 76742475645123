import { api } from ".";

export const getStatistics = (data: any) => {

    return api.get('statistics', {
        params: {
            from: data[0],
            to: data[1]
        }
    });
}
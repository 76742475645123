
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ILanguageResponseData } from "@models/index";
import { getLanguages } from "@services/language.service";
import { getLangaugeByKey } from "@services/index";

const initialState: ILanguageResponseData = {
    message: "",
    statusCode: 0,
    statusName: "",
    success: false,
    data: "",
    errors: [],
    loading: false,
    languages: [],
    languagesError: []
};

export const getLanguageThunk = createAsyncThunk(
    "language/getlanguageidbykey",
    async (key: any, { rejectWithValue }) => {
        try {
            const response = await getLangaugeByKey(key);
            return Promise.resolve(response.data);
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const receiveLanguages = createAsyncThunk(
    "languages/receiveLanguages",
    async (_, { rejectWithValue }) => {
        try {
            const response = await getLanguages();
            return Promise.resolve(response);
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const LanguageSlice = createSlice({
    name: "language",
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder.addCase(receiveLanguages.fulfilled, (state: any, { payload }) => {
            const parsedLanguages = payload.data.map((lng: any) => {
                const { id, key, name } = lng
                return {
                    id,
                    key,
                    label: name
                }
            })
            state.languages = parsedLanguages
        });

        builder.addCase(receiveLanguages.rejected, (state: any, { payload }) => {
            state.languagesError = payload
        });

        builder.addCase(getLanguageThunk.pending, (state: ILanguageResponseData) => {
            state.loading = true;
        });
        builder.addCase(getLanguageThunk.fulfilled, (state: ILanguageResponseData, { payload }: PayloadAction<any>) => {
            state.errors = [];
            state.success = payload.success;
            state.loading = false;
        });
        builder.addCase(getLanguageThunk.rejected, (state: ILanguageResponseData, { payload }: PayloadAction<any>) => {
            state.success = false;
            state.loading = false;
            state.errors = payload.errors;
        });
    },
});

export const getLangId = (state: any) => {
    return state.language.data;
}

export const getAllLanguages = (state: any) => {
    return state.language.languages
}
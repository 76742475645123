import React from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";

import { removeSymbolAndCapitalize } from "@utils/index";

import { ReactComponent as DashboardIcon } from "@assets/dashboard.svg";

import style from "./breadcrumb.module.css";

export const CustomBreadcrumbs = (): JSX.Element => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const pathSegments = location.pathname.split('/').filter(segment => segment !== '');

  function sliceURLSegment(url: string[]): string {
    return `/${url.slice(0, 1).join('/')}`
  }

  function generateBreadcrumbItemForString(segment: string, index?: number): JSX.Element {
    return (
      <React.Fragment key={index}>
        {' / '}
        <Link to={sliceURLSegment(pathSegments)} className={style.breadcrumbItem}>{removeSymbolAndCapitalize(segment) && removeSymbolAndCapitalize(segment)} </Link>
      </React.Fragment>
    )
  }

  return (
    <div className={style.breadcrumb}>
      <Link to="/" className={style.breadcrumbItem}>
        <DashboardIcon />
      </Link>

      {pathSegments.map((segment: any, index: number) => (
        generateBreadcrumbItemForString(segment, index)
      ))}

      <Link
        to={sliceURLSegment(pathSegments)}
        className={style.breadcrumbItem}
      >
        {searchParams.getAll('name')[0]}
      </Link>
    </div>
  );
};
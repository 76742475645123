import { api } from ".";

/*REPORTS API's */
export const getReports = (data: any) => {
    return api.get(`task-reports/all`, {
        params: {
            ...data
        }
    });
}
export const getDetailsReports = (id: number) => {
    return api.get(`task-reports/${id}`);
}
export const deleteReports = (reportId: number) => {
    return api.delete(`task-reports/${reportId}`);
}
export const downloadReportByUser = (data: any) => {
    return api.get(`task-reports/download`,{
        params:{
            userId:+data.userId,
            search:data.search
        } 
    });
    //return api.get(`task-reports/download?search=success&from=2023-11-08&to=2023-11-9&userId=306`)
}
import { objectToFormData } from "@utils/index";
import { api } from ".";

/*API calls for  Socials */
export const createSocial = (data: any) => {
    const formData = objectToFormData(
        {
            "link": data.url,
            "name": data.name,
            "image": data.image
        });
    return api.post('socialMedias', formData);
}
export const getSocials = () => {
    return api.get('/socialMedias');
}
export const deleteSocial = (id: any) => {
    return api.delete(`/socialMedias/${id}`);
}
export const getOneSocial = (id: any) => {
    return api.get(`/socialMedias/${id}`);
}
export const updateSocial = (data: any) => {
    const { socialData } = data

    const formData = objectToFormData(
        {
            "link": socialData.url,
            "name": socialData.name,
            "image": socialData.image
        });
    return api.put(`/socialMedias/${data.id}`, formData);
}
import { Table } from "antd"
import { ColumnsType } from "antd/es/table";

interface IProps {
    columns: ColumnsType<any>;
    dataSource: Object[];
    scroll?: Object;
    pagination?: Object;
    onChange?: any;
    onRow?: any
}

export const CustomTable = ({
    columns,
    dataSource,
    onChange,
    scroll,
    pagination,
    onRow
}: IProps): JSX.Element => {
    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            scroll={scroll}
            pagination={pagination}
            onRow={onRow}
            onChange={onChange}
        />
    )
}
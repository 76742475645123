import { Card, Col, Skeleton } from "antd"

import styles from './content-card.module.css'

export const ContentCardSkeleton = (): JSX.Element => {
    function generateContentCard(n: number) {
        const contentCards = [];
        for (let i = 0; i < n; i++) {
            contentCards.push(
                <Col key={i} className={styles.cardWrapper}>
                    <Card className={styles.card}>
                        <div className={styles.cardContentWrapper}>
                            <Skeleton.Image
                                active
                                className={styles.image}
                            />
                            <div className={styles.content}>
                                <div className={styles.title}>
                                    <Skeleton
                                        active
                                        title={{ width: 160 }}
                                        paragraph={{
                                            rows: 2,
                                            width: [140, 110],
                                        }} />
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col>
            );
        }
        return contentCards;
    }

    return (
        <div className={styles.mainWrapper}>
            {generateContentCard(3)}
        </div>
    )
}

import { useAppSelector } from "@hooks/index";

import { MainRouter } from "./main-routes";
import AuthRoutes from "./auth-routes";

const Routing = (): JSX.Element => {
  const token = useAppSelector((state) => {
    return state.adminLogin.accessToken;
  })

  return (!(token) ? <AuthRoutes /> : <MainRouter />);
};

export default Routing;


import { Space } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";

import {
  deleteProfessionThunk,
  getIsProfessionCreated,
  getIsRoleDeleted,
  getIsUpdated,
  getProfessionList,
  getProfessionsThunk,
  isshowEditModal,
  openCloseRoleModal,
  resetEditedID
} from "@store/index";
import { CustomButton, CustomModal, CustomTable } from "@components/index";
import { IDataType, IProfesionList, Place } from "@models/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { PAGE_LIMIT, getCookie } from "@utils/index";

import { ReactComponent as DeleteIcon } from "@assets/delete.svg";
import { ReactComponent as EditIcon } from "@assets/edit.svg";

export const ProfessionList = (): JSX.Element => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = getCookie('accessToken');

  const [open, setOpen] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [roleId, setRoleID] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<any>(1);

  const isCreated = useAppSelector<boolean>(getIsProfessionCreated);
  const isUpdated = useAppSelector<boolean>(getIsUpdated);
  const isDeleted = useAppSelector<boolean>(getIsRoleDeleted);
  const professionList = useAppSelector<IProfesionList[]>(getProfessionList);

  useEffect(() => {
    dispatch(getProfessionsThunk());
  }, [dispatch])

  useEffect(() => {
    if (isCreated || isUpdated || isDeleted) {
      dispatch(getProfessionsThunk());
    }
  }, [isCreated, isUpdated, isDeleted])

  const showModalDelete = (id?: any): void => {
    setOpen(true);
    setRoleID(id)
  };

  const handleOk = (): void => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 5000);

    const datas: any = {
      id: roleId,
      token: token
    }

    dispatch(deleteProfessionThunk(datas))
    setOpen(false);
  };

  const handleCancel = (): void => setOpen(false);

  const handlePageChange = (page: number): void => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('page', String(page));
    setCurrentPage(page)
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const paginationConfig = {
    onChange: handlePageChange,
    current: parseInt(new URLSearchParams(location.search).get('page') || currentPage.toString(), 10),
    pageSize: PAGE_LIMIT, // Adjust as per your requirement
    total: professionList.length // Assuming total count is available in trainingList
  };

  const columns: ColumnsType<IDataType> = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (_, record, index) => ((currentPage - 1) * PAGE_LIMIT) + index + 1
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => <>{`${record.name} `}</>,
    },
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => {
            const data = {
              show: true,
              id: record.id
            }
            dispatch(isshowEditModal(data));
          }}>
            <EditIcon />
          </a>
          <a onClick={() => showModalDelete(record.id)}>
            <DeleteIcon />
          </a>
        </Space>
      )
    }
  ]

  return (
    <>
      <CustomButton
        onClick={() => {
          dispatch(openCloseRoleModal(true))
          dispatch(resetEditedID())
        }}
        name={t("MAIN.ADD")}
      />
      <CustomTable
        columns={columns}
        dataSource={professionList}
        pagination={paginationConfig}
      />
      <CustomModal
        place={Place.PROFESSION_LIST}
        title={t("MAIN.DELETE_MESSAGE")}
        open={open}
        ok={handleOk}
        cancel={handleCancel}
        confirmLoading={confirmLoading}
        bodyStyle={{ height: 60 }}
        centered
      />
    </>
  )
}
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Space } from 'antd';
import { LeftOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';

import {
  deleteArticleThunk,
  errorForCategoryArticles,
  getArticleList,
  getArticleLoading,
  getArticleSuccess,
  getArticlesByCategory,
  resetCategoryArticlesErrors
} from '@store/index';
import {
  ArticlesListSkeleton,
  CustomButton,
  CustomModal,
  CustomTable,
  DeleteIcon,
  EditIcon
} from '@components/index';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { changeAcceptLanguage } from '@services/index';
import { createImagePath, DEFAULT_LANGAUGE, PAGE_LIMIT } from '@utils/index';
import { DataType, IArticleListData, Place } from '@models/index';
import { NotFound } from '@pages/index';

import style from './edit-article.module.css'
import '../../App.css'
import './modal.css';

export const ShowArticles = (): JSX.Element => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation()

  const navigate = useNavigate()
  const { category } = useParams();

  const articles = useAppSelector(getArticleList);
  const loading = useAppSelector(getArticleLoading);
  const isDeleted = useAppSelector(getArticleSuccess)
  const errors = useAppSelector(errorForCategoryArticles)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [elementForDelete, setElementForDelete] = useState("")
  const [currentPage, setCurrentPage] = useState<number>(1);

  changeAcceptLanguage(DEFAULT_LANGAUGE)

  useEffect(() => {
    return () => { dispatch(resetCategoryArticlesErrors()) }
  }, [dispatch])

  useEffect(() => {
    if (category) {
      dispatch(getArticlesByCategory({ category, currentPage }))
    }
  }, [category, isDeleted, dispatch])

  const handleOk = (): void => {
    dispatch(deleteArticleThunk(elementForDelete))
    setIsModalOpen(false);
  };

  const handleCancel = () => setIsModalOpen(false)

  const columns: ColumnsType<IArticleListData> = [
    {
      title: 'No',
      dataIndex: 'id',
      key: 'id',
      render: (_, record, index) => ((currentPage - 1) * PAGE_LIMIT) + index + 1,
      sorter: (previousElement: any, nextElement: any): any => {
        return previousElement.id > nextElement.id
      },
      defaultSortOrder: 'descend'
    },
    {
      title: 'Photo',
      dataIndex: 'photo',
      key: 'photo',
      render: (_, record: any) => <img style={{
        width: '95px',
        height: '75px'
      }} src={createImagePath(record.image.path)} alt='No image' />
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (_, record: any) => <p>{record.translations[0].title}</p>,
      sorter: (previousElement: any, nextElement: any) => {
        return previousElement.translations[0].title.localeCompare(nextElement.translations[0].title)
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      sorter: (previousElement: any, nextElement: any) => {
        return previousElement.status.localeCompare(nextElement.status)
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (element) => (
        <Space size="middle">
          <span className={style.iconSpan} onClick={e => {
            e.stopPropagation()
            navigate(`/content-management/article/${element.id}`)
          }}>
            <EditIcon />
          </span>
          <span className={style.iconSpan} onClick={e => {
            e.stopPropagation()
            setElementForDelete(element.id)
            setIsModalOpen(true)
          }}>
            <DeleteIcon />
          </span>
        </Space>
      ),
    },
  ];

  const handleRowClick = (record: DataType): void => navigate(`/content-management/article/${record.id}`)

  return (
    <>
      <div className={style.prevBtnWrapper}>
        <span className={style.addBtn}>
          <CustomButton
            onClick={() => navigate(
              '/content-management/article',
              {
                state: {
                  categoryData: category,
                  fromSectionList: true
                }
              }
            )}
            name={"+ Add Article"}
          />
        </span>
        <CustomButton
          onClick={() => navigate(-1)}
          name={"Back"}
          icon={<LeftOutlined />}
        />
        <CustomModal
          place={Place.SECTION_ARTICLE}
          title={t('MAIN.CONFIRM_DELETE')}
          open={isModalOpen}
          ok={handleOk}
          cancel={handleCancel}
          bodyStyle={{
            display: 'flex',
            alignItems: 'center',
            height: 70,
            width: 472,
            borderRadius: '0px'
          }}
          centered
        />
      </div >
      {
        loading ?
          <ArticlesListSkeleton />
          :
          !(Object.keys(errors).length === 0) ?
            <NotFound /> :
            <>
              <CustomTable
                columns={columns}
                dataSource={articles.data}
                pagination={{
                  pageSize: PAGE_LIMIT,
                  defaultPageSize: PAGE_LIMIT,
                  total: articles.meta?.itemCount,
                  current: currentPage,
                  onChange: (page: any) => {
                    setCurrentPage(page)
                    dispatch(getArticlesByCategory({ category, page }))
                  }
                }}
                onRow={(record: any) => ({
                  onClick: () => handleRowClick(record as any),
                })}
              />
            </>}
    </>
  )
}
import { useEffect } from "react";
import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from "react-router-dom";

import {
  changePasswordByAdminThunk,
  getUserErrorMessage,
  getUserErrors,
  isChangePasswordLoading,
  isPasswordChangedByAdmin
} from "@store/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { getCookie, VALIDATION_PATTERNS } from "@utils/index";
import { CustomButton } from "@components/index";

import style from "../user.module.css";

const ChangePassword = (): JSX.Element => {
  const params: any = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userErrors = useAppSelector<[]>(getUserErrors);
  const userErrorMessage = useAppSelector<string>(getUserErrorMessage);
  const isPasswordChanged = useAppSelector<boolean>(isPasswordChangedByAdmin);
  const loadingState = useAppSelector<boolean>(isChangePasswordLoading);

  const onFinish = (values: any): void => {
    const accessToken = getCookie('accessToken');
    const datas: any = {
      data: {
        "id": params.id,
        "newPassword": values.password
      },
      token: accessToken
    }
    dispatch(changePasswordByAdminThunk(datas))
  };

  useEffect(() => {
    if (isPasswordChanged) {
      navigate("/user-management")
    }
  }, [isPasswordChanged])

  return (
    <div className="bgColor">
      <CustomButton onClick={() => navigate("/user-management")} name={"Back"} position={"right"} icon={<LeftOutlined />} />
      {userErrors
        ? userErrors.map((item: any) => {
          return (
            <p key={item.id} className={style.error}>
              {t(`VALIDATIONS.${item.message.toUpperCase()}`)}
            </p>
          );
        })
        : ""}

      {(userErrorMessage && userErrors?.length) && (
        <p className={style.error}>
          {t(`ERRORS.${userErrorMessage}`)}
        </p>
      )}
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: t('VALIDATIONS.REQUIERD_PASSSWORD') as string },
            { pattern: new RegExp(VALIDATION_PATTERNS.PASSWORD, 'i'), message: t('VALIDATIONS.PASSWORD_PATTERN') as string }]}
          className={style.label}
          hasFeedback
        >
          <Input.Password
            placeholder={"Password" as string}
          />
        </Form.Item>
        <Form.Item
          name="confirm"
          className={style.label}
          label="Retype Password"
          dependencies={["password"]}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    t("ERRORS.PASSWORD_MATCHED_CONFIRM_ERROR") as string
                  )
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder={`${t("MAIN.RETYPE_PASSWORD")}` as string} onPaste={e => {
            e.preventDefault()
            return false;
          }} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 16, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            className={style.addUserButton}
            loading={loadingState}
          >
            {t("MAIN.UPDATE")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ChangePassword
import { Input } from "antd";
import { ChangeEvent } from "react";

import { ReactComponent as SearchIcon } from "@assets/search.svg"

import style from "./searchFilter.module.css";

interface IProps {
  onSearchChange?: (value: string) => void;
}

export const SearchFilter = ({ onSearchChange }: IProps): JSX.Element => {
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (onSearchChange) {
      onSearchChange(value);
    }
  };

  return (
    <div className={style.searchFilter}>
      <Input
        placeholder="Search"
        prefix={<SearchIcon />}
        className={style.search}
        onChange={handleSearchChange}
      />
    </div>
  )
}
import { Form, Select } from 'antd'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect } from 'react';

import {
    ICurrentLanguage,
    IArticleData,
    AntDataType,
    ServerErrorType,
    LocationOfUse
} from '@models/index';
import { articleErrors, sectionList, getSections } from '@store/index';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { getServerError } from '@utils/index';

import style from './section.module.css'
import '../../../App.css'
import { changeAcceptLanguage } from '@services/index';

interface IProps {
    setArticleData: Dispatch<SetStateAction<IArticleData>>;
    setAntData: Function;
    categoryId: number | undefined;
    currentLng: ICurrentLanguage | undefined
}

export const Section = ({
    setArticleData,
    setAntData,
    categoryId,
    currentLng
}: IProps): JSX.Element => {

    const dispatch = useAppDispatch()
    const { state } = useLocation()
    const { t } = useTranslation()

    const serverErrors = useAppSelector(articleErrors)
    const categories = useAppSelector(sectionList)

    const { Option } = Select

    const changeCategoryId = (categoryId: number): void => {
        setArticleData(prev => ({
            ...prev,
            categoryId
        }))
    }

    useEffect(() => {
        dispatch(getSections());
    }, [dispatch])

    useEffect(() => {
        if (currentLng?.id) {
            changeAcceptLanguage(currentLng?.key)
            dispatch(getSections());
        }
    }, [currentLng?.id, dispatch])

    useEffect(() => {
        setAntData(AntDataType.SECTION)
    }, [categoryId])

    useEffect(() => {
        if (!categories || !state) {
            return
        }

        const { sectionKey, categoryData } = state;
        const categoryInfo = state.fromSectionList && /\d/.test(categoryData)
            ? categories.find(({ id }: any) => id === +categoryData)
            : categories.find(({ key }: any) => key === sectionKey ? sectionKey : categoryData);

        if (categoryInfo) {
            setArticleData((prev) => ({
                ...prev,
                categoryId: typeof categoryInfo === 'object' ? categoryInfo.id : categoryInfo,
            }));
        }
    }, [categories, state]);

    function getSectionLabel(data: any): string | undefined {
        const currentSection = data.find((item: any) => item.language.id === currentLng?.id)
        return currentSection?.name
    }

    return (
        <div className='formItemWrapper'>
            <Form.Item
                label="Section"
                name="section"
                rules={[{ required: true, message: `${t('MAIN.CHOOSESECTION')}` }]}
                help={!categoryId && getServerError(LocationOfUse.ARTICLE, ServerErrorType.CATEGORY, serverErrors) ?
                    <p className="errMessage">
                        {t(`ARTICLE.${getServerError(LocationOfUse.ARTICLE, ServerErrorType.CATEGORY, serverErrors)}`)}
                    </p>
                    : undefined}
                className='formItem'
            >
                <Select
                    listHeight={175}
                    className={style.select}
                    onChange={changeCategoryId}
                >
                    {categories?.map((item: any) => {
                        return (
                            <Option
                                value={item.id}
                                key={item.id}
                                className={style.option}
                            >
                                {getSectionLabel(item?.categoryTranslations)}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>
        </div>
    )
}
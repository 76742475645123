import { useTranslation } from "react-i18next";
import { Button, Form, Input, message, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import Upload, { RcFile, UploadFile, UploadProps } from "antd/es/upload";

import {
    deleteBannerImageThunk,
    getAllBannerThunk,
    getBannerErrors,
    getBannerLoading,
    getIsBannerDeleted,
    getOneBannerInfo,
    updateBannerThunk,
    createBannerThunk,
    resetBannerErrors,
    getIsBannerUpdated,
    getIsBannerCreated
} from "@store/index";
import { Language, languages, CustomEditor, CustomModal, editArticleLanguages } from "@components/index";
import { BASE_URL, countLines, getBase64, getCookie } from "@utils/index";
import { IBannerContentData, IImage, Place, ErrorFields } from "@models/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { changeAcceptLanguage } from '@services/index'

import style from "./banner.module.css";

interface IProps {
    lang?: any
}

export const Banner = ({ lang }: IProps): JSX.Element => {

    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const { t, i18n } = useTranslation();
    const token = getCookie('accessToken');

    const isBannerDeleted = useAppSelector<boolean>(getIsBannerDeleted);
    const bannerContent = useAppSelector<IBannerContentData>(getOneBannerInfo);
    const bannerCreated = useAppSelector(getIsBannerCreated)
    const bannerUpdated = useAppSelector(getIsBannerUpdated)
    const loading = useAppSelector<boolean>(getBannerLoading);
    const errors = useAppSelector<[]>(getBannerErrors)

    const [currentLangLabel, setCurrentLangLabel] = useState<string>("English");
    const [editorValue, setEditorValue] = useState<string>(bannerContent?.content);
    const [title, setTitle] = useState<string>(bannerContent?.title);
    const [currentImages, setCurrentImages] = useState<any>([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [bannerlngID, setBannerLngID] = useState<number>(1);
    const [bannerlng, setBannerLng] = useState<string>(i18n.language.toLowerCase());

    /* Upload */
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string>('');
    const [previewTitle, setPreviewTitle] = useState<string>('');
    const [fileList, setFileList] = useState<any>();

    useEffect(() => {
        dispatch(getAllBannerThunk());
        return () => { dispatch(resetBannerErrors()) }
    }, [])

    useEffect(() => {
        if (bannerlng) {
            dispatch(getAllBannerThunk());
        }
    }, [bannerlng])

    useEffect(() => {
        if (bannerCreated || bannerUpdated) {
            successMessage()
            setCurrentImages([])
            // setFileList([])
        }

        if (isBannerDeleted) {
            dispatch(getAllBannerThunk());
        }
    }, [isBannerDeleted, bannerCreated, bannerUpdated, dispatch])

    const getLanguage = (lng: any): void => {
        changeAcceptLanguage(lng)
        setBannerLng(lng);
    }

    useEffect(() => {
        changeAcceptLanguage(bannerlng);
        setEditorValue(bannerContent?.content);
        setTitle(bannerContent.title)
        const obj: any = editArticleLanguages?.find((o: any) => o.key === bannerlng);
        setCurrentLangLabel(obj?.label)
        const lngId: any = languages?.find((o: any) => { if (o.label === currentLangLabel) return o.key });
        setBannerLngID(lngId.key);

        if (bannerlng) {
            dispatch(getAllBannerThunk());
            // setCurrentImages([]);
            // setFileList([])
        }
       
    }, [bannerlng, bannerContent.content, dispatch, bannerCreated, bannerUpdated, currentLangLabel]);

    useEffect(()=>{
        if(currentLangLabel){
            setCurrentImages([]);
            setFileList([]);
        }
    },[currentLangLabel])
    
    useEffect(() => {

        if (bannerContent?.images) {
            const imgList = bannerContent.images.map((item: IImage) => ({
                id: item.id,
                name: item.name,
                url: `${BASE_URL}${item.path}?authorization=${token}`
            }));
            setFileList(imgList);
            // setCurrentImages(imgList);
        }
    }, [bannerContent, bannerContent?.images])

    useEffect(() => {
        if (bannerContent)
            form.setFieldsValue({
                title: title,
                content: editorValue,
                // image: bannerContent?.images
            });
    }, [bannerContent]);

    const getEditorContent = (content: any) => {
        setEditorValue(content)
    }
    
    const handleCancel = (): void => setPreviewOpen(false);

    const handlePreview = async ({ name, url, preview, originFileObj }: UploadFile) => {
        if (!url && !preview) {
            preview = await getBase64(originFileObj as RcFile);
        }

        setPreviewImage(url || (preview as string));
        setPreviewOpen(true);
        setPreviewTitle(name || url!.substring(url!.lastIndexOf('/') + 1));
    };

    const props: UploadProps = {
        name: 'file',
        multiple: true,
        accept: "image/*",
        headers: {
            authorization: 'authorization-text',
        },
        beforeUpload: () => false,
        listType: "picture-card",
        async onChange({ fileList, file }) {
            const { status, name } = file
            setCurrentImages(Array.from(fileList) as []);
            setFileList(Array.from(fileList))

            if (status === 'done') {
                message.success(`${name} file uploaded successfully`);
            } else if (status === 'error') {
                message.error(`${name} file upload failed.`);
            }
        },
        fileList,
        showUploadList: {
            showDownloadIcon: true,
            downloadIcon: 'Download',
            showRemoveIcon: true,
            removeIcon: <CloseOutlined />,
        },
    };

    const successMessage = (): void => {
        messageApi.open({
            type: 'success',
            content: 'Success',
            duration: 1
        });
    }

    const onFinish = ({ title }: any): void => {

        const data = {
            title,
            content: editorValue,
            language: bannerlngID,
            images: currentImages,
            id: bannerContent.id ? bannerContent.id : void 0
        }       
    
        bannerContent?.id ? dispatch(updateBannerThunk(data)) : dispatch(createBannerThunk(data))
        
    }

    const findErrorByField = (field: ErrorFields): JSX.Element[] | undefined => {
        if (errors?.length) {
            const findedErrors = errors?.filter((err: any) => err?.field === field)
            const errorMessages = findedErrors?.map((err: any) => {
                return (
                    <p
                        key={err?.id}
                        className={style.errorMessage}
                    >
                        {t(`ERRORS.${err?.message?.toUpperCase()}`)}
                    </p>
                )
            })
            return errorMessages
        }
    }


    return (
        <>
            {contextHolder}
            <div className="bgColor">
                <div className={style.lng}>
                    <span className={style.currentLanguage}>
                        {currentLangLabel ? currentLangLabel : lang}
                    </span>
                    <Language getCurrentLanguage={getLanguage} banner />
                </div>

                <Form
                    name="basic"
                    className={style.bannerForm}
                    form={form}
                    onFinish={onFinish}
                >
                    <div className={style.settingsHomeTab}>
                        <Form.Item
                            label="Banner Title"
                            name="title"
                            rules={[{ required: true, message: 'Please input banner title!' }]}
                            help={findErrorByField(ErrorFields.TITLE)}
                            className={style.label}
                        >
                            {loading
                                ? <Skeleton.Input active className={style.titleSkeleton} />
                                : <Input className={style.metaTitle} />}
                        </Form.Item>
                    </div>
                    <div className={style.settingsHomeTab}>
                        <Form.Item
                            label="Banner Content"
                            name="content"
                            help={findErrorByField(ErrorFields.CONTENT)}
                            className={style.label}
                        >
                            {loading
                                ? <Skeleton.Input active className={style.contentSkeleton} />
                                : <CustomEditor
                                    content={editorValue}
                                    getEditorContent={getEditorContent}
                                    count={countLines(editorValue)}
                                />}
                        </Form.Item>
                    </div>
                    <div className={style.settingsHomeTab}>
                        <Form.Item
                            label="Photos"
                            name="image"
                            style={{ marginBottom: 0 }}
                            className={style.label}
                        >
                            {loading ?
                                <div className={style.imagesWrapper}>
                                    <Skeleton.Image active className={style.imageSkeleton} />
                                    <Skeleton.Image active className={style.imageSkeleton} />
                                    <Skeleton.Image active className={style.imageSkeleton} />
                                </div>
                                : <Upload
                                    {...props}
                                    onPreview={handlePreview}
                                    onRemove={({ id }: any) => {
                                        const unsavedImage = bannerContent?.images?.find((img: any) => img.id === id)
                                        if (unsavedImage) {
                                            dispatch(deleteBannerImageThunk({ id, bID: bannerContent?.id }))
                                        }
                                    }}
                                >
                                    <div>
                                        <PlusOutlined />
                                        <div>
                                            {t("MAIN.UPLOAD")}
                                        </div>
                                    </div>
                                </Upload>}
                            <CustomModal
                                place={Place.BANNER}
                                open={previewOpen}
                                cancel={handleCancel}
                                title={previewTitle}
                            >
                                <img
                                    className={style.previewImage}
                                    src={previewImage}
                                    alt="Banner image"
                                />
                            </CustomModal>
                        </Form.Item>
                    </div>
                    <div className={style.updateMetaButtonRow}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className={style.updateMeta}
                            disabled={loading}
                        >
                            {t("MAIN.UPDATE")}
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    )
}
import { Col, Row, Space } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  getIsSectionDeleted,
  getIsSectionUpdated,
  getSectionLoading,
  getSectionOneList,
  getSectionsForOneLanguage,
  getSections,
  getArticleList,
  getArticlesByCategory
} from "@store/index";
import {
  ContentCardSkeleton,
  Language, CustomButton,
  languages,
  editArticleLanguages,
  CardSection
} from "@components/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { DEFAULT_LANGAUGE_LABEL } from "@utils/index";
import { changeAcceptLanguage } from "@services/index";

import style from "./content.module.css";

export const ContentManagement = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [currentLanguageLabel, setCurrentLanguageLabel] = useState<string>("English");
  const [sectionLng, setSectionLng] = useState<string>("en");
  const [sectionLngID, setSectionLngID] = useState<number>(1);

  const sections = useAppSelector<any>(getSectionOneList);
  const isSectionUpdated = useAppSelector<boolean>(getIsSectionUpdated)
  const loading = useAppSelector<boolean>(getSectionLoading)
  const articleList = useAppSelector(getArticleList);
  const isSectionDeleted = useAppSelector<boolean>(getIsSectionDeleted);

  useEffect(() => {
    dispatch(getSectionsForOneLanguage());
    dispatch(getSections());
  }, [])

  useEffect(() => {
    if (isSectionUpdated || isSectionDeleted) {
      dispatch(getSectionsForOneLanguage());
    }
    dispatch(getSections());
  }, [isSectionUpdated, isSectionDeleted, dispatch])

  useEffect(() => {
    changeAcceptLanguage(sectionLng);
    const obj: any = editArticleLanguages?.find((o: any) => o.key === sectionLng);
    setCurrentLanguageLabel(obj?.label);
    const lngId: any = languages?.find((o: any) => { if (o.label === currentLanguageLabel) return o.key });
    setSectionLngID(lngId.key);
  }, []);


  useEffect(() => {
    changeAcceptLanguage(sectionLng);
    const obj: any = editArticleLanguages?.find((o: any) => o.key === sectionLng);
    setCurrentLanguageLabel(obj?.label);
    const lngId: any = languages?.find((o: any) => { if (o.label === currentLanguageLabel) return o.key });
    setSectionLngID(lngId.key);
    dispatch(getSectionsForOneLanguage());
  }, [sectionLng, sectionLngID]);

  useEffect(() => {
    const data = {};
    dispatch(getArticlesByCategory(data));
  }, [sections, sectionLngID, sectionLng, isSectionDeleted, dispatch])

  const getArticlesbyCategory = (catId: number) => {
    return articleList?.data?.filter((item: any) => item.category.id === catId)
  }

  const getLanguage = (lng: any): void => {
    changeAcceptLanguage(lng)
    setSectionLng(lng)
  }

  return (
    <>
      <Space size="middle" className={style.right}>
        <div className={style.languageMenuWrapper}>
          <span className={style.currentLanguage}>
            {currentLanguageLabel
              ? currentLanguageLabel
              : DEFAULT_LANGAUGE_LABEL}
          </span>
          <Language getCurrentLanguage={getLanguage} banner />
        </div>
        <div>
          <CustomButton
            onClick={() => navigate('/section')}
            name={`+${t("MAIN.ADD_SECTION")}`}
          />
          <CustomButton
            onClick={() => navigate('/content-management/article')}
            name={`+${t("MAIN.ADD_ARTICLE")} `}
          />
        </div>
      </Space>
      {loading ?
        <ContentCardSkeleton />
        :
        <div>
          <Row style={{ marginLeft: 30, marginBottom: 40 }}>
            {
              sections?.map((item: any) => {
                return (
                  <Col key={item.id} span={10} className={style.sectionCol} >
                    <CardSection
                      sectionKey={item.key}
                      title={item.categoryTranslations[0].name}
                      id={item.id}
                      articles={getArticlesbyCategory(item.id)}
                    />
                  </Col>
                )
              })}
          </Row>
        </div>
      }
    </>
  )
}
import { Routes, Route } from "react-router-dom";

import {
  ContentManagement,
  Article,
  SectionPage,
  NotFound,
  Settings,
  Reports,
  UserManagement,
  Dashboard
} from "@pages/index";
import EditUserTabs from "@pages/user-management/components/edit-user-tabs";
import ReportsDetails from "@pages/reports/components/reports-detaild";
import { Profession } from "@pages/profession/components/profession";
import User from "@pages/user-management/components/user";
import { AddSection } from "@components/index";
import Statistics from "@pages/statistics";

export function MainRouter(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}>
        <Route index element={<Statistics />} />

        <Route path="user-management">
          <Route index element={<UserManagement />} />
          <Route path="user" element={<User />} />
          <Route path="user/:id" element={<EditUserTabs />} />
        </Route>

        <Route path="content-management" >
          <Route index element={<ContentManagement />} />
          <Route path=":category" element={<SectionPage />} />
          <Route path="article" element={<Article />} />
          <Route path="article/:id" element={<Article />} />
        </Route>

        <Route path="statistics" element={<Statistics />} />
        <Route path="settings/:index" element={<Settings />} />

        <Route path="reports">
          <Route index element={<Reports />} />
          <Route path=":id" element={<ReportsDetails />} />
        </Route>

        <Route path="profession/profession/:id" element={<Profession />} />
        <Route path="section" element={<AddSection />} />
      </Route>
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}
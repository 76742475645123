import { Button } from "antd";

import style from "./custom-buttons.module.css";

let pos = "";

interface IProps {
  onClick?: React.MouseEventHandler<HTMLElement>,
  name: string,
  position?: string,
  icon?: any,
  style?: string
}

export const CustomButton = ({ onClick, name, position, icon }: IProps): JSX.Element => {

  if (position) {
    pos = style[position];
  } else {
    pos = "";
  }

  return (
    <Button
      type="primary"
      disabled={false}
      className={`${style.button} ${pos}`}
      onClick={onClick}
      style={style}
    >
      {icon} {name}
    </Button>
  );
}
export enum ActionTypes {
    CONTENT = "description",
    TITLE = "title",
    DATE = "date",
    PHOTO = "photo",
    CATEGORYID = "categoryID",
    TIME = "time"
}

export enum ErrorMessage {
    TITLE,
    CONTENT,
    FILE,
    SECTION
}

export enum TitleOrContent {
    TITLE,
    CONTENT
}

export enum ChooseDateOrTime {
    DATE,
    TIME
}

export enum AntDataType {
    TITLE,
    CONTENT,
    IMAGE,
    SECTION,
    DATE
}

export enum LocationOfUse {
    ARTICLE,
    SECTION
}
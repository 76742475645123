import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IPartnerResponseData } from "@models/index";
import { createPartner, deletePartner, getOnePartner, getPartners, updatePartner } from "@services/partners.service";

const initialState: IPartnerResponseData = {
    message: "",
    statusCode: 0,
    statusName: "",
    success: false,
    isPartnerCreated: false,
    isPartnerUpdated: false,
    isPartnersDeleted: false,
    loading: false,
    about: "",
    address: "",
    copyright: "",
    description: "",
    favicon: {},
    keyword: "",
    logo: {},
    title: "",
    data: [],
    partnerData: [],
    errors: [],
    partnerCardLoading: false
};

export const createPartnerThunk = createAsyncThunk(
    "partner/createPartner",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await createPartner(data);
            return Promise.resolve(response);
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const getAllPartnersThunk = createAsyncThunk(
    "partner/getPartners",
    async (_, { rejectWithValue }) => {
        try {
            const response = await getPartners();
            return Promise.resolve(response.data);
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const deletePartnersThunk = createAsyncThunk(
    "partner/deletePartner",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await deletePartner(data);

            return Promise.resolve(response);
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const getOnePartnerThunk = createAsyncThunk(
    "partner/getOnePartners",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await getOnePartner(data);
            return Promise.resolve(response.data);
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updatePartnerThunk = createAsyncThunk(
    "update/updatePartner",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await updatePartner(data);
            return Promise.resolve(response);
        } catch (error: any) {

            return rejectWithValue(error.response.data);
        }
    }
);

export const PartnerSlice = createSlice({
    name: "partner",
    initialState,
    reducers: {
        resetPartnerErrors: (state) => {
            state.errors = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createPartnerThunk.fulfilled, (state: IPartnerResponseData, { payload }: PayloadAction<any>) => {
            state.isPartnerCreated = true;
            state.statusCode = payload.statusCode;
            state.statusName = payload.statusName;
            state.message = "";
            state.errors = [];
        });
        builder.addCase(createPartnerThunk.rejected, (state: IPartnerResponseData, { payload }: PayloadAction<any>) => {
            state.loading = false;
            state.errors = payload.errors;
            state.isPartnerCreated = false;
            state.message = payload.message
        });

        builder.addCase(getAllPartnersThunk.pending, (state: IPartnerResponseData) => {
            state.loading = true;
        });
        builder.addCase(getAllPartnersThunk.fulfilled, (state: IPartnerResponseData, { payload }: PayloadAction<any>) => {
            state.loading = false;
            state.success = true;
            state.partnerData = payload;
            state.loading = false;
            state.isPartnerCreated = false;
            state.isPartnerUpdated = false;
            state.errors = [];
            state.message = "";
        });
        builder.addCase(getAllPartnersThunk.rejected, (state: IPartnerResponseData, { payload }: PayloadAction<any>) => {
            state.success = false;
            state.loading = false;
            state.isPartnerCreated = false;
            state.isPartnerUpdated = false;
            state.errors = payload.errors;
            state.message = payload.message;
        });


        builder.addCase(deletePartnersThunk.pending, (state: IPartnerResponseData) => {
            state.loading = true;
            state.isPartnersDeleted = false;
        });
        builder.addCase(deletePartnersThunk.fulfilled, (state: IPartnerResponseData, { payload }: PayloadAction<any>) => {
            state.loading = false;
            state.isPartnerCreated = false;
            state.isPartnersDeleted = true;
            state.statusCode = payload.statusCode;
            state.statusName = payload.statusName;
            state.errors = [];
            state.message = "";
        });

        builder.addCase(deletePartnersThunk.rejected, (state: IPartnerResponseData, { payload }: PayloadAction<any>) => {
            state.loading = false;
            state.errors = payload.errors;
            state.message = payload?.message;
            state.isPartnersDeleted = false;
            state.isPartnerCreated = false;
        });


        builder.addCase(getOnePartnerThunk.fulfilled, (state: IPartnerResponseData, { payload }: PayloadAction<any>) => {
            state.loading = false;
            state.partnerCardLoading = false
            state.isPartnerCreated = false;
            state.isPartnersDeleted = false;
            state.data = payload;
            state.statusCode = payload.statusCode;
            state.statusName = payload.statusName;
            state.errors = [];
            state.message = "";
        });
        builder.addCase(getOnePartnerThunk.rejected, (state: IPartnerResponseData, { payload }: PayloadAction<any>) => {
            state.loading = false;
            state.partnerCardLoading = false
            state.errors = payload.errors;
            state.message = payload?.message;
            state.isPartnersDeleted = false;
        });

        builder.addCase(getOnePartnerThunk.pending, (state: IPartnerResponseData) => {
            state.data = [];
            state.partnerCardLoading = true
        });

        builder.addCase(updatePartnerThunk.fulfilled, (state: IPartnerResponseData, { payload }: PayloadAction<any>) => {
            state.loading = false;
            state.isPartnerCreated = false;
            state.isPartnerUpdated = true;
            state.isPartnersDeleted = false;
            state.data = payload;
            state.statusCode = payload.statusCode;
            state.statusName = payload.statusName;
            state.errors = [];
            state.message = "";
        });
        builder.addCase(updatePartnerThunk.rejected, (state: IPartnerResponseData, { payload }: PayloadAction<any>) => {
            state.loading = false;
            state.isPartnerUpdated = false;
            state.isPartnersDeleted = false;
            state.errors = payload.errors;
            state.message = payload.message;
        });
    },
});

export const { resetPartnerErrors } = PartnerSlice.actions

export const getOnePartnerInfo = (state: any) => {
    return state.partner.data;
}
export const getIsPartnersCreated = (state: any) => {
    return state.partner.isPartnerCreated;
}
export const getIsPartnersUpdated = (state: any) => {
    return state.partner.isPartnerUpdated;
}
export const getIsPartnersDeleted = (state: any) => {
    return state.partner.isPartnersDeleted;
}
export const getPartnersData = (state: any) => {
    return state.partner.partnerData;
}
export const getPartnersErrors = (state: any) => {
    return state.partner.errors;
}
export const getPartnersErrorMessage = (state: any) => {
    return state.partner.message;
}
export const getPartnersLoading = (state: any) => {
    return state.partner.loading;
}
export const getPartnerCardLoading = (state: any) => {
    return state.partner.partnerCardLoading
}
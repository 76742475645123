import { Form, Radio, Skeleton } from 'antd'

import style from './article.module.css'

export const EditArticleSkeleton = (): JSX.Element => {
    return (
        <div>
            <Form style={{ maxWidth: 1099 }}>
                <div>
                    <div className="formItemWrapper">
                        <Form.Item
                            label="News Title"
                            className="formItem"
                        >
                            <Skeleton.Input
                                active
                                className={style.field}
                            />
                        </Form.Item>
                    </div>
                    <div className="formItemWrapper">
                        <Form.Item
                            label="News Content"
                            className="formItem"
                        >
                            <Skeleton.Input
                                active
                                className={style.editor}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className="formItemWrapper">
                    <Form.Item
                        label="Published"
                        className="formItem"
                    >
                        <Radio.Group>
                            <Skeleton.Avatar
                                active
                                className={style.radio}
                                size={'small'}
                            />
                            <Skeleton.Input
                                active
                                className={style.radioLabel}
                            />
                            <Skeleton.Avatar
                                active
                                className={style.radio}
                                size={'small'}
                            />
                            <Skeleton.Input
                                active
                                className={style.radioLabel}
                            />
                        </Radio.Group>
                    </Form.Item>
                </div>
                <div className="formItemWrapper">
                    <Form.Item
                        label="Change Features Photos"
                        className="formItem"
                    >
                        <Skeleton.Input
                            active
                            className={style.photo}
                        />
                    </Form.Item>
                </div>
                <div className='formItemWrapper'>
                    <Form.Item
                        label="Section"
                        className="formItem"
                    >
                        <Skeleton.Input
                            active
                            className={style.section}
                        />
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}
import { Card } from "antd";

import { BASE_URL, getCookie } from "@utils/index";
import { deletePartnersThunk } from "@store/index";
import { useAppDispatch } from "@hooks/index";
import { IImage } from "@models/index";

import { socialPartnerDelete, socialPartnerEdit, checked, reply } from "@assets/index";

import styles from "../partner.module.css";

interface IProps {
    link: string,
    image: IImage,
    title: string,
    id: number,
    isPartnerEditClicked: any
}
export const ShowPartners = ({ link, image, title, id, isPartnerEditClicked }: IProps): JSX.Element => {
    const token = getCookie('accessToken');
    const dispatch = useAppDispatch();

    return (
        <div className="noRadius">
            <Card className={styles.partnerCard}>
                <img
                    alt={image?.name}
                    src={`${BASE_URL}${image?.path}?authorization=${token}`}
                    style={{ width: 100, height: 100 }}
                />
            </Card>
            <div className={`${styles.actionsText} ${styles.showSocials}`}>
                <div style={{ paddingLeft: 10, wordBreak: "break-all" }}>
                    <img src={checked} className="pr10" alt={title} />
                    {title}
                </div>
                <div>
                    <a href={link} target="_blank">
                        <img src={reply} className="pr10" alt="Reply icon" />
                    </a>
                    <img src={socialPartnerEdit} className="pr10" onClick={() => isPartnerEditClicked({ isEdit: true, editedId: id })} alt="Edit icon" />
                    <img src={socialPartnerDelete} className="pr10" onClick={() => dispatch(deletePartnersThunk(id))} alt="Delete icon" />
                </div>
            </div>
        </div>
    );
}

import moment from 'moment';
import { useEffect } from 'react';
import { DatePicker, Skeleton } from 'antd';
import 'react-circular-progressbar/dist/styles.css';

import { getAllStatistics, getStatisticsThunk, getStatisticsLoading } from '@store/index';
import { VerticalChart, StaffOnlineChart, UserStatisticsChart } from './components'
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { IStatisticsData } from '@models/index';

import style from './components/statistics-charts.module.css'

const StatisticsCharts = (): JSX.Element => {

  const dispatch = useAppDispatch();
  const { RangePicker } = DatePicker;

  const statistics = useAppSelector<IStatisticsData>(getAllStatistics);
  const loading = useAppSelector<boolean>(getStatisticsLoading)

  useEffect(() => {
    const dateStrings = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    dispatch(getStatisticsThunk(dateStrings));
  }, [dispatch])

  const onChange = (dates: any, dateStrings: any): void => {
    if (dates) {
      dispatch(getStatisticsThunk(dateStrings));
    }
  };

  return (
    <>
      <RangePicker
        className={style.statisticsDate}
        format="YYYY-MM-DD"
        onChange={onChange}
      />
      {loading
        ? <Skeleton.Input active className={style.statisticsSkeletonFirstLine} />
        : <VerticalChart statistics={statistics} />
      }

      {loading
        ? <Skeleton.Input active className={style.statisticsSkeletonSecondLine} />
        : <div className={style.statisticsSection}>
          <StaffOnlineChart statistics={statistics} />
          <UserStatisticsChart statistics={statistics} />
        </div>
      }
    </>
  )
}
export default StatisticsCharts;
import { objectToFormData } from "@utils/index";
import { api } from ".";

/*API calls for  Partner */
export const createPartner = (data: any) => {
    const { values, currentImage } = data
    const formData = objectToFormData(
        {
            "image": currentImage,
            "name": values.name
        });
    return api.post('partners', formData);
}
export const getPartners = () => {
    return api.get('/partners');
}
export const getOnePartner = (id: any) => {
    return api.get(`/partners/${id}`);
}
export const updatePartner = (data: any) => {
    const { values, id, currentImage } = data
    const formData = objectToFormData(
        {
            "image": currentImage,
            "name": values.name
        });
    return api.put(`/partners/${id}`, formData);
}
export const deletePartner = (id: any) => {
    return api.delete(`/partners/${id}`);
}
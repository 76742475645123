import { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { Helmet } from "react-helmet";

import { getFaviconInfo, getMetaContent, getSettingsThunk } from './store';
import { useAppDispatch, useAppSelector } from './hooks';
import defaultFavicon from "@assets/default.ico";
import Routing from './routes';
import './App.css';
import './i18n';
import { BASE_URL } from './utils';

function App() {

  const title = useAppSelector(getMetaContent)
  const favicon: any = useAppSelector(getFaviconInfo);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSettingsThunk());
  }, [])

  useEffect(() => {
    const link = document.querySelector('link[rel="icon"]');

    favicon?.favicon?.path
      ? link?.setAttribute('href', `${BASE_URL}${favicon?.favicon?.path}`)
      : link?.setAttribute('href', defaultFavicon);

  }, [favicon]);

  const locals = {
    components: {
      Button: {
        colorPrimary: '#3DA2C2',
        colorPrimaryActive: "#3DA2C2",
        colorPrimaryHover: "#3DA2C2",
      },
      Input: {
        colorPrimary: '#3DA2C2',
        colorPrimaryHover: "#3DA2C2"
      },
      Checkbox: {
        colorPrimary: "#fff",
        colorPrimaryBorder: "none",
      },
      DatePicker: {
        colorPrimary: "#3DA2C2",
        colorPrimaryHover: "#3DA2C2"
      },
      Select: {
        colorPrimary: "#3DA2C2",
        colorPrimaryHover: "#3DA2C2"
      },
      Radio: {
        colorPrimary: "#3DA2C2",
        colorPrimaryHover: "#3DA2C2"
      },
      Tabs: {
        colorPrimary: "#3DA2C2",
        colorPrimaryHover: "#3DA2C2"
      },
      Pagination: {
        colorPrimary: "#3DA2C2",
        colorPrimaryHover: "#3DA2C2"
      },
      Switch: {
        colorPrimary: "#3DA2C2",
        colorPrimaryHover: "#3DA2C2"
      }
    }
  };

  return (
    <ConfigProvider theme={locals}>
      <div className="App">
        <Helmet>
          <title>{title.adminPanelTitle}</title>
        </Helmet>
        <Routing />
      </div>
    </ConfigProvider>
  );
}

export default App;
import moment from "moment";

import { PAGE_LIMIT } from "@utils/index";
import { api } from ".";
import { IReceivedUpdateData } from "@models/index";

export const getArticlesByCategoryRoute = (categoryData?: any) => {

    const { category, page } = categoryData

    if (Number(category) > 0)
        return api.get(`articles`, {
            params: {
                category: Number(category),
                page,
                take: PAGE_LIMIT
            }
        });
    else {
        return api.get(`articles`);
    }
}

export const deleteArticle = (articleId: any) => {
    return api.delete(`articles/${articleId}`);
}

export const getSingleArticleRouth = (articleId: string) => {
    return api.get(`articles/${articleId}`);
}

export const updateSingleArticle = ({ id, articleData, receivedDate }: IReceivedUpdateData) => {

    const formData = new FormData();

    const { categoryId, date, image, time, content } = articleData

    if (date || time || receivedDate) {
        const receivedDataConversion = moment(receivedDate).format()
        const defaultDate = moment(receivedDate).format('YYYY-MM-DD')
        const defaultTime = moment(receivedDate).format('HH:mm:ss')

        if (date && time) {
            formData.append('date', moment(`${date} ${time}`, "YYYY-MM-DD HH:mm:ss").toISOString())
        } else if (date && !time) {
            formData.append('date', moment(`${date} ${defaultTime}`, "YYYY-MM-DD HH:mm:ss").toISOString())
        } else if (!date && time) {
            formData.append('date', moment(`${defaultDate} ${time}`, "YYYY-MM-DD HH:mm:ss").toISOString())
        } else {
            formData.append('date', receivedDataConversion)
        }
    }

    if (image) {
        formData.append('image', image)
    }

    if (categoryId) {
        formData.append('categoryId', categoryId)
    }

    content.forEach((item: any, index: any) => {
        const { title, description, languageId } = item

        if (title && description) {
            formData.append(`content[${index}][title]`, title)
            formData.append(`content[${index}][description]`, description)
            formData.append(`content[${index}][languageId]`, languageId)
        } else if (title && !description) {
            formData.append(`content[${index}][title]`, title)
            formData.append(`content[${index}][languageId]`, languageId)
        } else if (!title && description) {
            formData.append(`content[${index}][description]`, description)
            formData.append(`content[${index}][languageId]`, languageId)
        }
    })

    return api.put(`articles/${id}`, formData);
}


export const createArticleRoute = (data: any) => {
    const formData = new FormData();
    const { image, categoryId, date, time, content } = data

    formData.append('categoryId', categoryId)
    formData.append('image', image)

    if (date && time) {
        const validDate: string = new Date(`${date} ${time}`).toISOString()
        formData.append('date', validDate)
    }

    content.forEach((item: any, index: any) => {
        formData.append(`content[${index}][title]`, item.title)
        formData.append(`content[${index}][description]`, item.description)
        formData.append(`content[${index}][languageId]`, item.languageId)
    })

    return api.post('articles', formData);
}
import { useTranslation } from 'react-i18next';
import { CircularProgressbar } from 'react-circular-progressbar';

import { IStatisticsData } from '@models/index';

import style from './statistics-charts.module.css';

interface IProps {
  statistics: IStatisticsData,
}

export const UserStatisticsChart = ({ statistics }: IProps): JSX.Element => {

  const { t } = useTranslation();

  return (
    <div className={style.userStatistics}>
      <h4>
        {t("MAIN.USERS_STATISTICS")}
      </h4>
      <div style={{ position: "relative", left: 5 }} >
        <div className={style.container}>
          <CircularProgressbar
            value={statistics.statistics?.authUsersPercent}
            styles={{
              root: {},
              path: {
                stroke: 'rgba(128, 194, 221, 1)',
                strokeWidth: "5",
                transition: 'stroke-dashoffset 0.5s ease 0s',
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
              },
              trail: {
                stroke: '#E9EBED',
                strokeWidth: "5",
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
              },
              text: {
                fill: '#f88',
                fontSize: '16px',
              },
              background: {
                fill: '#3e98c7',
              },
            }} />
          <div className={style.authUserPercent} >
            {Math.round(statistics.statistics?.authUsersPercent)}%
          </div>
        </div>
        <div className={style.container1}>
          <CircularProgressbar
            value={statistics.statistics?.unauthorizedUsersPercent}
            styles={{
              root: {},
              path: {
                stroke: 'rgba(22, 70, 90, 1)',
                strokeWidth: "7",
                transition: 'stroke-dashoffset 0.5s ease 0s',
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
              },
              trail: {
                stroke: '#E9EBED',
                strokeWidth: "7",
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
              },
              text: {
                fill: '#f88',
                fontSize: '16px',
              },
              background: {
                fill: '#3e98c7',
              },
            }} />
        </div>
        <div className={style.unauthUserPercent}>
          {Math.round(statistics.statistics?.unauthorizedUsersPercent)}%
        </div>
      </div>
      <div className={`${style.statusSection} ${style.statusSectionUsers}`}>
        <div>
          <div className={`${style.onlineText} ${style.onlineTextColor}`} />
          <span>
            {t('MAIN.LOGIN_USERS')}
          </span>
        </div>
        <div>
          <div className={`${style.offlineText} ${style.offlineTextColor}`} />
          <span>
            {t('MAIN.UNAUTHORIZED_USERS')}
          </span>
        </div>
      </div>
    </div>
  )
}
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { DatePicker, Pagination, PaginationProps, Select, Space } from "antd";

import {
    deleteReportThunk,
    downloadReportByUserThunk,
    getAllReports,
    getPdfPath,
    getReportDeleted,
    getReportsThunk,
    isPDFPath,
    getUserThunk,
    getUserList,
    getUserListForTaskReport
} from "@store/index";
import { firstLetterBox, shortenTitle, BASE_URL, PAGE_LIMIT } from "@utils/index";
import { DeleteBlackIcon, TaskDoneIcon, SearchFilter } from "@components/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { IReports, ReportPlace } from "@models/index";
import { NotFound } from "@pages/index";

import { ReactComponent as DownloadReportIcon } from "@assets/downloadreport.svg";
import { ReactComponent as ContentManagement } from "@assets/content.svg";
import { ReactComponent as DownloadIcon } from "@assets/download.svg";
import { ReactComponent as DetailsIcon } from "@assets/details.svg";
import { ReactComponent as TaskFaildIcon } from "@assets/faild.svg";
import { ReactComponent as ClockIcon } from "@assets/clock.svg";
import { ReactComponent as InfoIcon } from "@assets/info.svg";

import style from "../reports.module.css";
import InfiniteScrollSelect, { DebounceSelect } from "./infinity-scroll-select";
import InfinitiScrollSelect from "./infinity-scroll-select";

interface IProps {
    userId?: any;
    place?: ReportPlace
    hasFilter: boolean;
}

const ReportCard = ({ userId, hasFilter, place }: IProps): JSX.Element => {

    const { RangePicker } = DatePicker;
    const { t } = useTranslation();
    const { Option } = Select;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const ref = useRef<HTMLSelectElement | any>(null)

    const [openDetailsIndex, setOpenDetailsndex] = useState<number | null>(null);
    const [searchDate, setSearchDate] = useState<any>();
    const [openIndex, setOpenIndex] = useState<number | null>(null);
    const [hovered, setHovered] = useState<boolean>(false);
    const [searchData, setSearchData] = useState<string>("");
    const [users, setUsersID] = useState<any>([userId?.id]);
    const [current, setCurrent] = useState<number>(1);

    const [isLoading, setIsLoading] = useState(false);

    const isReportDeleted = useAppSelector<boolean>(getReportDeleted);
    const reports = useAppSelector<IReports>(getAllReports);
    const usersList = useAppSelector(getUserListForTaskReport);
    const pdfPath = useAppSelector(getPdfPath);
    const isPdfPath = useAppSelector(isPDFPath);

    const onPopupScroll = () => {
        if (ref.current) {
            const { scroll, scrollTop, clientHeight, scrollHeight } = ref.current;
            if (scrollHeight > 256 && usersList.meta.hasNextPage) {
                dispatch(
                    getUserThunk({
                        searchData,
                        take: PAGE_LIMIT,
                        page: usersList.meta.page !== 0 ? usersList.meta.page + 1 : 1,
                        order: 'ASC',
                        searchDate: searchDate ? searchData : void 0
                    })
                );
            }
        }
    }

    useEffect(() => {
        dispatch(getUserThunk({
            searchData,
            take: PAGE_LIMIT,
            page: 1,
            order: 'ASC'
        }));

        if (hasFilter) {
            const data = {
                users: +users,
                search: searchData && searchData
            }
            dispatch(downloadReportByUserThunk(data))
        }
    }, [dispatch]);

    useEffect(() => {
        if ((searchData && searchData.length > 3) || current || users || isReportDeleted) {
            const data = {
                search: searchData,
                take: 12,
                page: current,
                users
            }
            dispatch(getReportsThunk(data));
        }
    }, [searchData, current, users, isReportDeleted]);

    const options = usersList?.data?.map((item: any) => {
        return {
            value: item?.id,
            label: `${item?.firstName} ${item?.lastName}`
        }
    }, [usersList]);


    useEffect(() => {
        if (searchDate) {
            const data = {
                search: searchData,
                from: searchDate[0],
                to: searchDate[1],
                take: 12,
                users: users ? users : void 0,
                page: current
            }
            dispatch(downloadReportByUserThunk(data))
            dispatch(getReportsThunk(data));
        }
    }, [searchDate]);

    useEffect(() => {
        if (isReportDeleted) {
            dispatch(getReportsThunk(searchData));
        }
    }, [isReportDeleted]);

    const deleteReport = (id: number) => {
        dispatch(deleteReportThunk(id));
        toggleDetails(0)
    }

    const onSearchChange = (val: any) => {

        setSearchData(val);
        const data = {
            search: val,
            users: users && +users,
        }
        dispatch(getReportsThunk({
            searchData: val,
            take: PAGE_LIMIT,
            page: current
        }))
    }

    const onChange = (dates: any, dateStrings: [string, string]): void => {
        setSearchDate(dateStrings);
        if (!dates) {
            dispatch(getReportsThunk(searchData));
            const data = {
                search: searchData,
                from: dateStrings[0],
                to: dateStrings[1],
                users: users ? users : void 0,
            }
            let timeout;
            clearTimeout(timeout)
            timeout = setTimeout(() => dispatch(downloadReportByUserThunk(data)), 450)
        }
    };

    const handleHover = (index: number): void => {
        setOpenIndex(index === openIndex ? null : index);
        setHovered(!hovered);
    }

    const toggleDetails = (key: number): void => {
        setOpenDetailsndex(key === openDetailsIndex ? null : key);
    };

    const onPageChange: PaginationProps['onChange'] = (page: number): void => setCurrent(page);

    const handleChange = (value: string): void => setUsersID(value);

    const selectProps = {
        onChange: handleChange,
    };
    const sharedProps: any = {
        mode: "multiple",
        style: {
            width: '100%',
        },
        options,
        placeholder: 'Select Item...',
        maxTagCount: 'responsive',
    };
    const customPopupContainer = (triggerNode: HTMLElement) => triggerNode.parentNode;
    return (
        <>
            <div className={style.reportContainer}>
                <div
                    className={style.search}
                    style={{ marginLeft: 10 }}
                >
                    <SearchFilter onSearchChange={onSearchChange} />
                </div>
                <RangePicker
                    className={style.statisticsDate}
                    format="YYYY-MM-DD"
                    onChange={onChange}
                />
                {!hasFilter && <Space direction="vertical" style={{ width: 400, marginLeft: 10 }} ref={ref}>

                    <Select
                        {...sharedProps}
                        {...selectProps}
                        getPopupContainer={customPopupContainer}
                        onPopupScroll={onPopupScroll}
                    />

                </Space>
                }
                {place === ReportPlace.EDIT_USER &&
                    <Link
                        to={`${BASE_URL}${pdfPath.path}`}
                        target="_blank"
                        className={style.downloadpdf}
                        aria-current={!isPdfPath ? "page" : false}>
                        <span>{t("MAIN.DOWNLOAD")}</span>
                        <span className={style.downloadIcon}>
                            <DownloadReportIcon />
                        </span>
                    </Link>
                }
            </div>
            <div className={style.reportsBg}>
                {!reports?.data?.length
                    ?
                    <div className={style.reportsBg}>
                        <NotFound />
                    </div>
                    :
                    reports?.data?.map((item: any, index: number) => {
                        return (
                            <div key={index} className={style.nestedContent} onMouseEnter={() => handleHover(index)} onMouseLeave={() => handleHover(index)}>
                                <div className={style.accordionItem}>
                                    {index === openIndex && <div className={style.topLine} />}
                                    <div className={style.accordionContent}>
                                        <div className={style.accordionContent} >
                                            <div>
                                                <h3>
                                                    <ContentManagement
                                                        onClick={() => navigate(`/reports/${item?.id}`)}
                                                    />
                                                    <span
                                                        className={style.reportTitle}
                                                        onClick={() => navigate(`/reports/${item?.id}`)}
                                                    >
                                                        {shortenTitle(item?.title, 20)}
                                                    </span>
                                                    <div className={style.tooltip}>
                                                        {item?.title}
                                                    </div>
                                                    <DetailsIcon
                                                        className={style.detailsIcon}
                                                        onClick={() => toggleDetails(index)}
                                                    />
                                                    {index === openDetailsIndex &&
                                                        <div className={style.details}>
                                                            <div onClick={() => deleteReport(item.id)}>
                                                                <DeleteBlackIcon />
                                                                <span>
                                                                    {t("MAIN.DELETE")}
                                                                </span>
                                                            </div>
                                                            <div onClick={() => navigate(`/reports/${item.id}`)}>
                                                                <InfoIcon />
                                                                <span >
                                                                    {t("MAIN.INFO")}
                                                                </span>
                                                            </div>
                                                        </div>}
                                                </h3>
                                                <div className="pb10">
                                                    <h3 className={`${style.taskIcon} ${style[item.type == "success" ? "taskDone" : "taskFaild"]}`}>
                                                        {item.type == "success" ? <TaskDoneIcon /> : <TaskFaildIcon />}
                                                        <span>
                                                            {t(`REPORTS.${item.type == "success" ? "DONE" : "FAILD"}`)}
                                                        </span>
                                                    </h3>
                                                    <span>
                                                        <ClockIcon />
                                                        <span className={style.date}>
                                                            {moment(item.date).format('DD/MM/YYYY')}
                                                        </span>
                                                    </span>
                                                </div>
                                                {item.files.length > 0 &&
                                                    <span>
                                                        <DownloadIcon />
                                                        <span className={style.date}>
                                                            {item.files.length} {t("MAIN.FILES")}
                                                        </span>
                                                    </span>}
                                                <div className={style.firstLetterSection}>
                                                    <p className={style.fisrtLetter}>
                                                        {firstLetterBox(item?.user?.firstName)}
                                                    </p>
                                                    <h2 className={style.title}>
                                                        {item?.user?.firstName} {item?.user?.lastName}
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                <div className={style.page}>
                    <Pagination
                        current={current}
                        onChange={onPageChange}
                        total={reports.meta?.itemCount}
                        pageSize={12}
                        showSizeChanger={false}
                        pageSizeOptions={[]}
                    />
                </div>
            </div>
        </>
    )
}
export default ReportCard;
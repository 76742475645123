import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getSettings, updateSettings } from "@services/settings.service";
import { ISettingsResponseData } from "@models/index";

const initialState: ISettingsResponseData = {
  message: "",
  statusCode: 0,
  statusName: "",
  success: false,
  isSettingsUpdated: false,
  loading: false,
  about: "",
  address: "",
  copyright: "",
  description: "",
  favicon: {},
  keyword: "",
  logo: {},
  title: "",
  data: [],
  errors: ""
};

export const getSettingsThunk = createAsyncThunk(
  "settings/getSettings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getSettings();
      return Promise.resolve(response.data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateSettingsThunk = createAsyncThunk(
  "update/updateSettings",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await updateSettings(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const SettingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getSettingsThunk.fulfilled, (state: ISettingsResponseData, { payload }: PayloadAction<any>) => {
      state.success = true;
      state.data = payload[0];
      state.loading = false;
      state.isSettingsUpdated = false;
      state.errors = "";
      state.message = "";
    });

    builder.addCase(getSettingsThunk.rejected, (state: ISettingsResponseData, { payload }: PayloadAction<any>) => {
      state.success = false;
      state.loading = false;
      state.isSettingsUpdated = false;
      state.errors = payload;
      state.message = payload?.message;
    });

    builder.addCase(updateSettingsThunk.fulfilled, (state: ISettingsResponseData, { payload }: PayloadAction<any>) => {
      state.isSettingsUpdated = true;
      state.statusCode = payload.statusCode;
      state.statusName = payload.statusName;
      state.message = "";
      state.errors = "";

    });
    builder.addCase(updateSettingsThunk.rejected, (state: ISettingsResponseData, { payload }: PayloadAction<any>) => {
      state.errors = payload;
      state.isSettingsUpdated = false;
      state.message = payload.message;
    });
  },
});

export const getLogoInfo = (state: any) => {
  return {
    id: state.settings.data?.id,
    logo: state.settings.data?.logo,
  }
}
export const getFaviconInfo = (state: any) => {
  return {
    id: state.settings.data?.id,
    favicon: state.settings.data?.favicon,
  }
}
export const getGeneralContent = (state: any) => {
  const general = {
    id: state.settings.data?.id,
    copyright: state.settings.data?.copyright,
  }
  return general;
}
export const getMetaContent = (state: any) => {
  const meta = {
    id: state?.settings?.data?.id,
    title: state?.settings?.data?.title,
    adminPanelTitle: state?.settings?.data?.adminPanelTitle,
    description: state?.settings?.data?.description,
    keyword: state?.settings?.data?.keyword
  }
  return meta;
}
export const isSettingsUpdated = (state: any) => {
  return state.settings.isSettingsUpdated
}

export const getSettingsErrors = (state: any) => {
  return state.settings.errors;
}

export const getSettingsErrorMessage = (state: any) => {
  return state.settings.message;
}

export const getSettingsLoading = (state: any) => {
  return state.settings.loading;
}
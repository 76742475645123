import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, Skeleton, message } from "antd";
import TextArea from "antd/es/input/TextArea";

import {
    getMetaContent,
    getSettingsThunk,
    getSettingsErrorMessage,
    getSettingsErrors,
    getSettingsLoading,
    isSettingsUpdated,
    updateSettingsThunk
} from "@store/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { objectToFormData } from "@utils/index";
import { IMetaContent } from "models";

import style from "./settings-home.module.css";

export const SettingsOfHome = (): JSX.Element => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const loading = useAppSelector<boolean>(getSettingsLoading)
    const metaContent = useAppSelector<IMetaContent>(getMetaContent);
    const ismetaContentUpdated = useAppSelector<boolean>(isSettingsUpdated);
    const settingsErrors = useAppSelector(getSettingsErrors);
    const errorMessage = useAppSelector(getSettingsErrorMessage);

    const { title, description, keyword, adminPanelTitle } = metaContent;

    useEffect(() => {
        dispatch(getSettingsThunk());
    }, [dispatch])

    const updated = () => {
        messageApi.open({
            type: 'success',
            content: 'Home Page Updated',
            duration: 5,
        });
    };

    useEffect(() => {
        if (ismetaContentUpdated) {
            dispatch(getSettingsThunk());
            updated();
        }
    }, [ismetaContentUpdated])

    useEffect(() => {
        form.setFieldsValue({
            title,
            adminPanelTitle,
            description,
            keyword
        });
    }, [metaContent]);

    const onFinish = (values: any) => {
        const formData = objectToFormData(
            {
                "title": values.title,
                "adminPanelTitle": values.adminPanelTitle,
                "keyword": values.description,
                "description": values.keyword
            });
        const data = {
            formData,
            id: metaContent.id
        }
        dispatch(updateSettingsThunk(data));
    };

    return (
        <>
            {contextHolder}
            {settingsErrors
                ? settingsErrors.errors.map((item: any) => {
                    return (
                        <p key={item.id} className={style.error}>
                            {t(`ERRORS.${item.message.toUpperCase()}`)}
                        </p>
                    );
                })
                : ""}
            {errorMessage && settingsErrors.length < 0 ? <p className={style.error}>
                {t(`ERRORS.${errorMessage.toUpperCase()}`)}
            </p> : ""}

            <Form
                name="basic"
                className={style.homePageForm}
                initialValues={{
                    title,
                    adminPanelTitle,
                    description,
                    keyword
                }}
                onFinish={onFinish}
                form={form}
            >
                <div className={style.settingsHomeTab}>
                    <Form.Item
                        label="Meta Title"
                        name="title"
                        rules={[{ required: true, message: 'Please input meta title!' }]}
                        className={style.label}
                    >
                        {loading ? <Skeleton.Input active className={style.titleInputSkeleton} /> : <Input className={style.metaTitle} />}
                    </Form.Item>
                </div>
                <div className={style.settingsHomeTab}>
                    <Form.Item
                        label="Admin Panel Title"
                        name="adminPanelTitle"
                        rules={[{ required: true, message: 'Please input admin panel title!' }]}
                        className={style.label}
                    >
                        {loading ? <Skeleton.Input active className={style.titleInputSkeleton} /> : <Input className={style.metaTitle} />}
                    </Form.Item>
                </div>
                <div className={style.settingsHomeTab}>
                    <Form.Item
                        label="Meta Keyword"
                        name="keyword"
                        rules={[{ required: true, message: 'Please input meta keywords!' }]}
                        className={style.label}
                    >
                        {loading ? <Skeleton.Input active className={style.descriptionSkeleton} /> : <TextArea rows={4} placeholder="Keyword" className={style.metadesc} />}
                    </Form.Item>
                </div>
                <div className={style.settingsHomeTab}>
                    <Form.Item
                        label="Meta Description"
                        name="description"
                        rules={[{ required: true, message: 'Please input meta description' }]}
                        className={style.label}>
                        {loading ? <Skeleton.Input active className={style.descriptionSkeleton} /> : <TextArea rows={4} placeholder="Description" className={style.metadesc} />}
                    </Form.Item>
                </div>
                <div className={style.updateMetaButtonRow}>
                    <Button type="primary" htmlType="submit" className={style.updateMeta}>Update</Button>
                </div>
            </Form>
        </>
    );
};
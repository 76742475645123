import { Modal } from "antd"
import { ReactNode } from "react";

import { Place } from "@models/index";

interface IProps {
  place?: Place
  title: string;
  open: boolean;
  centered?: boolean;
  confirmLoading?: boolean;
  bodyStyle?: any
  cancel: any;
  ok?: any;
  okText?: any;
  okButtonProps?: any;
  cancelButtonProps?: any
  children?: ReactNode;
  width?: number
}

export const CustomModal = ({
  title,
  open,
  cancel,
  place,
  centered,
  ok,
  confirmLoading,
  bodyStyle,
  okText,
  okButtonProps,
  cancelButtonProps,
  children,
  width
}: IProps): JSX.Element => {

  return (
    <Modal
      open={open}
      title={title}
      footer={place === Place.BANNER ? null : undefined}
      onOk={ok}
      onCancel={cancel}
      okText={okText}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      centered={centered}
      confirmLoading={confirmLoading}
      bodyStyle={bodyStyle}
      width={width}
      className={(place === Place.PROFESSION || place === Place.PROFESSION_LIST || place === Place.USER_MANAGEMENT_LIST) ? 'modalStyle' : undefined}
    >
      {children}
    </Modal>
  )
}
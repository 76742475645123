import { IAdminLoginData } from "@models/interfaces/auth";
import { api } from ".";

/*API calls for auth */
export const adminLogin = (data: IAdminLoginData) => {
    return api.post("auth/admin/login", data);
}
export const getAdminInfoByToken = () => {
    return api.get('auth/admin');
}

export const sendRefreshToken = (data: string) => {
    return api.post('auth/refresh-token', {
        refreshToken: data
    });
}
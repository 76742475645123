import moment from 'moment';
import { useParams } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { CalendarOutlined } from '@ant-design/icons'
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DatePicker, Form, FormInstance, Input, Radio, TimePicker } from 'antd'

import { ActionTypes, IArticleData, AntDataType } from '@models/index';
import { CustomModal } from '@components/index';
import { PUBLISHED } from '@utils/index';

import style from './time-and-date.module.css'
import '../../../App.css'

interface IProps {
    editArticle: any;
    setArticleData: Dispatch<SetStateAction<IArticleData>>;
    form: FormInstance<any>;
    date: string;
    time: string;
    setAntData: Function
}

export const TimeAndDate = ({
    editArticle,
    setArticleData,
    form,
    date,
    time,
    setAntData
}: IProps): JSX.Element => {

    const { t } = useTranslation()
    const { id } = useParams<string>()

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(id ? true : false);

    useEffect(() => {
        setAntData(AntDataType.DATE)
    }, [date, time, editArticle?.date])

    const changeDateOrTime = (typeAction: ActionTypes, data: any): void => {
        setArticleData(prev => ({
            ...prev,
            [typeAction]: data
        }));
    }

    function resetDate(): void {
        changeDateOrTime(ActionTypes.DATE, '')
        changeDateOrTime(ActionTypes.TIME, '')
        form.resetFields(['date'])
    }

    const showModal = (): void => {
        setIsModalOpen(true);
    };

    const closeModal = (): void => {
        setIsModalOpen(false);
    };

    function disabledDate(current: any): any {
        return current && current < moment().startOf('day');
    }

    return (
        <>
            {editArticle?.status !== PUBLISHED &&
                <>
                    <div className='formItemWrapper'>
                        <Form.Item
                            label="Published"
                            name="published"
                            className='formItem'
                        >
                            <Radio.Group
                                onChange={e => setIsDatePickerOpen(e.target.value)}
                                defaultValue={isDatePickerOpen}
                                className={style.nowAndScheduleWrapper}
                            >
                                <Radio
                                    value={false}
                                    onClick={resetDate}
                                    className={style.nowAndSchedule}
                                >
                                    {t('MAIN.NOW')}
                                </Radio>
                                <Radio
                                    value={true}
                                    className={style.nowAndSchedule}
                                >
                                    {t('MAIN.SCHEDULE')}
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div hidden={!isDatePickerOpen} className='formItemWrapper'>
                        <Form.Item
                            label="News Published Date"
                            name="date"
                            rules={[
                                { required: isDatePickerOpen, message: `${t('MAIN.SET_DATE')}` }
                            ]}
                            className='formItem'
                        >
                            <Input
                                addonAfter={<CalendarOutlined className={style.datePickerIcon} />}
                                value={date || time
                                    ? `${date} ${time}`
                                    : moment(editArticle?.date).format("YYYY-MM-DD HH:mm:ss")}
                                className={style.showDate}
                                onClick={showModal}
                            />
                            <CustomModal
                                title=''
                                open={isModalOpen}
                                ok={closeModal}
                                cancel={closeModal}
                                bodyStyle={{ height: 130, lineHeight: "40px" }}
                                centered
                                okText="Schedule"
                            >
                                <h2>
                                    {t('ARTICLE.SENDMESSAGE')}
                                </h2>
                                <div className={style.dateAndTimeWrapper}>
                                    <DatePicker
                                        allowClear={false}
                                        disabledDate={disabledDate}
                                        showToday={false}
                                        className={style.datePicker}
                                        onChange={(_, date) => changeDateOrTime(ActionTypes.DATE, date)}
                                    />
                                    <span className={style.dateAndTimeDivider}>
                                        {t('ARTICLE.AT')}
                                    </span>
                                    <TimePicker
                                        allowClear={false}
                                        showNow={false}
                                        className={style.timePicker}
                                        onChange={(_, time) => changeDateOrTime(ActionTypes.TIME, time)} />
                                </div>
                            </CustomModal>
                        </Form.Item>
                    </div>
                </>}
        </>
    )
}
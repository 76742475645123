import { createAsyncThunk, createSlice, current, PayloadAction } from "@reduxjs/toolkit";

import { changePasswordByAdmin, createUserFromAdmin, deleteUser, getUserByID, getUsers, updateUserByAdmin } from "@services/user.service";
import { IUserResponseData } from "@models/index";

const initialState: IUserResponseData = {
  message: "",
  statusCode: 0,
  statusName: "",
  success: false,
  loading: false,
  about: "",
  address: "",
  copyright: "",
  description: "",
  favicon: {},
  keyword: "",
  logo: {},
  title: "",
  data: { data: [], meta: {} },
  reportPageList:{data:[],meta:{}},
  userbyid: {},
  isUserCreated: false,
  isPasswordChanged: false,
  errors: [],
  isUserUpdated: false,
  isUserDeleted: false
};

export const getUserThunk = createAsyncThunk(
  "user/getUsers",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getUsers(data);
      return Promise.resolve(response.data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createUserFromAdminThunk = createAsyncThunk(
  "user/createuserfromadmin",
  async (data: string, { rejectWithValue }) => {
    try {
      const response = await createUserFromAdmin(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserByIdThunk = createAsyncThunk(
  "getUser/getuserbyid",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getUserByID(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUserThunk = createAsyncThunk(
  "user/deleteUsers",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await deleteUser(data.id);

      return Promise.resolve(response.data);
    } catch (error: any) {

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserByAdminThunk = createAsyncThunk(
  "user/updateusersbyadmin",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await updateUserByAdmin(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const changePasswordByAdminThunk = createAsyncThunk(
  "user/changepasswordbyadmin",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await changePasswordByAdmin(data.data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getUserThunk.pending, (state: IUserResponseData) => {
      state.loading = true;
    });
    builder.addCase(getUserThunk.fulfilled, (state: IUserResponseData, { payload }: PayloadAction<any>) => {
      state.success = true;
      state.reportPageList = { data: [...(current(state)?.data?.data ?? []), ...payload.data,], meta: payload.meta } as any;
      state.data=payload
      state.loading = false;
      state.isUserCreated = false;
      state.isUserDeleted = false;
      state.isPasswordChanged = false;
      state.isUserUpdated = false;
      state.errors = [];
      state.message = "";
    });
    builder.addCase(getUserThunk.rejected, (state: IUserResponseData) => {
      state.success = false;
      state.loading = false;
    });
    builder.addCase(createUserFromAdminThunk.pending, (state: IUserResponseData) => {
      state.loading = true;
      state.isUserCreated = false;
      state.errors = [];
    });
    builder.addCase(createUserFromAdminThunk.fulfilled, (state: IUserResponseData, { payload }: PayloadAction<any>) => {
      state.success = payload;
      state.isUserCreated = true;
      state.loading = false;
      state.isPasswordChanged = false;
      state.errors = [];
      state.message = "";
    });
    builder.addCase(createUserFromAdminThunk.rejected, (state: IUserResponseData, { payload }: any) => {
      if ("errors" in payload) {
        state.errors = payload.errors
      } else {
        state.errors = payload.message?.toUpperCase()
      }
      state.message = payload?.message;
      state.isUserCreated = false;
      state.loading = false;
    });

    builder.addCase(changePasswordByAdminThunk.pending, (state: IUserResponseData) => {
      state.loading = true;
      state.isUserUpdated = false;
    });
    builder.addCase(changePasswordByAdminThunk.fulfilled, (state: IUserResponseData, { payload }: PayloadAction<any>) => {
      state.success = payload;
      state.isPasswordChanged = true;
      state.loading = false;
      state.errors = [];
      state.message = "";
    });
    builder.addCase(changePasswordByAdminThunk.rejected, (state: IUserResponseData, { payload }: any) => {
      state.errors = payload.errors;
      state.isPasswordChanged = false;
      state.loading = false;
    });


    builder.addCase(updateUserByAdminThunk.pending, (state: IUserResponseData) => {
      state.loading = true;
      state.isUserUpdated = false;
      state.isUserCreated = false;
      state.errors = []
    });
    builder.addCase(updateUserByAdminThunk.fulfilled, (state: IUserResponseData, { payload }: PayloadAction<any>) => {
      state.success = payload;
      state.loading = false;
      state.isUserUpdated = true;
      state.errors = [];
      state.message = "";
    });
    builder.addCase(updateUserByAdminThunk.rejected, (state: IUserResponseData, { payload }: any) => {
      if ("errors" in payload) {
        state.errors = payload.errors
      } else {
        state.errors = payload.message?.toUpperCase()
      }
      // state.errors = payload.errors;
      // state.message = payload?.message;
      state.isUserUpdated = false;
      state.isUserCreated = false;
      state.isUserDeleted = false;
      state.loading = false;
    });

    builder.addCase(getUserByIdThunk.pending, (state: IUserResponseData) => {
      state.isUserUpdated = false;
      state.userbyid = {}
    });
    builder.addCase(getUserByIdThunk.fulfilled, (state: IUserResponseData, { payload }: PayloadAction<any>) => {
      state.userbyid = payload.data;
      state.isUserUpdated = false;
      state.isPasswordChanged = false;
      state.message = "";
    });

    builder.addCase(getUserByIdThunk.rejected, (state: IUserResponseData, { payload }: any) => {
      state.success = false;
      state.isUserUpdated = false;
      state.message = payload?.message;
    });
    builder.addCase(deleteUserThunk.fulfilled, (state: IUserResponseData, { payload }: PayloadAction<any>) => {
      state.success = payload;
      state.isUserDeleted = true;
      state.loading = false;
      state.message = "";
    });

    builder.addCase(deleteUserThunk.pending, (state: IUserResponseData) => {
      state.isUserDeleted = false;
    });

    builder.addCase(deleteUserThunk.rejected, (state: IUserResponseData) => {
      state.isPasswordChanged = false;
      state.loading = false;
      state.isUserDeleted = false;
    });
  },
});

export const getUserList = (state: any) => {

  return state?.user?.data;
}
export const getUserListForTaskReport = (state: any) => {

  return state?.user?.reportPageList;
}

export const getUserErrors = (state: any) => {
  return state.user.errors;

}
export const getUserErrorMessage = (state: any) => {
  return (state.user.message).toUpperCase();
}
export const isUserCreatedByAdmin = (state: any) => {
  return state.user.isUserCreated;
}
export const getUserInfo = (state: any) => {
  return state.user.userbyid;
}
export const isAddUserLoading = (state: any) => {

  return state.user.loading;
}
export const isEditUserLoading = (state: any) => {

  return state.user.loading;
}
export const isPasswordChangedByAdmin = (state: any) => {

  return state.user.isPasswordChanged;
}
export const isUserUpdatedByAdmin = (state: any) => {

  return state.user.isUserUpdated;
}
export const isChangePasswordLoading = (state: any) => {

  return state.user.loading;
}
export const isUserDeletedByAdmin = (state: any) => {
  return state.user.isUserDeleted;
}

export const getUserLoading = (state: any) => {
  return state.user.loading;
}
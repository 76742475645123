import { useEffect, useState } from "react";
import { RcFile } from "antd/es/upload";
import { useTranslation } from "react-i18next";
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Upload, UploadProps, message } from "antd";

import {
    createSocialThunk,
    getOneSocialInfo,
    getSingleSectionLoading,
    getSocialErrors,
    resetSocialErrors,
    updateSocialThunk
} from "@store/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { createImagePath, getBase64 } from "@utils/index";
import { ISocialFormData } from "@models/index";
import { nofile } from "@assets/index"

import styles from "./social.module.css";

interface IProps {
    id?: number;
    isCancelClicked: Function
}

export const CreateOrUpdateSocialCard = ({ id, isCancelClicked }: IProps): JSX.Element => {

    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const { t } = useTranslation();

    const cardsInfo = useAppSelector(getOneSocialInfo);
    const socialErrors = useAppSelector(getSocialErrors);
    const singleSocialLoading = useAppSelector(getSingleSectionLoading)

    const [base64, setBase64] = useState<any>(null);
    const [validateImageField, setValidateImage] = useState<boolean>(false)

    useEffect(() => {
        dispatch(resetSocialErrors())
    }, [dispatch])

    useEffect(() => {
        if (id) {
            form.setFieldsValue({
                "url": cardsInfo.link,
                "name": cardsInfo.name,
                "image": cardsInfo.image
            })
        }
    }, [cardsInfo,id])

    const props: UploadProps = {
        name: 'file',
        headers: {
            authorization: 'authorization-text',
        },
        accept: "image/*",
        beforeUpload: () => false,
        async onChange({ fileList, file }) {
            if (fileList.length) {
                const img = await getBase64(file as RcFile);
                form.setFieldValue('image', file);
                setBase64(img);
            } else {
                setBase64(undefined)
            }

            if (file.status === 'done') {
                message.success(`${file.name} file uploaded successfully`);
            } else if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`);
            }
        },
        fileList: form.getFieldValue('image') ? [form.getFieldValue('image')] : undefined,
        showUploadList: false
    };

    const onFinish = (socialData: ISocialFormData): void => {
        dispatch(id
            ? updateSocialThunk({ socialData, id })
            : createSocialThunk(socialData));
    };

    const receivedErrors = (): JSX.Element | JSX.Element[] => {
        if (Array.isArray(socialErrors)) {
            return socialErrors.map((item: any) => {
                return (
                    <p className={styles.error}>
                        {t(`SOCIALS.${item?.message?.toUpperCase()}`)}
                    </p>
                )
            })
        } else if (socialErrors) {
            return (
                <p className={styles.error}>
                    {t(`ERRORS.${socialErrors}`)}
                </p>
            )
        }
        return <></>
    }

    return (
        <>
            {singleSocialLoading && id ?
                <p>{t('MAIN.LOADING')}</p> :
                <Card className={styles.card}>
                    {receivedErrors()}
                    <Form
                        name="basic"
                        labelCol={{ span: 16 }}
                        wrapperCol={{ span: 28 }}
                        style={{ maxWidth: 600 }}
                        onFinish={onFinish}
                        autoComplete="off"
                        layout="vertical"
                        form={form}
                    >
                        <Form.Item
                            label="URL:"
                            name="url"
                            rules={[{ required: true, message: '' }]}
                            style={{ marginBottom: 0 }}
                        >
                            <Input
                                addonAfter={<CloseOutlined onClick={() => form.resetFields(["url"])} />}
                                className={styles.socialInputs}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Name Social Media:"
                            name="name"
                            rules={[{ required: true, message: '' }]}
                            style={{ marginBottom: 0 }}
                        >
                            <Input
                                addonAfter={<CloseOutlined onClick={() => form.resetFields(["name"])} />}
                                className={styles.socialInputs}
                            />
                        </Form.Item>
                        <Form.Item
                            name="image"
                            style={{ marginBottom: 0 }}
                            rules={[{ required: true, message: '' }]}
                            className={styles.uploadLine}
                        >
                            <div>
                                <p>{t("MAIN.IMAGE")}</p>
                                <div
                                    className={`${styles.imageLabel} 
                                    ${validateImageField && !base64 && !cardsInfo.image
                                            ? styles.imageValidate
                                            : ''}`
                                    }
                                >
                                    <img
                                        src={base64
                                            ? base64
                                            : id
                                                ? createImagePath(cardsInfo.image?.path)
                                                : nofile}
                                        className={styles.uploadedSocialImage}
                                        alt="Social image"
                                    />
                                </div>
                            </div>
                            <div>
                                <Upload {...props}>
                                    <Button
                                        icon={<UploadOutlined />}
                                        className={styles.uploadButton}
                                    >
                                        {t("MAIN.UPLOAD")}
                                    </Button>
                                </Upload>
                            </div>
                        </Form.Item>
                        <br />
                        <div className={styles.socialButtonsRow}>
                            <Button
                                type="primary"
                                className={styles.socialCancelButton}
                                onClick={() => isCancelClicked(true)}
                            >
                                {t('MAIN.CANCEL')}
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className={styles.socialSaveButton}
                                onClick={() => setValidateImage(true)}
                            >
                                {t('MAIN.SAVE')}
                            </Button>
                        </div>
                    </Form>
                </Card>
            }
        </>
    );
}
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { Space, TableProps, Tag, Tooltip } from "antd";
import { ColumnsType, SorterResult } from "antd/es/table/interface";

import {
    getOpenCloseRoleModal,
    getProfessionList,
    getProfessionsThunk,
    getshowEditModal,
    openCloseRoleModal,
    deleteUserThunk,
    getUserThunk,
    getUserList,
    isUserDeletedByAdmin,
    getUserLoading
} from "@store/index";
import {
    ArticlesListSkeleton,
    CustomButton,
    CustomModal,
    CustomTable,
    SearchFilter
} from "@components/index";
import { PAGE_LIMIT, dynamicColors, getDynamicColor } from "@utils/index";
import { Profession } from "@pages/profession/components/profession";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { IProfesionList, DataType } from "@models/index";
import { ProfessionList } from "@pages/index";

import { ReactComponent as DeleteIcon } from "@assets/delete.svg";
import { ReactComponent as EditIcon } from "@assets/edit.svg";

import style from './user.module.css';

export const UserManagement = (): JSX.Element => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const userList = useAppSelector<any>(getUserList)
    const loading = useAppSelector(getUserLoading);
    const professionList = useAppSelector<IProfesionList[]>(getProfessionList);
    const isRoleModal = useAppSelector<boolean>(getOpenCloseRoleModal);
    const isEditmodal = useAppSelector<boolean>(getshowEditModal)
    const isUserDeleted = useAppSelector(isUserDeletedByAdmin)

    const [searchData, setSearchData] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [open, setOpen] = useState<boolean>(false);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [chooseModal, setChooseModal] = useState<number>(1);
    const [userId, setUserID] = useState<number>(1);
    const [roleMode, setRoleMode] = useState<boolean>(isRoleModal);
    const [sortedInfo, setSortedInfo] = useState<SorterResult<DataType>>({});

    const [userListByPage, setUserListByPage] = useState<any>();

    // useEffect(() => {
    //     setCurrentPage(location?.state?.page || 1);
    // }, [location?.state?.page]);

    useEffect(() => {    
        dispatch(getProfessionsThunk())
        if (isUserDeleted || searchData) {
            dispatch(getUserThunk({
                searchData,
                take: PAGE_LIMIT,
                page: Number(queryParams.get('page')) || 1
            }))
        }
    }, [isUserDeleted, searchData,  dispatch])


    useEffect(()=>{    
        if (currentPage) {
            dispatch(getUserThunk({
                searchData,
                take: PAGE_LIMIT,
                page: currentPage
            }))
        }
    },[currentPage])

    useEffect(() => {
        if (professionList.length) {
            dynamicColors(professionList)
        }
    }, [professionList])

    const onSearchChange = (val: any): void => {
        setSearchData(val)
    }

    const customizeRole = professionList.map(({ name, id }: any) => {
        return {
            text: name,
            value: id
        }
    })

    const showModal = (param: number, id?: any): void => {
        setOpen(true);
        setChooseModal(param);
        setUserID(id)
        dispatch(openCloseRoleModal(false))
    };

    const handleOk = (): void => {
        setConfirmLoading(true);
        setTimeout(() => {
            setConfirmLoading(false);
        }, 2000);

        const datas: any = {
            id: userId,
        }
        dispatch(deleteUserThunk(datas))
        setOpen(false);
    };

    useEffect(() => {
        if (isRoleModal) {
            dispatch(openCloseRoleModal(isRoleModal))
        }
    }, [isRoleModal, dispatch])

    const handleCancel = (): void => setOpen(false);

    const handleChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter): void => {
        setSortedInfo(sorter as SorterResult<DataType>);
       
        if (pagination.current) {
            setCurrentPage(pagination.current);
            queryParams.set('page', pagination.current.toString());
        }

       customSortAndFilter(sorter, filters, pagination)
    };

    const customSortAndFilter: any = (sorter: any, filters: any, page: any): void => {
        const order = sorter.order == "descend" ? "DESC" : "ASC";

        const requestData: any = {
            searchData,
            take: PAGE_LIMIT,
            order,
            orderBy: sorter.field,
            page: page.current
        }
       

        if (filters?.profession?.length && filters?.profession[0] !== "") {
            requestData.professionId = filters?.profession[0]
        }

        dispatch(getUserThunk(requestData))
    }
    const handlePageChange = (page: number): void => {   
        const queryParams = new URLSearchParams(location.search);     
        queryParams.set('page', String(page));       
        navigate(`${location.pathname}?${queryParams.toString()}`);
    };
   const paginationConfig = {
        onChange: handlePageChange,
        current: parseInt(new URLSearchParams(location.search).get('page') || currentPage.toString(), 10),
        pageSize: PAGE_LIMIT,
        total: userList.meta?.itemCount
    };
    
    const columns: ColumnsType<DataType> = [
        {
            title: "Id",
            dataIndex: 'id',
            key: 'id',
            width: 80,
            render: (_, record, index) => ((currentPage - 1) * PAGE_LIMIT) + index + 1,
            sorter: (previousElement: any, nextElement: any): any => {
                return previousElement.id > nextElement.id
            },
            defaultSortOrder: 'descend'
        },
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            key: "firstName",
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'firstName' ? sortedInfo.order : null,
            ellipsis: true,
            showSorterTooltip: false
        },
        {
            title: "Last Name",
            dataIndex: "lastName",
            key: "lastName",
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'lastName' ? sortedInfo.order : null,
            ellipsis: true,
            showSorterTooltip: false
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            width: 280,
        },
        {
            title: "Phone",
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: "Role",
            key: "profession",
            dataIndex: "profession",
            filters: [{ text: "All", value: "-1" }, { text: "Without Role", value: 0 }, ...customizeRole],
            filterMultiple: false,
            width: 180,
            onFilter: (value: any) => { return value },
            render: (_, record) => {
                if (record.profession) {
                    const { profession } = record;
                    const { name } = profession;
                    if (profession) {
                        const colors = getDynamicColor(name);
                        const color = colors?.color;
                        const bgcolor = colors?.bgcolor;
                        return (
                            <Tooltip title={name}>
                            <Tag
                                color={bgcolor}
                                key={profession.id}
                                className={style.threeDots}
                                style={{
                                    color: `${color}`,
                                    opacity: '1',
                                    fontSize: 14
                                }}
                            >
                                {name}
                            </Tag>
                            </Tooltip>
                        )
                    }
                }
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: 120,
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
            ellipsis: true,
            showSorterTooltip: false
        },
        {
            title: "Last Visit",
            dataIndex: "lastVisit",
            key: "lastVisit",
            render: (_, record: any) => {
                return record.lastVisit ? moment(record.lastVisit).format('DD/MM/YYYY') : "";
            }
        },
        {
            title: "Action",
            key: "action",
            width: 100,
            render: (_, record) => (
                <Space size="small">
                    <a onClick={e => {
                        e.stopPropagation();
                        navigate(`/user-management/user/${record.id}`, { state: { page: new URLSearchParams(location.search).get('page') } })
                    }}>
                        <EditIcon />
                    </a>
                    <a onClick={e => {
                        e.stopPropagation();
                        showModal(1, record.id)
                    }}>
                        <DeleteIcon />
                    </a>
                </Space>
            )
        }
    ];

    const handleRoleCancel = (): void => {
        setOpen(false);
        setRoleMode(!isRoleModal)
        dispatch(openCloseRoleModal(roleMode))
    }

    const handleRowClick = (record: DataType): void => {
        navigate(`/user-management/user/${record.id}`, { state: { page: new URLSearchParams(location.search).get('page') } });
    }
console.log(userListByPage)
    return (
        <>
            <Space size="middle" className={style.right}>
                <div className={style.search}>
                    <SearchFilter onSearchChange={onSearchChange} />
                </div>
                <div className="mr10">
                    <CustomButton
                        onClick={() => navigate('/user-management/user')}
                        name={"User"}
                        icon={<PlusOutlined />}
                    />
                    <CustomButton
                        onClick={() => {
                            showModal(2)
                            setRoleMode(isRoleModal)
                            dispatch(openCloseRoleModal(roleMode))
                        }}
                        name={"Role"}
                        icon={<PlusOutlined />}
                    />
                </div>
            </Space>

            {loading ?
                <ArticlesListSkeleton /> :
                <>
                    <CustomTable
                        columns={columns}
                        dataSource={userList.data}
                        onChange={handleChange}
                        scroll={{ x: 1500 }}
                        pagination={paginationConfig}
                        onRow={(record: any) => ({
                            onClick: () => handleRowClick(record),
                        })}
                    />
                </>
            }

            <CustomModal
                title={chooseModal === 1 ? "Are you sure want to delete this item?" : "Professions"}
                centered
                open={open}
                ok={chooseModal === 1 ? handleOk : undefined}
                okButtonProps={chooseModal !== 1 ? { style: { display: 'none' } } : undefined}
                cancelButtonProps={chooseModal !== 1 ? { style: { display: 'none' } } : undefined}
                okText={chooseModal === 1 ? "Delete" : ''}
                confirmLoading={confirmLoading}
                cancel={chooseModal === 1 ? handleCancel : handleRoleCancel}
                bodyStyle={chooseModal === 1 ? { height: 50 } : undefined}
                width={chooseModal !== 1 ? 1000 : undefined}
            >
                {
                    chooseModal !== 1 ?
                        (isRoleModal || isEditmodal)
                            ? <Profession />
                            : <ProfessionList />
                        : ''
                }
            </CustomModal>
        </>
    )
}

// Retrieve query parameters for pagination
//const page = Number(queryParams.get('page')) || 1;

// const handleChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter): void => {
//     // Update query parameters based on pagination changes
//     const params = queryString.stringify({ ...queryParams, page: pagination.current });
//     navigate(`?${params}`);
// };

//  useEffect(() => {
//     // Dispatch action to fetch data based on query parameters
//     if (isUserDeleted || queryParams.get('searchData') || queryParams.get('page')) {
//         dispatch(getUserThunk({
//             searchData: queryParams.get('searchData'),
//             take: PAGE_LIMIT,
//             page: Number(queryParams.get('page')) || 1
//         }));
//     }
// }, [queryParams, isUserDeleted, dispatch]);

{/* <span className="tableOption"> Showing {data.data.length < PAGE_LIMIT ? data.data.length : PAGE_LIMIT} of {data.data.length}</span> */ }

import { Progress } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IStatisticsData, OnOff } from '@models/index';

import style from './statistics-charts.module.css';

interface IProps {
  statistics: IStatisticsData,
}

export const StaffOnlineChart = ({ statistics }: IProps): JSX.Element => {

  const { t } = useTranslation();
  const [userCountPercent, setUserCountPercent] = useState<number>();

  useEffect(() => {
    const count = statistics?.offlineUsers + statistics?.onlineUsers;
    setUserCountPercent((statistics?.onlineUsers * 100) / count as any)
  }, [])

  return (

    <div className={style.online}>
      <h4>
        {t('MAIN.STAFF_ONLINE')}
      </h4>
      <Progress
        type="circle"
        width={200}
        strokeColor={{
          '30%': '#3DA2C2',
          '100%': '#16465A'
        }}
        strokeWidth={8}
        percent={userCountPercent}
        format={() => (
          <div className={style.insideText}>
            <div style={{ fontSize: 20 }}>
              {statistics?.offlineUsers + statistics?.onlineUsers}
            </div>
            <div style={{ fontSize: 16 }}>
              {t("MAIN.MEMBERS")}
            </div>
          </div>
        )}
        className={style.memberCount}
      />
      <div className={style.statusSection}>
        <div>
          <div className={style.onlineText} />
          <span>{OnOff.ONLINE}
            <strong>
              {statistics?.onlineUsers}
            </strong>
          </span>
        </div>
        <div>
          <div className={style.offlineText} />
          <span>{OnOff.OFFLINE}
            <strong>
              {statistics?.offlineUsers}
            </strong>
          </span>
        </div>
      </div>
    </div>
  )
}
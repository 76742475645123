import i18n from "@i18n/index";

export const ACCESS_TOKEN_DURATION = 15;
export const REFRESH_TOKEN_DURATION = 7;
export const permissions = [{
  'dashboard': 'Dashboard',
  'userManagement': "User Management",
  'contentManagement': "Content Managment",
  'settings': "Settings",
  'management': "Management"
}];

export const PAGE_LIMIT = 10;
export const BASE_URL = process.env.REACT_APP_IMAGE_URL

export const MIN_LENGTH_ARTICLE = 2
export const MIN_LENGTH_SECTION = 2
export const MAX_LENGTH_SECTION_KEY = 50
export const MAX_LENGTH_ARTICLE_TITLE = 100
export const MAX_LENGTH_SECTION_NAME = 100
export const MAX_LENGTH_ARTICLE_CONTENT = 3000
export const UPLOAD_FILE_TYPES = ['image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/webp', 'image/avif', 'image/gif']

export const DEFAULT_LANGAUGE = i18n.language.toLowerCase()
export const DEFAULT_LANGAUGE_LABEL = "English"
export const DEFAULT_SETTINGS_TAB = 'logo'
export const PUBLISHED = 'published'
import { useDispatch } from "react-redux";
import { Button, Form, Input } from "antd";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons"

import {
  DEFAULT_LANGAUGE,
  MAX_LENGTH_SECTION_NAME,
  MAX_LENGTH_SECTION_KEY,
  MIN_LENGTH_SECTION,
  getServerError
} from "@utils/index";
import {
  createSectionThunk,
  getAllLanguages,
  getIsCreated,
  getSectionCreatePending,
  getSectionErrors,
  resetSectionError
} from "@store/index";
import {
  SectionChangeType,
  ISectionPageData,
  ISectionLanguage,
  ILanguageData, LocationOfUse, ServerErrorType
} from "@models/index";
import { CustomButton, Language, SuccessOrErrorMessage } from "@components/index";
import { useAppSelector } from "@hooks/index";

import style from "./section.module.css";
import '../../App.css'

export const AddSection = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate()

  const languages = useAppSelector(getAllLanguages)
  const isPending = useAppSelector(getSectionCreatePending);
  const serverErrors = useAppSelector(getSectionErrors)
  const sectionCreated = useAppSelector(getIsCreated)

  const [currentLng, setCurrentLng] = useState<ISectionLanguage>();
  const [sectionData, setSectionData] = useState<ISectionPageData>({
    key: '',
    content: []
  });

  useEffect(() => {
    if (!sectionData.content.length) {
      languages.forEach((lng: ILanguageData) => {
        setSectionData(prev => ({
          key: '',
          content: [
            ...(prev.content),
            {
              languageId: lng.id,
              name: ''
            }],
        }));
      });
    }

    setCurrentLng(languages.find((lng: ILanguageData) => lng.key === DEFAULT_LANGAUGE))

    return () => { dispatch(resetSectionError()) }
  }, [languages, dispatch])

  const onFinish = () => {
    dispatch(createSectionThunk(sectionData));
  };

  function changeSectionData(typeAction: SectionChangeType, data: string): void {
    setSectionData(prev => {
      const updatedData = { ...prev };
      if (typeAction === SectionChangeType.NAME) {
        updatedData.content = prev.content.map(lng => {
          if (lng.languageId === currentLng?.id) {
            return {
              ...lng,
              name: data,
            };
          }
          return lng;
        });
      } else if (typeAction === SectionChangeType.KEY) {
        updatedData.key = data;
      }
      return updatedData;
    });
  }

  function findSectionName(): string | undefined {
    const sectionName = sectionData.content.find(lng => lng.languageId === currentLng?.id);
    return sectionName?.name
  }

  const onSubmit = (): void => {
    if ((findSectionName()?.length ?? 0) < MIN_LENGTH_SECTION || (findSectionName()?.length ?? 0) > MAX_LENGTH_SECTION_NAME) {
      return
    }

    sectionData.content.forEach(lng => {
      if (!lng.name || lng.name.length >= MAX_LENGTH_SECTION_NAME) {
        setCurrentLng(languages.find((receivedLng: ILanguageData) => lng.languageId === receivedLng.id))
      }
    })
    sectionData.content.forEach(lng => !!(!lng.name) && setCurrentLng(languages.find((receivedLng: ILanguageData) => lng.languageId === receivedLng.id)))
  }

  return (
    <div className="bgColor">
      <SuccessOrErrorMessage
        sectionKey={sectionData.key}
        sectionCreated={sectionCreated}
      />
      <div className={style.prevBtnWrapper}>
        <div className={style.languageMenuWrapper}>
          <p className={style.currentLanguage}>
            {currentLng?.label}
          </p>
          <Language getCurrentLanguage={setCurrentLng} section />
        </div>
        <CustomButton
          name="Back"
          onClick={() => navigate(-1)}
          icon={<LeftOutlined />}
        />
      </div>
      <Form
        name="Section form"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{
          maxWidth: 600,
          margin: '76px 0 0 50px'
        }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
        autoComplete="off"
      >
        {languages.map(({ id }: ILanguageData) => {
          return (
            <div key={id} hidden={currentLng?.id !== id}>
              <div>
                <Form.Item
                  label="Name"
                  name={`name${id}`}
                  className="formItem"
                  rules={[{ required: true, message: `${t('SECTION.PLEASE_INPUT_SECTION_NAME')}` }, { min: MIN_LENGTH_SECTION, message: 'Category name is very short' }, { max: MAX_LENGTH_SECTION_NAME, message: 'Category name is very long' }]}
                  help={getServerError(LocationOfUse.SECTION, ServerErrorType.NAME, serverErrors, currentLng?.id) ? <p className="errMessage">{t(`MAIN.${getServerError(LocationOfUse.SECTION, ServerErrorType.NAME, serverErrors, currentLng?.id)}`)}</p> : undefined}
                >
                  <Input onChange={e => changeSectionData(SectionChangeType.NAME, e.target.value)} />
                </Form.Item>
              </div>
            </div>
          )
        })}
        <div>
          <Form.Item
            label="Key"
            name="key"
            className="formItem"
            rules={[{ required: true, message: `${t('SECTION.PLEASE_INPUT_SECTION_KEY')}` }, { min: MIN_LENGTH_SECTION, message: 'Category key is very short' }, { max: MAX_LENGTH_SECTION_KEY, message: 'Category key is very long' }, { pattern: new RegExp(/^[a-z_]+$/), message: 'The text must be only lowercase letters, and contain an underscore' }]}
            help={getServerError(LocationOfUse.SECTION, ServerErrorType.NAME, serverErrors, currentLng?.id) ? <p className="errMessage">{!!getServerError(LocationOfUse.SECTION, ServerErrorType.KEY, serverErrors) && t(`MAIN.${getServerError(LocationOfUse.SECTION, ServerErrorType.KEY, serverErrors)}`)}</p> : undefined}
          >
            <Input onChange={e => changeSectionData(SectionChangeType.KEY, e.target.value)} />
          </Form.Item>
        </div>
        <Form.Item wrapperCol={{ offset: 16, span: 16 }}>
          <Button
            onClick={onSubmit}
            disabled={isPending || sectionCreated}
            type="primary" htmlType="submit"
            className={style.addUserButton}
          >
            {t('MAIN.ADD')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
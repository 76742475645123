import { Button, Form, Input } from "antd"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getGeneralContent, updateSettingsThunk } from "@store/index"
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { objectToFormData } from "@utils/index";
import { IGeneralContent } from "@models/index";

import style from "./settings-general-content.module.css";

export const Copyright = (): JSX.Element => {

    const [form] = Form.useForm();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const generalContent = useAppSelector<IGeneralContent>(getGeneralContent);

    const [name, setName] = useState<string>("");

    useEffect(() => {
        form.setFieldsValue({ copyright: generalContent?.copyright });
    }, [generalContent])

    const onFinish = (values: any): void => {
        const formData = objectToFormData({ "copyright": values.copyright });
        const data = {
            formData: formData,
            id: generalContent.id
        }

        dispatch(updateSettingsThunk(data));
    };

    return (
        <Form
            name="basic"
            form={form}
            style={{ maxWidth: 1500, display: "flex" }}
            initialValues={{
                copyright: generalContent?.copyright,
            }}
            onFinish={onFinish}
        >
            <Form.Item
                label="Copyright"
                name="copyright"
                rules={[{ required: true, message: 'Please input copyright info!' }]}
                className={style.label}
            >
                <Input
                    className={style.generalContentInput}
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </Form.Item>
            <div className={style.socialMediaButtonRow}>
                <Button
                    type="primary"
                    htmlType="submit"
                    className={style.updateGeneralContent}
                >
                    {t("MAIN.UPDATE")}
                </Button>
            </div>
        </Form>
    )
}
import { Tabs, TabsProps } from "antd";
import { useParams } from "react-router-dom";

import { ReportPlace, UserManagementTabs } from "@models/index";
import ChangePassword from "./change-password";
import User from "./user"
import { Reports } from "@pages/index";

import style from "../user.module.css";

export const EditUserTabs = (): JSX.Element => {

  const params = useParams();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: UserManagementTabs.UPDATE,
      children: <User />,
    },
    {
      key: '2',
      label: UserManagementTabs.CHANGE_PASSWORD,
      children: <ChangePassword />,
    },
    {
      key: '3',
      label: UserManagementTabs.TASK_REPORT,
      children: <Reports
        userId={params}
        hasFilter={true}
        place={ReportPlace.EDIT_USER}
      />,
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} className={style.userTabs} />

};
export default EditUserTabs;
import { Skeleton } from 'antd'

import style from './partners.module.css'

export const PartnersSkeleton = () => {

    function generatePartnersCards(cardsCount: number): JSX.Element[] {
        const partnersCards = [];
        for (let i = 0; i < cardsCount; i++) {
            partnersCards.push(
                <div className={style.cardWrapper}>
                    <div className={style.imageWrapper}>
                        <Skeleton.Image active className={style.image} />
                    </div>
                    <Skeleton.Input active className={style.partnerTitleWrapper} />
                </div>
            );
        }
        return partnersCards
    }

    return (
        <div className={style.mainWrapper}>
            <div>
                <Skeleton
                    active
                    title={false}
                    paragraph={{
                        rows: 1,
                        width: 70
                    }} />
            </div>
            <div className={style.mainContent}>
                {generatePartnersCards(6)}
            </div>
        </div>
    )
}
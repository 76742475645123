import { Button } from "antd";
import { useEffect } from "react";
import { Header } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import {
    getAdminInfo,
    getAdminInfoByTokenThunk,
    getAdminToken,
    getLogoInfo,
    getMetaContent,
    getSettingsThunk
} from "@store/index";
import { IAdminInfo, ILogo, IMetaContent } from "@models/index";
import { BASE_URL, createImagePath, firstLetterBox } from "@utils/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import defaultLogo from "@assets/defaultLogo.png";

import style from "./header.module.css";

interface IProps {
    collapsed: boolean;
    setCollapsed: (collapsed: boolean) => void;
    setIsOpen: (isOpen: boolean) => void;
    isOpen: boolean;
}

export const AdminHeader = ({
    collapsed,
    setCollapsed,
    setIsOpen,
    isOpen
}: IProps): JSX.Element => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const logo = useAppSelector<ILogo>(getLogoInfo);
    const adminInfo = useAppSelector<IAdminInfo>(getAdminInfo);
    const token = useAppSelector<string>(getAdminToken);
    const title = useAppSelector<IMetaContent>(getMetaContent);

    useEffect(() => {
        dispatch(getAdminInfoByTokenThunk(token));
        dispatch(getSettingsThunk());
    }, [dispatch])

    return (
        <Header className={style.header}>
            <div className={style.collapsed}>
                <img
                    src={logo
                        ? createImagePath(logo.logo?.path)
                        : `${BASE_URL}${defaultLogo}`}
                    alt={`${title.title}`}
                    className={logo
                        ? style.logo
                        : style.defaultLogo}
                    onClick={() => navigate('/')}
                />
                <Button
                    className={style.menuButton}
                    type="text"
                    icon={
                        collapsed
                            ? <MenuUnfoldOutlined />
                            : <MenuFoldOutlined />
                    }
                    onClick={() => {
                        setCollapsed(!isOpen);
                        setIsOpen(!collapsed);
                    }}
                />
            </div>
            <div className={style.rightdropdown}>
                <div className={style.rightContent}>
                    <span>
                        {t("MAIN.WELCOME")} {adminInfo.name} (admin)
                    </span>
                    <span className={style.firstLetter}>
                        {firstLetterBox(`${adminInfo.name}`)}
                    </span>
                </div>
            </div>
        </Header>
    )
}
import { Tabs, TabsProps } from 'antd';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { SettingsOfLogo, SettingsOfFavicon, SettingsOfGeneralContent, SettingsOfHome, InformationContent } from './components'
import { getIsBannerUpdated, getOneBannerThunk, getSettingsThunk, isSettingsUpdated } from '@store/index';
import { Partners, Banner } from '@components/index';
import { SettingsTabs } from '@models/index';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { DEFAULT_LANGAUGE, DEFAULT_LANGAUGE_LABEL } from '@utils/index';

export const Settings = (): JSX.Element => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { index } = useParams();

    const [activeTab, setActiveTab] = useState<string | undefined>(index);
    const [isValidKey, setIsValidKey] = useState<boolean>();

    const isSettingUpdated = useAppSelector<boolean>(isSettingsUpdated)
    const isBannerUpdated = useAppSelector<boolean>(getIsBannerUpdated);

    useEffect(() => {
        dispatch(getSettingsThunk());
        dispatch(getOneBannerThunk(DEFAULT_LANGAUGE))
    }, [dispatch])


    useEffect(() => {
        if (activeTab) {
            navigate(`/settings/${activeTab}`)
        }
    }, [activeTab])

    useEffect(() => {
        if (isSettingUpdated) {
            dispatch(getSettingsThunk());
        }
    }, [isSettingUpdated, dispatch])

    useEffect(() => {
        if (isBannerUpdated) {
            dispatch(getOneBannerThunk(DEFAULT_LANGAUGE))
        }
    }, [isBannerUpdated, dispatch])

    const items: TabsProps['items'] = [
        {
            key: 'logo',
            label: SettingsTabs.LOGO,
            children: <SettingsOfLogo />,
        },
        {
            key: 'favicon',
            label: SettingsTabs.FAVICON,
            children: <SettingsOfFavicon />,
        },
        {
            key: 'general',
            label: SettingsTabs.GENERAL_CONTENT,
            children: <SettingsOfGeneralContent />
        },
        {
            key: 'home',
            label: SettingsTabs.HOME_PAGE,
            children: <SettingsOfHome />,
        },
        {
            key: 'banner',
            label: SettingsTabs.BANNER,
            children: <Banner lang={DEFAULT_LANGAUGE_LABEL} />
        },
        {
            key: 'partners',
            label: SettingsTabs.PARTNERS,
            children: <Partners />,
        },
        {
            key: 'info',
            label: SettingsTabs.INFORMATION_CONTENT,
            children: <InformationContent lang={DEFAULT_LANGAUGE_LABEL} />,
        },
    ];

    useEffect(() => {
        const data = items.some(e => e.key === index);
        if (data) {
            setIsValidKey(true)
        }
    }, [index])

    const onChange = (key: string): void => {
        setActiveTab(key);
        navigate(`/settings/${activeTab}`)
    };

    return (
        <>
            {isValidKey
                ? <Tabs destroyInactiveTabPane items={items} onChange={onChange} activeKey={activeTab} />
                : t('MAIN.DATA_NOT_FOUND')}
        </>
    );
}
import { done } from "@assets/index";

import style from "./icons.module.css";

interface IProps {
  isClicked: Function
}

export const DoneIcon = ({ isClicked }: IProps): JSX.Element => {
  return <img src={done} className={style.done} onClick={() => isClicked(true)} alt="Done icon" />
};
import { useTranslation } from "react-i18next";
import { Button, Form, Input, Switch } from "antd";
import { useCallback, useEffect, useState } from "react";

import {
    createProfessionThunk,
    getEditedId,
    getErrorMessage,
    getErrorsField,
    getIsProfessionCreated,
    getIsUpdated,
    getOpenCloseRoleModal,
    getProfessionByIdThunk,
    getRoleById,
    openCloseRoleModal,
    resetRoleById,
    updateProfessionThunk
} from "@store/index";
import { getCookie, capitilizedText, permissions } from "@utils/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";

import style from "./profession.module.css";

export const Profession = (): JSX.Element => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const params = useAppSelector<number>(getEditedId);
    const roleByID = useAppSelector<any>(getRoleById);
    const errorMessage = useAppSelector<string>(getErrorMessage);
    const errorsField = useAppSelector<[]>(getErrorsField);
    const isCreated = useAppSelector<boolean>(getIsProfessionCreated);
    const isUpdated = useAppSelector(getIsUpdated)
    const isListRole = useAppSelector(getOpenCloseRoleModal);

    const [selectedAccess, setSelectedAccess] = useState<any>({});

    useEffect(() => {
        dispatch(getProfessionByIdThunk(params));
        dispatch(openCloseRoleModal(!isListRole));
    }, [dispatch])

    useEffect(() => {
        if (isCreated || isUpdated || errorMessage) {
            dispatch(openCloseRoleModal(false))
        }
    }, [isCreated, isUpdated, errorMessage, dispatch])

    useEffect(() => {
        const select = (arr: any, obj: any) => arr.reduce((r: any, e: any) => Object.assign(r, obj[e] ? { [e]: obj[e] } : null), {})
        const output = select(Object.keys(permissions[0]), roleByID);
        setSelectedAccess(output)

        form.setFieldsValue({
            name: roleByID?.name,
            key: roleByID?.key,
        });

        return () => {
            if (roleByID.id) {
                dispatch(resetRoleById())
                form.resetFields()
            }
        }
    }, [roleByID.id])

    const onChange = useCallback((checked: boolean, title: string) => {
        setSelectedAccess((item: any) => ({ ...item, [title]: checked }))
    }, [selectedAccess])

    const onFinish = (values: any): void => {
        const accessToken = getCookie('accessToken');

        const datas: any = {
            data: {
                "name": values.name.trim(),
                "key": values.key.trim(),
                ...selectedAccess
            },
            "id": params && params,
            token: accessToken,
        }
        dispatch(params ? updateProfessionThunk(datas) : createProfessionThunk(datas.data));
    };

    return (
        <>
            {!!(errorMessage && errorsField?.length < 0) && <p className={style.error}>
                {t(`ROLES.${errorMessage.toUpperCase()}`)}
            </p>}
            {errorsField?.length > 0
                && errorsField?.map((item: any) => {
                    return (
                        <p key={item.id} className={style.error}>
                            {t(`ROLES.${item.message.toUpperCase()}`)}
                        </p>
                    );
                })}
            <Form
                name="basic"
                labelCol={{ span: 4 }}
                form={form}
                wrapperCol={{ span: 16 }}
                className={style.form}
                initialValues={{
                    name: roleByID?.name,
                    key: roleByID?.key,
                }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    label="Name"
                    name="name"
                    className={style.label}
                    rules={[
                        { required: true, message: 'Please input profession name!' },
                        { whitespace: true, message: 'Name cannot be empty!' }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Key"
                    name="key"
                    className={style.label}
                    rules={[
                        { required: true, message: 'Please input profession key!' },
                        { whitespace: true, message: 'Key cannot be empty!' }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Accesses:"
                    name="accesses:"
                    className={style.label}
                >
                    <div className={style.accessesSection}>
                        {permissions.map((permissionObject, index) => (
                            <div key={index}>
                                {Object.entries(permissionObject).map(([key, value]) => (
                                    <div key={key} >
                                        <span>
                                            {capitilizedText(value)}
                                        </span>
                                        <Switch
                                            onChange={e => onChange(e, key)}
                                            checked={selectedAccess[key]}
                                            size="small"
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 21, span: 8 }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className={`${style.addUserButton} ${style.add}`}
                    >
                        {t(`MAIN.${params ? 'UPDATE' : 'ADD'}`)}
                    </Button>
                </Form.Item>
            </Form>
            <Button
                type="primary"
                className={style.addUserButton}
                onClick={() => dispatch(openCloseRoleModal(false))}
            >
                {t("MAIN.BACK")}
            </Button>
        </>
    )
}
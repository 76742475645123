import { AxiosResponse } from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ACCESS_TOKEN_DURATION, REFRESH_TOKEN_DURATION, deleteCookie, getCookie, setCookie, upperCase } from "@utils/index";
import { adminLogin, getAdminInfoByToken, sendRefreshToken } from "@services/auth.service";
import { IAdminLoginData, IAdminLoginResponseData, ILoginPayload } from "@models/interfaces/auth";

const initialState: IAdminLoginResponseData = {
  message: "",
  statusCode: 0,
  statusName: "",
  success: false,
  loading: false,
  errorKey: "",
  errorFields: [],
  accessToken: getCookie('accessToken'),
  refreshToken: getCookie('refreshToken'),
  adminInfo: {}
};

export const adminLoginThunk = createAsyncThunk(
  "admin/login",
  async (data: IAdminLoginData, { rejectWithValue }) => {
    try {
      const response = await adminLogin(data);
      setCookie("accessToken", response.data.accessToken, ACCESS_TOKEN_DURATION);
      setCookie("refreshToken", response.data.refreshToken, REFRESH_TOKEN_DURATION);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendRefreshTokenThunk = createAsyncThunk(
  "auth/refreshToken",
  async (data: string, { rejectWithValue }) => {
    try {

      const response = await sendRefreshToken(data);
      setCookie("accessToken", response.data.accessToken, ACCESS_TOKEN_DURATION);
      setCookie("refreshToken", response.data.refreshToken, REFRESH_TOKEN_DURATION);
      return Promise.resolve(response);

    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAdminInfoByTokenThunk = createAsyncThunk(
  "adminGet/getAdmin",
  async (data: any, { rejectWithValue }) => {
    try {

      const response = await getAdminInfoByToken();
      return Promise.resolve(response);

    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const AdminLoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    adminLogout: (state) => {
      deleteCookie('accessToken', "");
      deleteCookie('refreshToken', "");
      state.accessToken = ''
      state.refreshToken = ''

    },
    deleteAdminLoginErrors: (state) => {
      state.errorFields = [];
      state.message = ""
    },

  },

  extraReducers: (builder) => {
    builder.addCase(adminLoginThunk.pending, (state: IAdminLoginResponseData) => {
      state.loading = true;
    });
    builder.addCase(adminLoginThunk.fulfilled, (state: IAdminLoginResponseData, { payload }: PayloadAction<AxiosResponse<ILoginPayload>>) => {
      state.success = true;
      state.accessToken = payload.data.accessToken;
      state.refreshToken = payload.data.refreshToken;
      state.loading = false;
    });
    builder.addCase(adminLoginThunk.rejected, (state: IAdminLoginResponseData, { payload }: PayloadAction<any>) => {
      state.message = payload?.message || "";
      state.errorFields = payload?.errors || [""];
      state.success = false;
      state.loading = false;
    });

    builder.addCase(sendRefreshTokenThunk.fulfilled, (state: IAdminLoginResponseData, { payload }: PayloadAction<any>) => {
      state.success = payload;
      state.errorKey = "";
      state.accessToken = payload.data.accessToken
      state.refreshToken = payload.data.refreshToken
    });
    builder.addCase(sendRefreshTokenThunk.rejected, (state: IAdminLoginResponseData, { payload }: PayloadAction<any>) => {
      state.errorKey = payload;
    });



    builder.addCase(getAdminInfoByTokenThunk.pending, (state: IAdminLoginResponseData, { payload }: any) => {
      state.success = payload;
    });
    builder.addCase(getAdminInfoByTokenThunk.fulfilled, (state: IAdminLoginResponseData, { payload }: PayloadAction<AxiosResponse<ILoginPayload>>) => {
      state.adminInfo = payload.data;
    });
    builder.addCase(getAdminInfoByTokenThunk.rejected, (state: IAdminLoginResponseData, { payload }: any) => {
      state.accessToken = payload;
      state.success = false;
    });
  },
});
export const { adminLogout, deleteAdminLoginErrors } = AdminLoginSlice.actions;

export const getAdminLoginErrorMessages = (state: any) => {
  return upperCase(state.adminLogin?.message);
}
export const getAdminLoginErrorsFieldMessages = (state: any) => {
  return state.adminLogin.errorFields;
}

export const getAdminToken = (state: any) => {
  return state.adminLogin.accessToken;
}
export const getRefreshToken = (state: any) => {
  return state.adminLogin.refreshToken;

}
export const isAdminLoginLoading = (state: any) => {
  return state.adminLogin.loading;
}
export const getAdminInfo = (state: any) => {
  return state.adminLogin.adminInfo;
}
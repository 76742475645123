import { useTranslation } from 'react-i18next';

import { getLogoInfo, getMetaContent } from '@store/index';
import { ILogoInfo } from '@models/interfaces/auth';
import defaultLogo from "@assets/defaultLogo.png";
import { createImagePath } from '@utils/index';
import { useAppSelector } from '@hooks/index';
import { IMetaContent } from '@models/index';

import style from "./login.module.css";

export const RightContent = (): JSX.Element => {
    const { t } = useTranslation()
    const logo: any = useAppSelector<ILogoInfo>(getLogoInfo)
    const generalContent = useAppSelector<IMetaContent>(getMetaContent);

    return (
        <div className={style.rightSection}>
            <h1>{t("MAIN.WELCOME")} {t("MAIN.TO")}</h1>
            <div>
                <img
                    src={logo
                        ? createImagePath(logo.logo?.path)
                        : `${defaultLogo}`}
                    alt={generalContent.title}
                    className={logo ? style.logo : ""}
                />
            </div>
            <h2>
                {t("MAIN.ADMINPANEL")}
            </h2>
        </div>
    );
}
import { useEffect } from "react";
import { Dropdown, MenuProps, Space } from "antd";
import { useAppDispatch, useAppSelector } from "@hooks/index"

import { getAllLanguages, receiveLanguages } from "@store/index";
import { changeAcceptLanguage } from "@services/index";
import { ILanguageData } from "@models/index";
import { language } from "@assets/index";

import style from "../icons/icons.module.css";

export const languages: MenuProps['items'] = [
  {
    key: '1',
    label: 'English',
  },
  {
    key: '2',
    label: 'Russian',
  },
  {
    key: '3',
    label: 'Armenian',
  }
];

export const editArticleLanguages: MenuProps['items'] = [
  {
    key: 'en',
    label: 'English',
  },
  {
    key: 'ru',
    label: 'Russian',
  },
  {
    key: 'hy',
    label: 'Armenian',
  }
];

interface IProps {
  getCurrentLanguage: Function;
  section?: boolean;
  article?: boolean;
  banner?: boolean
}

export const Language = ({
  article,
  section,
  banner,
  getCurrentLanguage
}: IProps): JSX.Element => {

  const dispatch = useAppDispatch()
  const languages = useAppSelector(getAllLanguages)

  useEffect(() => {
    dispatch(receiveLanguages())
  }, [dispatch])

  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    if (banner) {
      getCurrentLanguage(key);
    } else {
      if (article) {
        changeAcceptLanguage(key)
      }
      const getCurrentLngData = languages.find((lng: ILanguageData) => lng.key === key)
      getCurrentLanguage(getCurrentLngData)
    }
  };

  function getSuitableObjForMenuLng() {
    if (article || section) {
      return languages
    } else {
      return editArticleLanguages
    }
  }

  const menuProps: MenuProps = {
    items: getSuitableObjForMenuLng(),
    onClick: handleMenuClick,
  };

  return (
    <div className={style.lng}>
      <Dropdown menu={menuProps} trigger={['click']}>
        <a onClick={e => e.preventDefault()}>
          <Space>
            <img src={language} alt="Language icon" />
          </Space>
        </a>
      </Dropdown>
    </div>
  );
};
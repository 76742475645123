import { configureStore } from '@reduxjs/toolkit';

import { InformationContentSlice, ProfessionsSlice, AdminLoginSlice, StatisticsSlice, SettingsSlice, ArticlesSlice, ReportsSlice, PartnerSlice, SectionSlice, SocialSlice, BannerSlice, UserSlice, LanguageSlice } from '@store/index';
import { injectStore } from "@services/index";

export const store = configureStore({
  reducer: {
    adminLogin: AdminLoginSlice.reducer,
    settings: SettingsSlice.reducer,
    user: UserSlice.reducer,
    profession: ProfessionsSlice.reducer,
    section: SectionSlice.reducer,
    article: ArticlesSlice.reducer,
    social: SocialSlice.reducer,
    banner: BannerSlice.reducer,
    partner: PartnerSlice.reducer,
    statistiscs: StatisticsSlice.reducer,
    language: LanguageSlice.reducer,
    informationContent: InformationContentSlice.reducer,
    reports: ReportsSlice.reducer
  }
})

injectStore(store);
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
import { ExportOutlined } from '@ant-design/icons';
import { Layout, Menu, theme, MenuProps } from 'antd';
import { useCallback, useEffect, useState, ReactNode, Key } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { sectionList, getSectionOneList, getSectionsForOneLanguage, adminLogout } from "@store/index";
import { AdminHeader, CustomBreadcrumbs } from '@components/index';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { DEFAULT_SETTINGS_TAB } from '@utils/index';

import { ReactComponent as ContentManagement } from "@assets/content.svg";
import { ReactComponent as DashboardIcon } from "@assets/dashboard.svg";
import { ReactComponent as UserManagement } from "@assets/users.svg";
import { ReactComponent as Settings } from "@assets/settings.svg";
import { ReactComponent as ReportIcon } from "@assets/report.svg";

import './antd-config.css'
import style from "./left-side.module.css";

const { Content, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

export const LeftSide = (): JSX.Element => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation()
  const { pathname } = useLocation();

  const parts = pathname.split('/')[1];
  const isSettingsIncluded = parts && parts.includes('settings');

  const pathnameMainPart = isSettingsIncluded
    ? `${parts}/${DEFAULT_SETTINGS_TAB}`
    : parts || 'statistics';

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [menuData, setMenuData] = useState<Array<any>>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const sectionOne = useAppSelector<[]>(getSectionOneList);
  const sections = useAppSelector<[]>(sectionList);

  useEffect(() => {
    dispatch(getSectionsForOneLanguage())
  }, [dispatch])

  const {
    token: { colorBgContainer }
  } = theme.useToken();

  const onClick = (info: any): void => {
    setIsOpen(false);
    setCollapsed(false);
    navigate(`${info.key}`);
  };

  const onOpenChange = useCallback((openKeys: any) => {
    if (isOpen == false && !collapsed && openKeys.length) {
      navigate(`${openKeys}`);
      setCollapsed(false);
    }
  }, [isOpen]);

  function getItem(
    label: ReactNode,
    key: Key,
    icon?: ReactNode,
    children?: MenuItem[],
    onClick?: any,
    onTitleClick?: any): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      onClick,
      onTitleClick
    } as MenuItem;
  }

  useEffect(() => {
    if (sections.length) {
      const sectionsData = sectionOne.map((itemOne: any) => getItem(itemOne.categoryTranslations[0].name, `/content-management/${itemOne.id}`))
      setMenuData(sectionsData);
    }
  }, [sections]);

  const items: MenuItem[] = [
    getItem('Dashboard', 'statistics', <DashboardIcon />),
    getItem('User Management', 'user-management', <UserManagement />),
    getItem('Content Management', 'content-management', <ContentManagement />, menuData),
    getItem('Task Report', `reports`, <ReportIcon />),
    getItem('Settings', `settings/${DEFAULT_SETTINGS_TAB}`, <Settings />)
  ]

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <AdminHeader
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
      <Sider
        width={270}
        collapsible
        collapsed={collapsed}
        onCollapse={value => setCollapsed(value)}
        trigger={null}
        style={{ maxWidth: "600px", background: colorBgContainer }}
      >
        <Menu
          mode="inline"
          className={style.leftNavigation}
          items={items}
          onClick={onClick}
          onOpenChange={onOpenChange}
          selectedKeys={[pathnameMainPart]}
        />
        <span
          onClick={() => {
            dispatch(adminLogout())
            navigate('/');
          }}
          className={style.logout}
        >
          <ExportOutlined /> {!collapsed && t('MAIN.LOGOUT')}
        </span>
      </Sider>

      <Layout>
        <Content>
          {pathname != "/statistics" && <CustomBreadcrumbs />}
          <Outlet />
        </Content>
      </Layout>
    </Layout >
  )
}
import { Card } from "antd";

import { BASE_URL, getCookie } from "@utils/index";
import { deleteSocialsThunk } from "@store/index";
import { useAppDispatch } from "@hooks/index";
import { IImage } from "@models/index"
import { socialPartnerEdit, checked, reply, socialPartnerDelete } from "@assets/index";

import styles from "./social.module.css";

interface IProps {
    link: string,
    image: IImage,
    title: string,
    id: number,
    isEditClicked: Function
}

export const SocialCards = ({ link, image, title, id, isEditClicked }: IProps): JSX.Element => {

    const token = getCookie('accessToken');
    const dispatch = useAppDispatch();

    return (
        <div className={styles.cardWrapper}>
            <Card className={styles.socialCard}>
                <img alt={image?.name} src={`${BASE_URL}${image?.path}?authorization=${token}`} className={styles.socialImage} />
            </Card>
            <div className={`${styles.actionsText} ${styles.showSocials}`}>
                <div className={styles.titleWrapper}>
                    <img src={checked} className="pr10" alt={title} />
                    {title}
                </div>
                <div>
                    <a href={link} target="_blank">
                        <img src={reply} className="pr10" alt="Reply icon" />
                    </a>
                    <img
                        src={socialPartnerEdit}
                        className="pr10"
                        onClick={() => isEditClicked({ isEdit: true, editedId: id })}
                        alt="Edit icon"
                    />
                    <img
                        src={socialPartnerDelete}
                        className="pr10"
                        onClick={() => dispatch(deleteSocialsThunk(id))}
                        alt="Delete icon"
                    />
                </div>
            </div>
        </div>
    );
}
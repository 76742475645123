import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';

import { IStatisticsData, IuserTaskReports } from '@models/index';
import style from "./statistics-charts.module.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IProps {
  statistics: IStatisticsData;
}

export const VerticalChart = ({ statistics }: IProps): JSX.Element => {

  const { t } = useTranslation();

  const done = statistics?.usersWithTaskReports?.map((item: IuserTaskReports) => {
    return item.percent
  }) || []

  const doneDate = statistics?.usersWithTaskReports?.map((item: IuserTaskReports) => {
    return moment(item?.createdAt).format('DD/MM/YYYY')
  }) || []

  const unsent = statistics?.usersWithoutTaskReports?.map((item: IuserTaskReports) => {
    return item.percent
  }) || []
  const unsentDate = statistics?.usersWithoutTaskReports?.map((item: IuserTaskReports) => {
    return moment(item?.createdAt).format('DD/MM/YYYY')
  }) || []

  const labels = doneDate

  const data = {
    labels,
    datasets: [
      {
        label: 'Done Task Report %',
        data: done,
        backgroundColor: "#74BBD3"
      },
      {
        label: 'Unsent Task Report %',
        data: unsent,
        backgroundColor: '#295B82',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      scales: {
        yAxes: [{
          ticks: {
            max: 20,
            min: 0,
            stepSize: 3
          }
        }]
      }
    }

  };

  return (
    <div className={style.statisticsSection}>
      <div className={style.statisticsTask1}>
        <p className={style.rotateText}>
          {t("MAIN.TASK_REPORT")}
        </p>
        <div>
          <div className={style.chartContainer}>
            <Bar options={options} data={data} />
          </div>
          <p className={style.directText}>
            {t("MAIN.TASK_REPORT")}
          </p>
        </div>
      </div>
      <div className={style.taskReport}>
        <div>
          <p>{statistics.taskReportsCount}</p>
          <p> {t("MAIN.TASK_REPORT")}</p>
        </div>
      </div>
    </div>
  )
}
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { createSection, deleteSection, getSectionsRoute, getSectionsAllForOneLanguage, updateSection } from "@services/section.service";
import { ISectionData } from "@models/index";

const initialState: ISectionData = {
  message: "",
  statusCode: 0,
  statusName: "",
  success: false,
  loading: false,
  data: [],
  dataOne: [],
  sectionErrors: [],
  isSectionCreated: false,
  isSectionUpdated: false,
  isSectionDeleted: false,
  isSectionCreatePending: false
}

export const getSections = createAsyncThunk(
  "section/getsectionss",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getSectionsRoute();
      return Promise.resolve(response.data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getSectionsForOneLanguage = createAsyncThunk(
  "section/getsectionsall",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getSectionsAllForOneLanguage();
      return Promise.resolve(response.data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createSectionThunk: any = createAsyncThunk(
  "section/createsection",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await createSection(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateSectionThunk = createAsyncThunk(
  "update/updateSection",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await updateSection(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteSectionThunk = createAsyncThunk(
  "delete/deletesection",
  async (data: any, { rejectWithValue }) => {
    try {

      const response = await deleteSection(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const SectionSlice = createSlice({
  name: "section",
  initialState,
  reducers: {
    resetSectionSuccess: (state) => {
      state.isSectionCreated = false
    },
    resetSectionError: (state) => {
      state.sectionErrors = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSections.pending, (state: ISectionData) => {
      state.loading = true;
    });

    builder.addCase(getSections.fulfilled, (state: ISectionData, { payload }: PayloadAction<any>) => {
      state.success = true;
      state.data = payload;
      state.loading = false;
      state.isSectionUpdated = false;
      state.isSectionDeleted = false;
    });

    builder.addCase(getSections.rejected, (state: ISectionData) => {
      state.success = false;
      state.loading = false;
      state.isSectionDeleted = false;
    });


    builder.addCase(getSectionsForOneLanguage.pending, (state: ISectionData) => {
      state.loading = true;
    });

    builder.addCase(getSectionsForOneLanguage.fulfilled, (state: ISectionData, { payload }: PayloadAction<any>) => {
      state.success = true;
      state.dataOne = payload;
      state.loading = false;
      state.isSectionUpdated = false;
      state.isSectionDeleted = false;
    });

    builder.addCase(getSectionsForOneLanguage.rejected, (state: ISectionData) => {
      state.success = false;
      state.loading = false;
      state.isSectionDeleted = false;
    });


    builder.addCase(updateSectionThunk.fulfilled, (state: ISectionData, { payload }: PayloadAction<any>) => {
      state.statusCode = payload.statusCode;
      state.statusName = payload.statusName;
      state.isSectionUpdated = true;
      state.isSectionDeleted = false;
    });

    builder.addCase(createSectionThunk.pending, (state: ISectionData, { payload }: any) => {
      state.isSectionCreatePending = true
      state.success = payload;
      state.isSectionCreated = false
    });
    builder.addCase(createSectionThunk.fulfilled, (state: ISectionData, { payload }: PayloadAction<any>) => {
      state.success = payload;
      state.isSectionCreated = true;
      state.isSectionUpdated = false;
      state.isSectionDeleted = false;
      state.isSectionCreatePending = false
    });

    builder.addCase(createSectionThunk.rejected, (state: ISectionData, { payload }: any) => {
      state.success = false;
      state.isSectionDeleted = false;
      state.isSectionCreatePending = false;
      state.isSectionCreated = false
      state.sectionErrors = payload.errors ? payload.errors : payload.message.toUpperCase()
    });

    builder.addCase(deleteSectionThunk.pending, (state: ISectionData, { payload }: any) => {
      state.success = payload;
    });
    builder.addCase(deleteSectionThunk.fulfilled, (state: ISectionData, { payload }: PayloadAction<any>) => {
      state.success = payload;
      state.isSectionUpdated = false;
      state.isSectionDeleted = true;
    });
    builder.addCase(deleteSectionThunk.rejected, (state: ISectionData) => {
      state.success = false;
      state.isSectionDeleted = false;
    });
  },
});

export const sectionList = (state: any) => {
  return state.section.data
}
export const getSectionOneList = (state: any) => {
  return state.section.dataOne
}
export const getIsCreated = (state: any) => {
  return state.section.isSectionCreated;
}
export const getIsSectionUpdated = (state: any) => {
  return state.section.isSectionUpdated;
}
export const getIsSectionDeleted = (state: any) => {
  return state.section.isSectionDeleted;
}
export const getSectionLoading = (state: any) => {
  return state.section.loading;
}
export const getSectionCreatePending = (state: any) => {
  return state.section.isSectionCreatePending
}
export const getSectionErrors = (state: any) => {
  return state.section.sectionErrors
}

export const { resetSectionSuccess, resetSectionError } = SectionSlice.actions

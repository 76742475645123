const colors: [...{ bgcolor: string; color: string }[]] = [

    { bgcolor: 'rgba(255, 199, 243, 0.48)', color: 'rgba(224, 88, 194, 1)' },
    { bgcolor: 'rgba(250, 246, 161, 0.48)', color: 'rgba(111, 106, 21, 1)' },
    { bgcolor: 'rgba(243, 231, 166, 0.48)', color: 'rgba(255, 214, 70, 1)' },
    { bgcolor: 'rgba(114, 255, 128, 0.3)', color: 'rgba(29, 130, 39, 1)' },
    { bgcolor: 'rgba(199, 225, 255, 0.48)', color: 'rgba(76, 135, 203, 1)' },
    { bgcolor: 'rgba(243, 231, 166, 0.48)', color: 'rgba(255, 214, 70, 1)' },

];

const colorsObj: any = {};

export function dynamicColors(roles: any[]) {
    const uniqRoles = new Set(roles);
    const values: any = uniqRoles.entries();
    let i = 0;

    for (const [value] of values) {

        if (value) {
            colorsObj[value?.name] = colors[i] ? colors[i] : colorsObject();
            i++;
        }
    }

    return colorsObj;
}

export function getDynamicColor(role: string) {
    return colorsObj[role];
}

export function getRandomRgba(o: number) {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);

    return `rgba(${r},${g},${b},${o})`;
};

export function colorsObject() {
    return { bgcolor: getRandomRgba(0.48), color: '#000' }
}
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Form, Input, Checkbox, Button } from "antd";

import {
    adminLoginThunk,
    deleteAdminLoginErrors,
    getAdminLoginErrorMessages,
    isAdminLoginLoading
} from "@store/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { IOnFinishValue } from "@models/interfaces/auth";
import style from "./login.module.css";

export const LoginForm = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const error = useAppSelector<string>(getAdminLoginErrorMessages);
    const loading = useAppSelector<boolean>(isAdminLoginLoading);

    const onFinish = (values: IOnFinishValue): void => {
        const data = {
            login: values.login,
            password: values.password
        }
        dispatch(adminLoginThunk(data))
    };

    useEffect(() => {
        dispatch(deleteAdminLoginErrors());
    }, [dispatch])

    return (
        <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            className={style.formContainer}
            initialValues={{}}
            onFinish={onFinish}
            autoComplete="off"
        >
            {error ? <p className="errorMessage">{t(`ERRORS.ERR_ADMIN_NOT_EXISTS`)}</p> : ""}
            <Form.Item
                name="login"
                rules={[{ required: true, message: t(`VALIDATIONS.REQUIERD_EMAIL`) as string }]}
                className={style.formItem}
            >
                <Input
                    className={style.loginField}
                    placeholder="Email"
                />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[{ required: true, message: t(`VALIDATIONS.REQUIERD_PASSSWORD`) as string }]}
                className={style.formItem}
            >
                <Input.Password
                    className={style.loginField}
                    placeholder="Password"
                />
            </Form.Item>

            <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{ span: 16 }}
            >
                <Checkbox className={style.rememberMe}>
                    {t('MAIN.REMEMBER_ME')}
                </Checkbox>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 14 }}>
                <Button
                    type="primary"
                    htmlType="submit"
                    className={style.submit}
                    loading={loading}
                >
                    {t('MAIN.LOGIN')}
                </Button>
            </Form.Item>
        </Form>
    )
}
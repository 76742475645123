import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IArticlesResponseData, IReceivedUpdateData } from "@models/index";

import {
  createArticleRoute,
  deleteArticle,
  getArticlesByCategoryRoute,
  getSingleArticleRouth,
  updateSingleArticle
} from "@services/article.service";

const initialState: IArticlesResponseData = {
  statusCode: 0,
  statusName: "",
  success: false,
  updateSuccess: false,
  createSuccess: false,
  loading: false,
  data: [],
  dataForSingleArticle: [],
  isArticleDeleted: false,
  errors: [],
  errorForCategoryArticle: {},
  sendData: false,
  requestData: false,
  languages: [],
  languagesError: [],
  articleIsNotExistsError: []
};

export const getArticlesByCategory = createAsyncThunk(
  "article/getarticles",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getArticlesByCategoryRoute(data);
      return Promise.resolve(response.data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createArticle: any = createAsyncThunk(
  "articles/createarticles",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await createArticleRoute(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEditArticle: any = createAsyncThunk(
  "editArticle/editArticleAsync",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getSingleArticleRouth(id);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateArticle: any = createAsyncThunk(
  "updateArticle/updateArticleThunk",
  async (data: IReceivedUpdateData, { rejectWithValue }) => {
    try {
      const response = await updateSingleArticle(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const deleteArticleThunk: any = createAsyncThunk(
  "articles/deleteArticleAsync",
  async (articleId: any, { rejectWithValue }) => {
    try {
      const response = await deleteArticle(articleId);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const ArticlesSlice = createSlice({
  name: "article",
  initialState,
  reducers: {
    resetSuccessReducer: (state) => {
      state.createSuccess = false
      state.updateSuccess = false
    },

    resetError: (state) => {
      state.errors = []
    },

    resetArticleData: (state) => {
      state.dataForSingleArticle = []
    },

    resetAddArticleErrors: (state) => {
      state.errors = []
    },

    changeSetDataState: (state, { payload }) => {
      state.sendData = payload
    },

    resetCategoryArticlesErrors: (state) => {
      state.errorForCategoryArticle = {}
    }
  },

  extraReducers: (builder) => {

    builder.addCase(getArticlesByCategory.pending, (state: IArticlesResponseData) => {
      state.loading = true;
    });

    builder.addCase(getArticlesByCategory.fulfilled, (state: IArticlesResponseData, { payload }: PayloadAction<any>) => {
      state.success = true;
      state.data = payload;
      state.loading = false;
    });

    builder.addCase(getArticlesByCategory.rejected, (state: IArticlesResponseData, { payload }: PayloadAction<any>) => {
      state.success = false;
      state.loading = false;
      state.errorForCategoryArticle = payload;
    });

    builder.addCase(createArticle.pending, (state: IArticlesResponseData, { payload }: any) => {
      state.createSuccess = payload;
      state.errors = []
      state.requestData = true
    });

    builder.addCase(createArticle.fulfilled, (state: IArticlesResponseData) => {
      state.createSuccess = true;
      state.requestData = false
    });

    builder.addCase(createArticle.rejected, (state: IArticlesResponseData, { payload }: any) => {
      state.createSuccess = false;
      if (payload?.errors) {
        state.errors = payload.errors;
      } else if (payload?.error) {
        state.errors = payload?.message;
      } else if (payload?.message) {
        state.errors = payload.message
      }
      state.requestData = false
    });

    builder.addCase(deleteArticleThunk.fulfilled, (state: IArticlesResponseData) => {
      state.isArticleDeleted = !state.isArticleDeleted
      state.loading = false
    });

    builder.addCase(deleteArticleThunk.rejected, (state: IArticlesResponseData) => {
      state.loading = false
    });

    builder.addCase(deleteArticleThunk.pending, (state: IArticlesResponseData) => {
      state.loading = true
    });

    builder.addCase(getEditArticle.fulfilled, (state: IArticlesResponseData, { payload }: PayloadAction<any>) => {
      state.dataForSingleArticle = payload.data
      state.loading = false
    });

    builder.addCase(getEditArticle.pending, (state: IArticlesResponseData) => {
      state.loading = true;
      state.dataForSingleArticle = []
      state.articleIsNotExistsError = []
    });

    builder.addCase(getEditArticle.rejected, (state: IArticlesResponseData, { payload }: PayloadAction<any>) => {
      state.loading = false
      state.articleIsNotExistsError = payload
    });
    builder.addCase(updateArticle.pending, (state: IArticlesResponseData, { payload }: PayloadAction<any>) => {
      state.errors = []
      state.updateSuccess = payload
      state.requestData = true
    });
    builder.addCase(updateArticle.fulfilled, (state: IArticlesResponseData) => {
      state.updateSuccess = true
      state.requestData = false
    });
    builder.addCase(updateArticle.rejected, (state: IArticlesResponseData, { payload }: PayloadAction<any>) => {
      state.updateSuccess = false
      state.errors = payload.errors
      state.requestData = false
    });
  },
});

export const getArticleList = (state: any) => {
  return state.article.data;
}

export const getArticleLoading = (state: any) => {
  return state.article.loading;
}

export const articleErrors = (state: any) => {
  return state.article.errors;
}

export const getArticleSuccess = (state: any) => {
  return state.article.isArticleDeleted;
}

export const getSingleArticle = (state: any) => {
  return state.article.dataForSingleArticle
}

export const isArticleCreated = (state: any) => {
  return state.article.createSuccess
}

export const getUpdateArticleSuccess = (state: any) => {
  return state.article.updateSuccess
}

export const getEditArticleRequestState = (state: any) => {
  return state.article.requestPending
}

export const sendDataState = (state: any) => {
  return state.article.sendData
}

export const errorForCategoryArticles = (state: any) => {
  return state.article.errorForCategoryArticle
}

export const getDataRequest = (state: any) => {
  return state.article.requestData
}

export const articleNotExistError = (state: any) => {
  return state.article.articleIsNotExistsError?.message
}

export const { resetSuccessReducer, resetError, resetArticleData, resetAddArticleErrors, changeSetDataState, resetCategoryArticlesErrors } = ArticlesSlice.actions
import { ReportPlace } from "@models/index";
import ReportCard from "./components/report-card";

interface IProps {
    userId?: any;
    hasFilter?: any;
    place?: ReportPlace
}

export const Reports = ({ userId, hasFilter, place }: IProps): JSX.Element => {
    return (
        <ReportCard
            userId={userId}
            hasFilter={hasFilter}
            place={place}
        />
    )
}
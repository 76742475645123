import { close } from "@assets/index";

import style from "@components/icons/icons.module.css";

interface IProps {
  isClose: Function
}

export const CloseIcon = ({ isClose }: IProps): JSX.Element => {
  return <img src={close} className={style.close} onClick={() => isClose(false)} alt="Close icon" />
};
export enum OnOff {
  ONLINE = "Online",
  OFFLINE = "Offline"
}
export enum ButtonLoading {
  ZERO = 0,
  ONE = 1
}
export enum LineCount {
  ARTICLE = 25,
  BANNER = 4
}
export const SettingsTabs = {
  LOGO: 'Logo',
  GENERAL_CONTENT: 'General Content',
  FAVICON: 'Favicon',
  HOME_PAGE: 'Home Page',
  BANNER: 'Banner',
  PARTNERS: 'Partners',
  INFORMATION_CONTENT: 'Information Content'
}
export const UserManagementTabs = {
  UPDATE: 'Update Information',
  CHANGE_PASSWORD: 'Change Password',
  TASK_REPORT: 'Task Report'
}

export enum UserStatus {
  ACTIVE = "active",
  PENDING = "pending",
  BLOCKED = "blocked"
}

export enum ServerErrorType {
  IMAGE,
  CATEGORY,
  TITLE,
  CONTENT,
  KEY,
  NAME
}
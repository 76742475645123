import { message } from 'antd';
import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { resetSuccessReducer, resetSectionSuccess, getSectionErrors, resetSectionError } from '@store/index';
import { useAppDispatch, useAppSelector } from '@hooks/index'
import { IArticleData } from 'models/index';
import { changeAcceptLanguage } from '@services/index';
import { DEFAULT_LANGAUGE } from '@utils/index';

interface IProps {
    articleData?: IArticleData;
    sectionKey?: string;
    articleCreated?: boolean;
    articleUpdated?: boolean;
    sectionCreated?: boolean
}

export const SuccessOrErrorMessage = ({
    articleData,
    sectionKey,
    articleCreated,
    articleUpdated,
    sectionCreated
}: IProps): JSX.Element => {

    const [messageApi, contextHolder] = message.useMessage();
    const { t } = useTranslation()

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const sectionErrors = useAppSelector(getSectionErrors)

    function messageContent(): ReactNode {
        if (typeof sectionErrors === 'string') {
            return t(`SECTION.${sectionErrors}`)
        } else if (articleCreated || articleUpdated) {
            return `Article ${articleCreated ? 'created' : 'updated'}`
        } else if (sectionCreated) {
            return `Section created`
        }
    }

    function generateMessage(): void {
        if (articleCreated || articleUpdated || sectionCreated) {
            messageApi.success({
                content: messageContent(),
                duration: 1,
            }).then(() => {
                if ((articleCreated || articleUpdated) && articleData?.categoryId) {
                    navigate(`/content-management/${articleData?.categoryId}`)
                    dispatch(resetSuccessReducer())
                } else if (sectionCreated) {
                    navigate('/content-management/article', { state: { sectionKey } })
                    dispatch(resetSectionSuccess())
                }
            })
        } else if (typeof sectionErrors === 'string') {
            messageApi.error({
                content: messageContent(),
                duration: 1,
            }).then(() => {
                if (sectionErrors) {
                    dispatch(resetSectionError())
                }
            })
        }
    }

    useEffect(() => {
        generateMessage()
        changeAcceptLanguage(DEFAULT_LANGAUGE)
    }, [articleCreated, articleUpdated, sectionCreated, sectionErrors])

    return (
        <>
            {contextHolder}
        </>
    );
};
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { createBanner, deleteBannerImage, getAllBanner, getOneBanner, updateBanner } from "@services/banner.service";
import { IBannerResponseData } from "@models/index";

const initialState: IBannerResponseData = {
  message: "",
  statusCode: 0,
  statusName: "",
  success: false,
  isBannerCreated: false,
  isBannerUpdated: false,
  isBannerDeleted: false,
  loading: false,
  about: "",
  address: "",
  copyright: "",
  description: "",
  favicon: {},
  keyword: "",
  logo: {},
  title: "",
  onebanner: [],
  bannerList: [],
  singleBanner: [],
  socialData: [],
  errors: []
};

export const deleteBannerImageThunk = createAsyncThunk(
  "banner/deletebannerimages",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await deleteBannerImage(data);
      return Promise.resolve(response.data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getOneBannerThunk = createAsyncThunk(
  "banner/getOneBanner",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getOneBanner(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllBannerThunk = createAsyncThunk(
  "banner/getAllBanner",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllBanner();
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateBannerThunk = createAsyncThunk(
  "update/updatebanner",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await updateBanner(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createBannerThunk = createAsyncThunk(
  "create/createbanner",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await createBanner(data);
      return Promise.resolve(response);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const BannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    resetBannerErrors: (state) => {
      state.errors = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(deleteBannerImageThunk.pending, (state: IBannerResponseData) => {
      state.loading = true
    });
    builder.addCase(deleteBannerImageThunk.fulfilled, (state: IBannerResponseData) => {
      state.loading = false;
      state.isBannerUpdated = false;
      state.isBannerDeleted = true;
      state.message = "";
      state.errors = [];
    });
    builder.addCase(deleteBannerImageThunk.rejected, (state: IBannerResponseData, { payload }: PayloadAction<any>) => {
      state.loading = false;
      state.isBannerDeleted = false;
      state.isBannerUpdated = false;
      state.message = payload.message;
      state.errors = payload.errors;
    });

    builder.addCase(getOneBannerThunk.pending, (state: IBannerResponseData) => {
      state.errors = []
      state.loading = true
      state.singleBanner = []
    });
    builder.addCase(getOneBannerThunk.fulfilled, (state: IBannerResponseData, { payload }: PayloadAction<any>) => {
      state.loading = false
      state.isBannerUpdated = false;
      state.isBannerDeleted = false;
      state.onebanner = payload.data;
      state.singleBanner = [];
      state.statusCode = payload.statusCode;
      state.statusName = payload.statusName;
      state.message = "";
      state.errors = [];
    });
    builder.addCase(getOneBannerThunk.rejected, (state: IBannerResponseData, { payload }: PayloadAction<any>) => {
      state.loading = false;
      state.singleBanner = [];
      state.onebanner = [];
      state.isBannerDeleted = false;
      state.isBannerUpdated = false;
      state.message = payload.message;
      state.errors = payload.errors;
    });

    builder.addCase(getAllBannerThunk.pending, (state: IBannerResponseData) => {
      state.loading = true
    });
    builder.addCase(getAllBannerThunk.fulfilled, (state: IBannerResponseData, { payload }: PayloadAction<any>) => {
      state.loading = false
      state.isBannerUpdated = false;
      state.isBannerDeleted = false;
      state.bannerList = payload.data;
      state.onebanner = [];
      state.statusCode = payload.statusCode;
      state.statusName = payload.statusName;
      state.message = "";
      state.errors = [];
    });
    builder.addCase(getAllBannerThunk.rejected, (state: IBannerResponseData, { payload }: PayloadAction<any>) => {
      state.loading = false;
      state.bannerList = [];
      state.onebanner = [];
      state.isBannerDeleted = false;
      state.isBannerUpdated = false;
      state.message = payload.message;
      state.errors = payload.errors;
    });

    builder.addCase(updateBannerThunk.fulfilled, (state: IBannerResponseData, { payload }: PayloadAction<any>) => {
      state.loading = false;
      state.isBannerUpdated = true;
      state.data = payload;
      state.statusCode = payload.statusCode;
      state.statusName = payload.statusName;
      state.message = "";
      state.errors = [];
    });
    builder.addCase(updateBannerThunk.rejected, (state: IBannerResponseData, { payload }: PayloadAction<any>) => {
      state.loading = false;
      state.isBannerDeleted = false;
      state.isBannerUpdated = false;
      state.message = payload.message;
      state.errors = payload.errors;
    });

    builder.addCase(updateBannerThunk.pending, (state: IBannerResponseData) => {
      state.isBannerUpdated = false;
      state.loading = true;
    })

    builder.addCase(createBannerThunk.fulfilled, (state: IBannerResponseData, { payload }: PayloadAction<any>) => {
      state.loading = false;
      state.data = payload;
      state.statusCode = payload.statusCode;
      state.statusName = payload.statusName;
      state.message = "";
      state.errors = [];
      state.isBannerCreated = true
    });
    builder.addCase(createBannerThunk.rejected, (state: IBannerResponseData, { payload }: PayloadAction<any>) => {
      state.loading = false;
      state.isBannerDeleted = false;
      state.isBannerUpdated = false;
      state.message = payload.message;
      state.errors = payload.errors;
      state.isBannerCreated = false
    });
    builder.addCase(createBannerThunk.pending, (state: IBannerResponseData, { payload }: PayloadAction<any>) => {
      state.loading = true
      state.isBannerCreated = false
    })
  },
});

export const { resetBannerErrors } = BannerSlice.actions

export const getOneBannerInfo = (state: any) => {
  return state.banner.bannerList;
}
export const getIsBannerUpdated = (state: any) => {
  return state.banner.isBannerUpdated;
}

export const getIsBannerCreated = (state: any) => {
  return state.banner.isBannerCreated
}

export const getIsBannerDeleted = (state: any) => {
  return state.banner.isBannerDeleted;
}
export const getBannerLoading = (state: any) => {
  return state.banner.loading;
}
export const getBannerErrors = (state: any) => {
  return state.banner.errors;
}
export const getBannerErrorMessage = (state: any) => {
  return state.banner.message;
}
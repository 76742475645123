import { Col, Row } from "antd";

import { LoginForm, RightContent } from "./components/index";
import style from "./components/login.module.css";

export const LoginScreen = (): JSX.Element => {

  return (
    <Row className={style.loginSection}>
      <Col span={8}>
        <LoginForm />
      </Col>
      <Col span={8} className={style.contentSection}>
        <RightContent />
      </Col>
    </Row>
  );
}
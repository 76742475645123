import { Skeleton } from "antd"

import style from './section-articles-list.module.css'

export const ArticlesListSkeleton = (): JSX.Element => {

    function generateSkeleton(): JSX.Element {
        return (
            <div className={style.skeletonsMainWrapper}>
                <div className={style.id}>
                    <Skeleton
                        active
                        title={false}
                        paragraph={{
                            rows: 1,
                            width: 50
                        }} />
                </div>
                <div className={style.imageWrapper}>
                    <Skeleton.Image
                        active
                        className={style.image}
                    />
                </div>
                <div className={style.titleWrapper}>
                    <Skeleton
                        active
                        title={false}
                        paragraph={{
                            rows: 1,
                            width: 100
                        }}
                    />
                </div>
                <div className={style.sectionWrapper}>
                    <Skeleton
                        active
                        title={false}
                        paragraph={{
                            rows: 1,
                            width: 130
                        }}
                    />
                </div>
                <div className={style.statusWrapper}>
                    <Skeleton
                        active
                        title={false}
                        paragraph={{
                            rows: 1,
                            width: 100
                        }}
                    />
                </div>
                <div className={style.iconsWrapper}>
                    <Skeleton.Avatar
                        active
                        size='small'
                        shape='square'
                    />
                    <Skeleton.Avatar
                        active
                        size='small'
                        shape='square'
                    />
                </div>
            </div>
        )
    }
    return (
        <>
            <Skeleton.Input
                active
                size="large"
                className={style.tableHeader}
            />
            {generateSkeleton()}
            {generateSkeleton()}
            {generateSkeleton()}
            {generateSkeleton()}
            {generateSkeleton()}

            <div className={style.paginationWrapper}>
                <Skeleton.Avatar
                    active
                    size='small'
                    shape='square'
                />
                <Skeleton.Avatar
                    active
                    size='default'
                    shape='square'
                />
                <Skeleton.Avatar
                    active
                    size='small'
                    shape='square'
                />
            </div>
        </>
    )
}

import moment from "moment"
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { LeftOutlined } from '@ant-design/icons'

import { getDetailsById, getReportLoading, getReportsDetailsThunk } from "@store/index";
import { CustomButton } from "@components/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { IDetailsReportItem, IReportsDetailed } from "@models/index";
import { createImagePath, firstLetterBox, BASE_URL, getCookie } from "@utils/index";
import { NotFound } from "@pages/index";
import Fancybox from "@components/fancybox";

import style from "../reports.module.css";

const ReportsDetails = (): JSX.Element => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const token = getCookie('accessToken');
    const { id } = useParams<string>();

    const detailedReports = useAppSelector<IReportsDetailed>(getDetailsById);
    const loading = useAppSelector(getReportLoading)

    useEffect(() => {
        dispatch(getReportsDetailsThunk(id))
    }, [])

    if (!loading && !detailedReports.title) {
        return <NotFound />
    }

    return (
        <>
            {loading ? <p>Loading...</p> :
                <div className={style.reportDetailsContainer}>
                    <div className={style.reportDetailsHeader}>
                        <div style={{ display: "flex" }}>
                            <p className={style.fisrtLetter}>
                                {firstLetterBox(detailedReports?.user?.firstName)}
                            </p>
                            <h2 className={style.title}>
                                {detailedReports?.user?.firstName} {detailedReports?.user?.lastName}
                            </h2>
                        </div>
                        <CustomButton
                            onClick={() => navigate("/reports")}
                            name={"Back"}
                            position={"right"}
                            icon={<LeftOutlined />}
                        />
                    </div>
                    <div className={style.detaildSection}>
                        <h2>{detailedReports?.title}</h2>
                        <div>
                            <span className={style.label}>
                                {t("MAIN.DATE")}:
                            </span>
                            <span>
                                {moment(detailedReports?.date).format('DD/MM/YYYY')}
                            </span>
                        </div>
                        <div>
                            <span className={style.label}>
                                {t("MAIN.STATUS")}:
                            </span>
                            <span>
                                {detailedReports?.type}
                            </span>
                        </div>
                        <div>
                            <span className={style.label}>
                                {t("MAIN.DESCRIPTION")}:
                            </span>
                            <p>{detailedReports?.description}</p>
                        </div>
                    </div>
                </div>}

            {detailedReports?.files?.length > 0 ?
                <div className={style.reportImages}>
                    <div>
                        <span className={style.label}>
                            {t("MAIN.ATTACHED_FILES")}:
                        </span>
                    </div>
                    <div className={style.fancyboxSection}>
                        <Fancybox
                            options={{
                                Carousel: {
                                    infinite: false,
                                }
                            }} >
                            {detailedReports?.files?.map((item: IDetailsReportItem) => {
                                return (
                                    <div className={style.images}>
                                        <a
                                            data-fancybox="gallery"
                                            href={`${BASE_URL}${item.path}?authorization=${token}`}
                                        >
                                            <img
                                                src={createImagePath(item.path)}
                                                alt={item.name}
                                            />
                                        </a>
                                        <p>{item.name}</p>
                                    </div>
                                )
                            })}
                        </Fancybox>
                    </div>
                </div>
                : ""}
        </>
    )
}
export default ReportsDetails;
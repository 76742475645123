import { api } from ".";

/*API calls for Information Content */
export const getInformationContent = (lng:any) => {
    return api.get("information-content",{
        headers: {
            'Accept-Language': lng,
        }
    });
}

export const updateInformationContent = (data: any) => {
    return api.put(`/information-content/${data.id}`, {
        about: data.about,
        banner: data.banner,
        aboutTitle: data.aboutTitle,
        bannerTitle: data.bannerTitle,
        languageId: data.languageId
    });
}
export const createInformationContent = (data: any) => {
    return api.post(`/information-content`, {
        about: data.about,
        banner: data.banner,
        aboutTitle: data.aboutTitle,
        bannerTitle: data.bannerTitle,
        languageId: data.languageId
    });
}